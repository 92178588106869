<template>
  <div class="inner_cont">
    <div class="area_img_text"></div>
    <div class="area_img">
      <Lottie
        class="lottie_result_img"
        :options="resultImgLottieOption"
        @animCreated="handleAnimation" />
    </div>
  </div>
</template>

<script>
import Lottie from 'vue-lottie';
import send from '@/assets/json/send.json';

export default {
  name: 'TutorialStep1',
  components:{
    Lottie
  },
  data(){
    return{
      resultImgLottieOption: {
        animationData: send
      },
    }
  },
  methods:{
    handleAnimation(anim) {
      this.anim = anim;
    },
  }
}
</script>

<style scoped>
.inner_cont{position:relative;width:100%;height:100%;max-height:376px;padding-top:116px;box-sizing:border-box}

.area_img_text{position:absolute;top:0;width:100%;height:110px;max-height:34%;background:url(/assets/images/tutorial/img_tutorial_step1_text.png)no-repeat center 0;background-size:auto 100%}

.area_img{position:absolute;bottom:0;z-index:10;width:100%;height:260px;max-height:82%}

.page_tutorial .bullt_page{background-color:#E2D7C4}
</style>