var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PopupLayout',{class:['inner_quest_clear_layer', { active: _vm.isMounted }],attrs:{"paddingTop":2,"isTemplate":true,"compeleteBtnText":_vm.$poData.quest_clear_btn},on:{"onClickComplete":function($event){return _vm.$emit('onClickComplete')}},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('p',{staticClass:"desc_answer_sum"},[_vm._v(" "+_vm._s(_vm.$poData.place_quest_clear_right_answer_sum.format( _vm.quizAnswerData.total_quiz_cnt, _vm.quizAnswerData.accumulated_success_cnt || '0', ))+" ")])]},proxy:true},{key:"foot_bottom",fn:function(){return [(!_vm.hasReward)?_c('p',{staticClass:"desc_reward_end"},[_vm._v(" "+_vm._s(_vm.$poData.sponsor_quest_clear_note_1)+" ")]):_vm._e()]},proxy:true}])},[(_vm.hasReward)?_c('div',{staticClass:"group_box"},[_c('div',{staticClass:"row_box",style:({ height: `${_vm.rowBoxSize}px` })},[_c('div',{staticClass:"box_character img_container"},[_c('div',{staticClass:"inner_box_character"},[_c('img',{attrs:{"src":"/assets/images/character/img_sponsored_quest_complete_reward.png","alt":"퍼핀 캐릭터"}})])]),_c('div',{staticClass:"box_money img_container"},[_c('img',{attrs:{"src":"/assets/icons/income-etc.svg","alt":"돈 주머니"}})])]),_c('div',{staticClass:"box_reward_text",style:({
        height: `${_vm.rowBoxSize * 0.4}px`,
        fontSize: `${_vm.rowBoxSize * 0.4 * 0.3}px`,
      })},[_vm._v(" "+_vm._s(_vm.rewardText)+" ")])]):_c('div',{staticClass:"group_box noreward"},[_c('div',{staticClass:"row_box",style:({ height: `${_vm.rowBoxSize}px` })},[_c('div',{staticClass:"box_money img_container"},[_c('img',{attrs:{"src":"/assets/icons/interest.svg","alt":"돈 천사"}})]),_c('div',{staticClass:"box_character img_container"},[_c('div',{staticClass:"inner_box_character"},[_c('img',{attrs:{"src":"/assets/images/sponsored/quest_clear_character.png","alt":"퍼핀 캐릭터"}})])])]),_c('div',{staticClass:"box_reward_text",style:({
        height: `${_vm.rowBoxSize * 0.4}px`,
        fontSize: `${_vm.rowBoxSize * 0.4 * 0.3}px`,
      })},[_vm._v(" "+_vm._s(_vm.rewardText)+" ")])]),_c('p',{staticClass:"desc_reward_sponsor",domProps:{"innerHTML":_vm._s(_vm.descRewardSponsor)}}),_c('div',{staticClass:"confetti area360"},[_c('Lottie',{attrs:{"options":_vm.confettiLottieOption}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }