<template>
  <PopupLayout
    :paddingTop="2"
    :isTemplate="true"
    class="inner_stageup_layer"
    ref="stageupLayer"
    :compeleteBtnText="$poData.stage_up_btn"
    @onClickComplete="$emit('onClickComplete',realIndex)">
    <template v-slot:head>
      <!-- <p class="desc_info" v-html="convertLineFeed($poData.stage_up_subtitle.format(userLevel-1))"></p> -->
      <strong class="tit_layer">{{ $poData.stage_up_title }}</strong>
      <!-- {{ $poData.stage_up_title }} -->
      <div class="area_stage">
        <strong class="badge_stage">{{ $poData.stage_up_stage_num.format(userLevel) }}</strong>
      </div>
      <p class="desc_info">{{ $poData.stage_up_subtitle }}</p>
    </template>
    <div class="area_new_place" ref="newPlace"
      :style="{'--bodySize':`${bodySize}px` || '100%'}">
      <!-- <p class="desc_new_place">{{ $poData.stage_up_new_place }}</p> -->
      <div v-if="newPlaceList.length === 1" class="item_new_place">
        <div
         v-if="newPlaceList[0].thumbnail.imageUrl"
          class="img_place"
          :style="{
            backgroundImage: `url(${newPlaceList[0].thumbnail.imageUrl})`,
          }"
        />
        <Lottie
          v-else
          class="img_place"
          :options="stepStageUpLottieOption"
          @animCreated="handleAnimation" />
        <p class="desc_eng_name"><span>{{ newPlaceList[0].eng_name }}</span></p>
        <p class="desc_name">{{ newPlaceList[0].name }}</p>
        <Lottie
          class="lottie_confettie_img"
          :width="400"
          :height="400"
          :options="confettieImgLottieOption"
          @animCreated="handleAnimation" />
      </div>
      <template v-else>
        <Swiper
          class="swiper list_new_place"
          :options="swiperOption"
          ref="newPlaceSwiper"
          @slideChange="slideChange()">
          <SwiperSlide
            v-for="(place,index) in newPlaceList"
            :key="'Place'+index"
            :class="index <= visitedIndex ? 'item_visited' : 'item_disabled'">
            <div
              v-if="place.thumbnail.imageUrl"
              class="img_place"
              :style="{
                backgroundImage: `url(${place.thumbnail.imageUrl})`,
              }"
            />
            <Lottie
              v-else
              class="img_place"
              :width="240"
              :height="240"
              :options="stepStageUpLottieOption"
              @animCreated="handleAnimation" />
            <Lottie
              class="lottie_confettie_img"
              v-if="index <= visitedIndex && index === realIndex"
              :width="400"
              :height="400"
              :options="confettieImgLottieOption"
              @animCreated="handleAnimation" />
          </SwiperSlide>
        </Swiper>
          <p class="desc_eng_name"><span>{{ newPlaceList[realIndex].eng_name }}</span></p>
          <p class="desc_name">{{ newPlaceList[realIndex].name }}</p>
          <div class="swiper-pagination" slot="pagination"></div>
      </template>
    </div>
  </PopupLayout>
</template>
<script>
import PopupLayout from '@/components/layout/popup/PopupLayout'
import Lottie from 'vue-lottie';
import confettie from '@/assets/json/confettie.json';
import stepStageUp from '@/assets/json/step_stage_up.json';
import { Swiper, SwiperSlide, Pagination } from 'vue-awesome-swiper'
import { mapGetters } from 'vuex';

export default {
  name: 'StageUpPopup',
  props:{
    userLevel: Number,
    newPlaceList: Array
  },
  components:{
    PopupLayout,
    Swiper,
    SwiperSlide,
    Pagination,
    Lottie,
  },
  mounted(){
    this.setBodySize();
    // if(this.newPlaceList.length === 0 ){
      // this.newPlaceList = [
      //   {
      //     eng_name:'Lemontree Company',
      //     name:'레몬트리'
      //   },
      //   {
      //     eng_name:'Home',
      //     name:'집'
      //   },
      //   {
      //     eng_name:'Overseas Stock Market',
      //     name:'해외 증권거래소'
      //   },
      //   {
      //     eng_name:'Mine',
      //     name:'암호화페 채굴장'
      //   },
      //   {
      //     eng_name:'Flea Market',
      //     name:'중고시장'
      //   }
      // ]
    // }
  },
  data(){
    return{
      // newPlaceList:[],
      swiperOption: {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 16,
        speed:100,
        centeredSlides:true,
        mousewheel:{
          forceToAxis:true,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
      },
      realIndex:0,
      visitedIndex:0,
      bodySize:200,
      confettieImgLottieOption: {
        animationData: confettie,
        loop : false,
      },
      stepStageUpLottieOption: {
        animationData: stepStageUp,
        loop : false,
      },
    }
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowHeight' ]),
  },
  watch:{
    getWindowHeight(newVal, oldVal){
      if(newVal != oldVal){
        this.setBodySize()
      }
    }
  },
  methods:{
    setBodySize(){
      this.bodySize = this.$refs.newPlace.offsetHeight;
    },
    slideChange(){
      const realIndex = this.$refs.newPlaceSwiper.$swiper.realIndex;
      this.realIndex = realIndex;
      if(this.visitedIndex < realIndex){
        this.visitedIndex = realIndex;
      }
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
  },
}
</script>

<style scoped>
.inner_stageup_layer >>> .body_layer{overflow:inherit}
.inner_stageup_layer >>> .inner_body{height:100%;max-height:393px;padding:30px 0 123px;box-sizing:border-box}
.inner_stageup_layer >>> .inner_body .area_new_place,
.inner_stageup_layer >>> .inner_body .swiper-container{height:100%}
.inner_stageup_layer >>> .inner_body .img_place{display:block;height:100%;background-repeat:no-repeat;background-size:auto 100%;background-position:center}
.inner_stageup_layer >>> .inner_body .img_place img{display:none}
/* .inner_stageup_layer >>> .inner_body .img_place{display:inline-block;height:100%;background:none !important;vertical-align:top;}
.img_place img{display:block;height:100%;margin:0 auto} */

/* 높이 640 이하 디바이스 */
.short_device .inner_stageup_layer >>> .head_layer{padding-top:20px}
.short_device .inner_stageup_layer >>> .inner_body{max-height:317px;padding:23px 0 94px}
.short_device .desc_eng_name{margin-top:14px}
.short_device .swiper-pagination{margin-top:14px}
.short_device .area_new_place .lottie_confettie_img{bottom:-54px}
/* .short_device .inner_stageup_layer >>> .inner_body .img_place{width:200px;height:200px} */
/* .short_device .inner_stageup_layer >>> .foot_layer{padding-top:11px} */

.area_stage{margin-top:16px}
.badge_stage{display:inline-block;width:49px;height:46px;padding:6px 0;border-radius:100%;font-family:'Poppins';font-weight:700;font-size:28px;line-height:34px;background-color:#8F69DD;color:#fff;vertical-align:top;box-sizing:border-box}
.desc_info{margin-top:16px;font-weight:500;font-size:16px;line-height:24px;color:#666;text-align:center}

.area_new_place{position:relative}
.area_new_place .desc_new_place{margin-bottom:23px;font-weight:800;font-size:20px;line-height:30px}
.area_new_place .lottie_confettie_img{position:absolute;bottom:22px;left:50%;z-index:10;transform: translateX(-50%)}

.swiper.list_new_place{overflow:inherit}
.swiper.list_new_place >>> .swiper-wrapper{align-items:center;}
.item_new_place{margin:0 auto 22px;height:100%}
.item_new_place,
.swiper.list_new_place .swiper-slide{width:var(--bodySize)}
.area_place{font-size:0;white-space:nowrap;text-align:center}
.area_place:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.area_place .img_place{display:inline-block;width:240px;height:240px;background-repeat:no-repeat;background-size:100% 100%;vertical-align:middle;white-space:normal;transition:.3s filter ease-out}
/* .swiper.list_new_place >>> .lottie_confettie_img{opacity:0}
.swiper.list_new_place >>> .swiper-slide-active .lottie_confettie_img{opacity:1} */

.item_disabled .img_place{filter:saturate(0)}


.desc_eng_name{margin-top:16px;font-size:0;white-space:nowrap;text-align:center}
.desc_eng_name:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.desc_eng_name span{display:inline-block;font-weight:700;font-size:24px;line-height:30px;vertical-align:middle;white-space:normal;word-wrap:break-word;word-break:keep-all}
.desc_name{margin-top:4px;font-weight:500;font-size:12px;line-height:16px;text-align:center;color:#666}

.swiper-pagination{position:static;height:8px;margin-top:37px}
.swiper-pagination >>> .swiper-pagination-bullet{width:8px;height:8px;margin:0 3.5px;background-color:#E2D7C4;opacity:1;vertical-align:top}
.swiper-pagination >>> .swiper-pagination-bullet-active{background-color:#232323}

.item_confetti{position:absolute;left:50%;z-index:10;}

@keyframes positionSlideUpSlow {
  0%{
    transform:translateY(110vh);opacity:0;
  }
  100%{
    transform:translateY(0);opacity:1;
  }
}
@keyframes confettiExits {
  0%{
    transform:scale(1);opacity:1;
  }
  100%{
    /* transform:scale(1);opacity:1; */
    transform:scale(0);top:110px;margin-left:-15px;opacity:0
  }
}
.item_confetti{
  transform:translateY(110vh);opacity:0;
  animation-name: positionSlideUpSlow, confettiExits;
  animation-duration:0.5s, 0.3s;
  animation-timing-function: ease-out, ease-in;
  animation-fill-mode: forwards;
}
</style>