<template>
  <BottomSheetBase
    :title="text.title"
    :isShowDimmed="true"
    @onClickBack="$emit('onClickBack')"
  >
    <div class="content">
      <p class="text" v-html="text.description"></p>
    </div>
    <div class="buttonGroup">
      <Button
        btnSize="medium"
        btnStyle="secondary"
        :text="text.buttonYes"
        @onClickBtn="onClickSendSms()"
      >
      </Button>
      <button type="button" class="btnClose" @click="$emit('onClickClose')">
        {{ text.buttonCancel }}
      </button>
    </div>
  </BottomSheetBase>
</template>

<script>
import BottomSheetBase from '@/components/layout/bottomSheet/BottomSheetBase.vue';
import Button from '@/components/common/button/Button';

// mixin
import InterFaceMixin from '@/mixins/InterFaceMixin';
export default {
  name: 'RequestInvParentBottomSheet',
  components: { BottomSheetBase, Button },
  mixins: [InterFaceMixin],
  props: {
    title: String,
    text: {
      title: String,
      description: String,
      buttonYes: String,
      buttonCancel: String,
    },
  },
  methods:{
    onClickSendSms() {
      const smsText =
        '앞으로 용돈 관리는 퍼핀으로 해볼게요! 아껴 쓰고, 저금하고, 경제도 공부해 보고 싶은데 시작을 도와주세요~! 퍼핀 앱 다운로드 링크 : https://www.firfin.family/';
      const andInterfaceFunction = () => {
        window.FirFinInterface.sendSms(smsText);
      };
      const iosInterfaceFunction = () => {
        window.webkit.messageHandlers.FirFinInterface.postMessage({
          name: 'sendSms',
          text: smsText,
        });
      };
      this.customSendInterface(andInterfaceFunction, iosInterfaceFunction)
    }
  }
};
</script>

<style scoped>
.content {
  margin-top: 16px;
  margin-bottom: 24px;
}

.text {
  color: var(--font-font-200, #666);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.link_secondary {
  display: block;
  width: 100%;
  height: 56px;
  padding: 14px 16px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  background-color: #232323;
  color: #fff;
  text-align: center;
}
.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.btnClose {
  color: var(--font-font-200, #666);
  text-align: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
</style>
