<template>
  <div class="cont_world" :style="contCssProps">
    <StageMap :viewModel="viewModel" :isTestMode="isTestMode" />
    <!-- map UI -->
    <template
      v-if="viewModel.isShowMapUI || viewModel.firstLoadCheckList.isLoadFailed"
    >
      <!-- 상단 -->
      <div class="area_left">
        <button class="btn_back" @click.stop="onClickBackKey()">
          <IconSvg
            iconName="back"
            :size="24"
            :icoSize="16"
            iconColor="#232323"
          />
        </button>
      </div>
      <div v-if="!viewModel.firstLoadCheckList.isLoadFailed" class="area_right">
        <button
          class="btn_place_search"
          @click="viewModel.onClickPlaceSearchPopup()"
        >
          {{ $poData.worldmap_place_list }}
        </button>
      </div>
      <!-- 지도정보 -->
      <div ref="headMapInfo" class="head_map">
        <div
          class="img_cover"
          :style="{
            'background-image':`url('${contentData.MAP.thumbnail_image_url}')`
          }"
        ></div>
        <div class="info_head">
          <strong class="tit_map">{{ contentData.MAP.title }}</strong>
          <div class="left_reward">
            <span class="img_reward">
              <img
                class="img_g"
                :src="contentData.SPONSOR.image"
                :alt="contentData.SPONSOR.name"
              />
            </span>
            <p v-if="contentData.REWARD.is_show_remain_amount" class="desc_reward">
              <!-- 남은 리워드 <span class="txt_price">{{ viewModel.remainAmountText }}</span> -->
              <template v-if="!contentData.REWARD.is_reward_end">
                남은 리워드
                <span class="txt_price">
                  <br v-if="contentData.REWARD.is_coupon_reward"/>{{ contentData.REWARD.reward_description_combine }}
                </span>
              </template>
              <template v-else>
                {{ contentData.REWARD.reward_end_desc }}
              </template>
            </p>
            <p v-else class="desc_reward">
              퍼핀이 <span class="txt_price">리워드</span>를 <span class="txt_break_response">드려요!</span>
            </p>
          </div>
        </div>
      </div>
      <div class="bg_dimmed"></div>
    </template>
    <!-- popup -->
    <!-- 장소검색 팝업 -->
    <PlaceSearchPoup
      :isActive="viewModel.isPlaceSearchPopup"
      :placeSearchData="contentData.PLACE_SEARCH"
      :userLevel="contentData.USER.stage"
      :isShowSearchBar="false"
      :isLocked="isLocked"
      @onClickPlaceSearchItem="
        (placeId, placeStage) =>
          viewModel.onClickPlaceSearchItem(placeId, placeStage)
      "
      @onSearch="keyWord => viewModel.placeSearchKeyword(keyWord)"
      @onClickClosePlaceSearchPopup="viewModel.onClickClosePlaceSearchPopup()"
    />
    <Popup
      :direct="viewModel.popupModel.direct"
      :type="viewModel.popupModel.type"
      :bgColor="viewModel.popupModel.bgColor"
      :isDragable="viewModel.popupModel.isDragable"
      :isDimmedClose="viewModel.popupModel.isDimmedClose"
      :isActive="viewModel.popupModel.isActive"
      @onClickClose="viewModel.onClickClosePopup()"
    >
      <!-- 튜토리얼 팝업 -->
      <Tutorial
        v-if="viewModel.popupModel.name === 'Tutorial'"
        @onUpdatePopupData="
          popupParams => viewModel.popupModel.updatePopupData(popupParams)
        "
        @onClickClosePopup="viewModel.onClickClosePopup()"
        @onClickQuiz="viewModel.onClickTutorialQuiz()"
      />
      <!-- 장소상세 팝업 -->
      <PlaceDetailPopup
        v-if="viewModel.popupModel.name === 'PlaceDetailPopup'"
        :rewardData="contentData.REWARD"
        :mapType="contentData.MAP.map_type"
        :mapUid="contentData.MAP.uid"
        :userData="contentData.USER"
        :courseData="contentData.COURSE"
        :questData="contentData.QUEST"
        :placeDetail="contentData.PLACE_DETAIL"
        @onClickQuiz="viewModel.onClickQuiz()"
      />
      <!-- 퀴즈 팝업 -->
      <Quiz
        v-if="viewModel.popupModel.name === 'Quiz'"
        ref="Quiz"
        :userData="contentData.USER"
        :quizData="contentData.QUIZ"
        :questData="contentData.QUEST"
        :isForceHideMoneyReward="true"
        @onUpdatePopupData="
          popupParams => viewModel.popupModel.updatePopupData(popupParams)
        "
        @questClear="answerData => viewModel.questClear(answerData)"
        @questClearNoPopup="
          answerData => viewModel.questClearNoPopup(answerData)
        "
        @goToWorldMap="viewModel.placeDetailPopup()"
      />
      <!-- 퀘스트 완료 팝업 -->
      <QuestClearPopup
        v-if="viewModel.popupModel.name === 'QuestClearPopup'"
        :questData="contentData.QUEST"
        :checked_reward="contentData.USER.checked_reward"
        :sponsorName="contentData.SPONSOR.name"
        @onClickClose="viewModel.onClickCloseTutorialPopup()"
        @onClickComplete="viewModel.onClickCloseQuizPopup()"
      />
      <!-- 장소 완료 팝업 -->
      <PlaceClearPopup
        v-if="viewModel.popupModel.name === 'PlaceClearPopup'"
        :placeDetail="contentData.PLACE_DETAIL.place"
        :clearPlaceData="contentData.PLACE_DETAIL.clear_data"
        :checked_reward="contentData.USER.checked_reward"
        :thumbnailImageUrl="placeClearPopupThumbnailImageUrl"
        @onClickComplete="viewModel.onClickClosePlaceClearPopup()"
      />
      <!-- 스테이지 업 팝업 -->
      <StageUpPopup
        v-if="viewModel.popupModel.name === 'StageUpPopup'"
        :userLevel="contentData.USER.stage"
        :newPlaceList="
          contentData.PLACE.total_list.find(
            item => item.stage === contentData.USER.stage,
          ).placeList
        "
        @onClickComplete="
          realIndex => viewModel.onClickCloseStageUpPopup(realIndex)
        "
      />
      <!-- 맵완료 팝업 -->
      <MapCompletePopup
        v-if="viewModel.popupModel.name === 'MapCompletePopup'"
        :image="contentData.MAP.completed_image_url || contentData.MAP.thumbnail_image_url"
        @onClickComplete="viewModel.onClickCloseMapCompletePopup()"
      />
    </Popup>
    <Lock v-if="isLocked" @openUnlockBottomSheet="openUnlockBottomSheet" />
    <div v-if="viewModel.mapUid && isShowUnlockBottomSheet" class="bottomSheet">
      <RequestUnlockBottomSheet
        v-if="contentData.MAP.authentication.has_leader_parents"
        :text="{
          title: viewModel.isMapTypeSponsor ? $poData.send_unlock_request_reconfirm_title : $poData.send_plus_quiz_request_reconfirm_title,
          description: viewModel.isMapTypeSponsor ? $poData.send_unlock_request_reconfirm_desc : $poData.send_plus_quiz_request_reconfirm_desc,
          buttonYes: viewModel.isMapTypeSponsor ? $poData.send_unlock_request_reconfirm_yes : $poData.send_plus_quiz_request_reconfirm_yes,
          buttonCancel: viewModel.isMapTypeSponsor ? $poData.send_unlock_request_reconfirm_cancel : $poData.send_plus_quiz_request_reconfirm_cancel,
        }"
        :mapUid="viewModel.mapUid"
        @onClickBack="closeUnlockBottomSheet()"
        @onClickClose="closeUnlockBottomSheet()"
        @onCompleteRequest="handleCompleteRequest()"
      />
      <RequestInvParentBottomSheet
        v-else
        :text="{
          title: '부모님이 해주셔야 하는 기능이에요.<br/>퍼핀앱 가입을 요청해보세요!',
          description: '퍼핀의 모든 기능을 이용하려면 부모님께 가족 등록을 요청해주세요.',
          buttonYes: '부모님께 문자로 가입요청',
          buttonCancel: '다음에 할게요',
        }"
        @onClickBack="closeUnlockBottomSheet()"
        @onClickClose="closeUnlockBottomSheet()"
      />
    </div>
    <div v-if="isShowRequestCompleteDialog" class="dialogComplete">
      <DialogBase
        :title="$poData.sent_request_success_popup_title"
        :isShowDimmed="true"
      >
        <!-- @onClickBack="handleClickCompleteDialog()" -->
        <Button
          btnSize="medium"
          btnStyle="secondary_tonal"
          :text="$poData.sent_request_success_popupp_btn"
          @onClickBtn="handleClickCompleteDialog()"
        />
      </DialogBase>
    </div>
    <template v-if="isDevMode">
      <!-- controal test -->
      <template v-if="viewModel.isShowMapUI">
        <DevTools :viewModel="viewModel" :isTestMode.sync="isTestMode" />
        <div v-if="viewModel.isTestResultPopup" class="test_result_popup">
          <button @click="viewModel.onClickCloseTestPopup()">X</button>
          <div
            class="inner_test_result_popup"
            v-html="viewModel.testResult"
          ></div>
        </div>
      </template>
      <!-- test action dimmed -->
      <div v-if="viewModel.isAction" class="dimmed_action">
        <p v-if="isTestMode">ACTION</p>
      </div>
    </template>
  </div>
</template>
<script>
// viewModel
import StepMapViewModel from '@/views/stepMap/viewModel/StepMapViewModel';
import { mapGetters } from 'vuex';

// mixin
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';

import PlaceSearchPoup from '@/views/stepMap/view/popup/PlaceSearchPoup';

import StageMap from '@/components/shared/map/StageMap.vue';

import IconSvg from '@/components/common/icon/IconSvg';

import Tutorial from '@/views/tutorial/view/Tutorial';

import DevTools from '@/views/stepMap/view/DevTools.vue';

import Quiz from '@/views/quiz/view/Quiz';
import Popup from '@/components/layout/popup/Popup';

import PlaceDetailPopup from '@/views/worldMap/view/popup/PlaceDetailPopup';
import StageUpPopup from '@/views/worldMap/view/popup/StageUpPopup';
import QuestClearPopup from '@/components/shared/popup/QuestClearSponsorInfoPopup';
import PlaceClearPopup from '@/views/worldMap/view/popup/PlaceClearPopup';
import Lock from '@/components/layout/Lock.vue';
import MapCompletePopup from '@/components/shared/popup/MapCompletePopup.vue';

import RequestUnlockBottomSheet from '@/components/shared/bottomSheet/RequestUnlockBottomSheet.vue';
import RequestInvParentBottomSheet from '@/components/shared/bottomSheet/RequestInvParentBottomSheet.vue';
import DialogBase from '@/components/layout/dialog/DialogBase.vue';
import Button from '@/components/common/button/Button';


export default {
  name: 'StepMap',
  components: {
    IconSvg,
    Quiz,
    Popup,
    Tutorial,
    PlaceDetailPopup,
    PlaceSearchPoup,
    StageUpPopup,
    QuestClearPopup,
    PlaceClearPopup,
    StageMap,
    DevTools,
    Lock,
    RequestUnlockBottomSheet,
    RequestInvParentBottomSheet,
    DialogBase,
    Button,
    MapCompletePopup,
  },
  mixins: [InterFaceMixin, BackKeyEventMixin],
  data() {
    return {
      viewModel: new StepMapViewModel(this.$route.query.mapUid, this.$route.query.mapType),
      isTestMode: false,
      isTestResultPopup: false,
      isShowUnlockBottomSheet: false,
      isShowRequestCompleteDialog: false,
    };
  },
  updated(){
      if(this.$refs.headMapInfo && this.viewModel.horizonData.mapInfoHeight === 0){
        this.viewModel.horizonData.mapInfoHeight = this.$refs.headMapInfo.offsetHeight;

        this.viewModel.setPositionForce();
      }
  },
  computed: {
    ...mapGetters('commonViewport', [
      'getWindowWidth',
      'getWindowHeight',
      'getSafeAreaTopHeight',
      'getSafeAreaBottomHeight',
    ]),
    ...mapGetters('map', ['isLocked']),
    contCssProps() {
      var cssObj = {};
      const {
        topSafeAreaMarginTop,
        topMapShowAreaHeight,
        mapInfoMarginTop,
        mapInfoHeight,
        mapInfoMarginBottom,
      } = this.viewModel.horizonData;
      cssObj = {
        '--safeAreaTopHeight': `${this.getSafeAreaTopHeight + topSafeAreaMarginTop}px`,
        '--safeAreaTopHeightHead': `${this.getSafeAreaTopHeight + topMapShowAreaHeight + mapInfoMarginTop}px`,
        '--safeAreaTopHeightHeadDimmed': `${this.getSafeAreaTopHeight + topMapShowAreaHeight + mapInfoMarginTop + mapInfoHeight}px`,
        '--safeAreaTopHeightPlace': `${this.getSafeAreaTopHeight + topMapShowAreaHeight + mapInfoMarginTop + mapInfoHeight + mapInfoMarginBottom}px`,
        '--safeAreaTopHeightControal': `${this.getSafeAreaTopHeight + 80}px`, // dev tool 상단
        '--safeAreaBottomHeight': `${this.getSafeAreaBottomHeight + 24}px`,
      };
      if (this.isDevMode) {
        cssObj['--controalTestMaxHeight'] = `${this.getWindowHeight -
          220 -
          this.getSafeAreaTopHeight}px`;
      }
      return cssObj;
    },
    contentData() {
      return this.viewModel.model.contentData;
    },
    isDevMode() {
      return process.env.NODE_ENV != 'production';
    },
    placeClearPopupThumbnailImageUrl() {
      return this.viewModel.getThumbnailImageUrl(
        this.contentData.PLACE_DETAIL.place.id,
      );
    },
  },
  methods: {
    onClickBackKey() {
      if (this.viewModel.popupModel.name != 'Quiz') {
        if (this.viewModel.historyBackEvent) {
          this.viewModel.historyBackEvent();
        } else {
          this.goBack(this.viewModel.isFromWeb);
        }
      } else {
        this.$refs.Quiz.onClickBackKey();
      }
    },
    // 테스트 팝업
    onClickCloseTestPopup() {
      this.isTestResultPopup = false;
    },
    openUnlockBottomSheet() {
      this.isShowUnlockBottomSheet = true;
    },
    closeUnlockBottomSheet() {
      this.isShowUnlockBottomSheet = false;
    },
    handleCompleteRequest() {
      this.isShowRequestCompleteDialog = true;
      this.isShowUnlockBottomSheet = false;
    },
    handleClickCompleteDialog() {
      this.isShowRequestCompleteDialog = false;
    },
  },
};
</script>
<style scoped>
.cont_world {
  width: 100%;
  height: 100%;
}
.cont_world .area_map{
  padding-top:var(--safeAreaTopHeightPlace);
}

/* area_left */
.area_left {
  position: fixed;
  top: var(--safeAreaTopHeight);
  left: 20px;
  z-index: 100;
}
.btn_back {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: #fff;
}
/* area_right */
.area_right {
  position: fixed;
  top: var(--safeAreaTopHeight);
  right: 20px;
  z-index: 100;
}

.btn_place_search {
  height: 48px;
  padding: 12px 16px;
  border-radius: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background-color: #fff;
  color: #000;
}

.test_result_popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding-top: 30px;
  overflow-y: auto;
  background-color: #000;
  color: #fff;
}
.test_result_popup .inner_test_result_popup {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  word-wrap: break-word;
  word-break: keep-all;
}
.test_result_popup button {
  position: fixed;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: #fff;
  color: #000;
}
/* Head map */
.head_map{
  overflow:hidden;
  background-color:#fff;
  position: fixed;
  z-index: 100;
  top: var(--safeAreaTopHeightHead);
  left: 20px;
  right: 20px;
  border-radius:16px;
  padding:14px;
}
.head_map .img_cover{
  float:left;
  width:64px;
  height:64px;
  border-radius:12px;
  background-color:#ccc;
  background-size:cover;
}
.head_map .info_head{
  overflow:hidden;
  padding:4px 0 0 12px;
}
.head_map .info_head .tit_map{
  display:block;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing:-0.02em;
}
.head_map .info_head .desc_today_reward{
  display:inline-block;
  margin-top:8px;
  padding:4px 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius:100px;
  color:#F77833;
  background-color:#FBE9E5;
  vertical-align: top;
}
.head_map .info_head .left_reward{
  display:flex;
  margin-top:8px;
}
.head_map .info_head .left_reward .img_reward{
  overflow:hidden;
  flex:0 0 24px;
  width:24px;
  height:100%;
  margin-right:6px;
  border-radius:100px;
  background-color:#ccc;
  font-size:0;white-space:nowrap;text-align:center
}
.head_map .info_head .left_reward .img_reward:after{
  display:inline-block;
  height:100%;
  vertical-align:middle;
  content:''
}
.head_map .info_head .left_reward .img_reward .img_g{
  display:inline-block;
  width:24px;
  height:24px;
  vertical-align:middle;
  white-space:normal;
}
.desc_reward{
  overflow: hidden;
  padding:4px 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color:#111;
  letter-spacing:-0.02em;
}
.head_map .info_head .left_reward .txt_price{
  color:#8F69DD;
}

/* bg_dimmed */
.bg_dimmed{
  position:fixed;
  z-index: 90;
  top:0;
  left:0;
  right:0;
  height:var(--safeAreaTopHeightHeadDimmed);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);
  backdrop-filter: blur(6px);
}

/* dimmed_action */
.dimmed_action {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
}
/* ;background-color:rgba(0,0,0,.6) */
.dimmed_action p {
  display: inline-block;
  padding: 8px;
  background-color: #f00;
  color: #fff;
  vertical-align: top;
}

.area_bottom {
  position: fixed;
  bottom: var(--safeAreaBottomHeight);
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  box-sizing: border-box;
}

.bottomSheet,
.dialogComplete {
  pointer-events: auto;
}
</style>