<template>
  <div class="bg_done"
    :style="{
      marginLeft: `${ (bgDoneWidth/2 * -1) }px`
      }">
    <svg :width="bgDoneWidth" :height="bgDoneHeight" viewBox="0 0 212 188">
        <defs>
          <linearGradient x1="50.1272627%" y1="100%" x2="50.1272627%" y2="0%" id="linearGradient-s2u5hpnxi4-1">
              <stop stop-color="#FFD82A" stop-opacity="0.056" offset="25.0952%"></stop>
              <stop stop-color="#FFD82A" stop-opacity="0.2" offset="45.3702%"></stop>
              <stop stop-color="#FFD82A" stop-opacity="0" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g id="place" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.7">
            <g id="Artboard-Copy-2" transform="translate(-153.000000, -632.000000)" fill="url(#linearGradient-s2u5hpnxi4-1)" fill-rule="nonzero">
                <g id="Polygon-19" transform="translate(153.000000, 632.000000)">
                    <polygon id="Path" points="106.1932 187.979 7.10542736e-15 0 211.8452 0"></polygon>
                </g>
            </g>
        </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'PlaceDone',
  props:{
    placeWidth: Number
  },
  computed:{
    bgDoneWidth(){
      return this.placeWidth*0.75*2
    },
    bgDoneHeight(){
      return this.bgDoneWidth*0.88;
    }
  }
}
</script>

<style scoped>
.bg_done{position:absolute;top:2px;bottom:20px;left:50%;overflow:hidden;filter:blur(8px)}
</style>