
// import poData from '@/constants/poData.js';

export default class PopupModel {
  constructor(){
    this.name = ''; // 팝업 이름
    this.isActive = false; // 활성화 여부
    this.direct = ''; // 나오는 방향 [ bottom : 아래에서 올라오는 / right : 우측에서 나오는 ]
    this.type = ''; // 타입 [ bottom_sheet : 하단 바텀시트 형태 / full : 화면에 꽉차는 형태 ]
    this.bgColor = ''; // 배경 컬러 [ PRIMARY / PRIMARY200 / SECONDARY / SECONDARY100 / WHITE ]
    this.isDragable = false; // 드레그 가능 유무
    this.isDimmedClose = true; // 딤드 클릭시 닫히는 기능 유무
    this.closeCallBack = undefined; // 팝업이 닫혔을때 callBack
    // 위 데이터는 /components/layout/popup/Popup 내에서 관리 됩니다
  }
  setPopupData(obj){
    const { name, isActive, direct, type, bgColor, isDragable, isDimmedClose, closeCallBack } = obj;
    this.name = name;
    this.isActive = isActive;
    this.direct = direct;
    this.type = type;
    this.bgColor = bgColor;
    this.isDragable = isDragable;
    this.isDimmedClose = isDimmedClose;
    this.closeCallBack = closeCallBack;
  }
  updatePopupData(obj){
    const { name, direct, type, bgColor } = obj
    this.name = name || this.name;
    this.direct = direct || this.direct;
    this.type = type || this.type;
    this.bgColor = bgColor || this.bgColor;
  }
  resetPopupData(){
    this.name = '';
    this.isActive = false;
    this.direct = '';
    this.type = '';
    this.bgColor = '';
    this.isDragable = false;
    this.isDimmedClose = false;
    this.closeCallBack = undefined;
  }
  getPopupData(){
    let obj = {
      name : this.name,
      isActive : this.isActive,
      direct : this.direct,
      type : this.type,
      bgColor : this.bgColor,
      isDragable : this.isDragable,
      isDimmedClose : this.isDimmedClose,
      closeCallBack : this.closeCallBack,
    }
    return obj;
  }
}