<template>
  <PopupLayout
    ref="placeClearLayer"
    :paddingTop="0"
    :isTemplate="true"
    :class="['inner_map_complete_layer', { active: isMounted }]"
    :compeleteBtnText="$poData.place_quest_clear_btn"
    @onClickComplete="$emit('onClickComplete')"
  >
    <template v-slot:head>
      <div class="head_scroll">
        <div class="inner_head_scroll">
          <!-- 더 좋은 방법 필요하지만, 시간 관계 상 이렇게 마무리.. -->
          <span v-for="index in 30" :key="`${index}`">
            Congratulations
          </span>
        </div>
      </div>
      <h1 class="tit_map_complete" v-html="$poData.map_quest_clear_title"></h1>
    </template>
    <div ref="areaImg" class="area_img">
      <div
        class="img_map_cover"
        :style="{
          width: `${bodySize}px`,
          backgroundImage: `url(${image})`,
        }"
      ></div>
    </div>
    <p class="desc_map_complete">
      {{ $poData.map_quest_clear_body }}
    </p>
    <!-- <template v-slot:foot>
      <Button
        btnSize="medium"
        btnStyle="secondary"
        :text="$poData.map_quest_clear_main_btn"
        @onClickBtn="$emit('onClickConfirm')"
      />
    </template> -->
  </PopupLayout>
</template>
<script>
import PopupLayout from '@/components/layout/popup/PopupLayout';
import { mapGetters } from 'vuex';

export default {
  name: 'MapCompletePopup',
  components: {
    PopupLayout,
    // Lottie,
  },
  props: {
    image: String,
  },
  data() {
    return {
      isMounted: false,
      bodySize: 150
    };
  },
  async mounted() {
    await this.$nextTick();
    this.setBodySize();
    this.isMounted = true;
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowHeight' ]),
  },
  watch:{
    getWindowHeight(newVal, oldVal){
      if(newVal != oldVal){
        this.setBodySize()
      }
    }
  },
  methods:{
    setBodySize(){
      this.bodySize = this.$refs.areaImg.offsetHeight;
    },
  }
};
</script>

<style scoped>
.inner_map_complete_layer >>> .head_layer,
.inner_map_complete_layer >>> .foot_layer{
  position: relative;
  z-index: 1;
}
.head_scroll {
  padding: 10px 0;
  background: var(--secondary-black-200, #232323);
  color: var(--secondary-orange-200, #f98e5a);
  font-family: Poppins;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: 34px;
}
.inner_head_scroll {
  display: inline-flex;
  gap: 20px;

  padding: 0 20px;

  animation: scrollAnimation 18s linear infinite;
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0px);
  }
  100% {
    /* paddingX: 20, gap: 20, */
    transform: translateX(calc(-50% + 10px));
  }
}

.tit_map_complete {
  margin-top: 48px;
  color: var(--secondary-black-200, #232323);
  text-align: center;
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%; /* 50px */
  /* text-transform: uppercase; */

  transform: scale(0.1);
  transition: transform 0.3s ease-out;
  letter-spacing: -0.02em;
}
.short_device .tit_map_complete{font-size:42px}
.inner_map_complete_layer >>> .body_layer {
  overflow: inherit;
}
.inner_map_complete_layer >>> .inner_body {
  position: relative;
  height: 100%;
  max-height: 376px;
  box-sizing: border-box;
  padding: 24px 0 72px;
}
.area_img {
  position: relative;
  z-index: 0;
  height: 100%;
  line-height: 0;
}

.area_img .img_map_cover {
  position: relative;
  z-index: 0;
  max-width: 280px;
  max-height: 280px;
  height: 100%;
  margin: 0 auto;
  border-radius: 1000px;
  border: 3px solid var(--Secondary-black-200, #232323);
  overflow: hidden;
  box-sizing: border-box;
  background-size: cover;
  transform: scale(0.1);
  transition: transform 0.3s ease-out;
}

.desc_map_complete {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  z-index: 1;
  margin-top: 24px;
  color: var(--font-font-300, #333);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */

  transform: scale(0.1);
  transition: transform 0.3s ease-out;
}

.inner_map_complete_layer.active .tit_map_complete,
.inner_map_complete_layer.active .area_img .img_map_cover,
.inner_map_complete_layer.active .desc_map_complete {
  transform: scale(1);
}
</style>
