<template>
  <div
    id="map"
    ref="map"
    :class="[
      'area_map',
      { 
        action: viewModel.isAction, 
        selected: contentData.PLACE_DETAIL.id, 
        isPopup: viewModel.popupModel.name,
        scroll_bottom_sheet: viewModel.popupModel.name === 'PlaceDetailPopup'
      },
    ]"
    :style="mapCssProps"
    @click.capture="handleClickMap"
  >
    <div
      class="inner_map"
    >
      <!-- 비활성화 노출 장소 데이터 - TBD -->
      <!-- 장소 stage별 묶음 -->
      <div
        v-for="levelPlace in contentData.PLACE.total_list"
        :key="`levelPlace${levelPlace.stage}`"
        :class="[
          'group_level',
          `level${levelPlace.stage}`,
          {
            active:
              isLocked ||
              (contentData.USER.stage != null &&
                contentData.USER.stage >= levelPlace.stage),
            // active:
            //   contentData.USER.stage != null &&
            //   contentData.USER.stage >= levelPlace.stage,
            action:
              viewModel.animationData.stage != null &&
              viewModel.animationData.stage === levelPlace.stage,
            even: levelPlace.stage % 2 === 0,
            odd: levelPlace.stage % 2 != 0,
          },
        ]"
      >
        <!-- 장소 항목 -->
        <div
          v-for="place in levelPlace.placeList"
          :id="place.id"
          :key="`Place${place.id}`"
          :class="[
            'item_place',
            { selected: contentData.PLACE_DETAIL.place.id === place.id && !viewModel.isShowMapUI },
            { user_on: contentData.PLACE.random_place && contentData.PLACE.random_place.id === place.id },
            { clear : viewModel.checkPlaceClear(place.id) }
          ]"
          :ref="`place_${place.id}`"
          @click="viewModel.onClickPlace(place, levelPlace.stage)"
        >
          <!-- <span class="txt_name">{{ place.name }}</span> -->
          <div class="img_place img_today"></div>
          <div class="img_place img_active"></div>
          <div class="img_place img_disabled" ></div>
          <!-- 유저 프로필 -->
          <div
            v-if="contentData.PLACE.random_place && contentData.PLACE.random_place.id === place.id"
            class="profile_comm"
          >
          <p class="desc_today">오늘의 퀴즈</p>
            <IconSvg
              iconName="profile_arrow_bottom"
              :icoSize="14"
              iconColor="#232323"
            />
          </div>
          <!-- 장소 클리어 스타일 -->
          <!-- <template v-if="viewModel.checkPlaceClear(place.id)">
            <PlaceDone :placeWidth="place.width" />
            <Glowfly v-if="!viewModel.isShowMapUI" />
            <div class="icon_done"></div>
          </template> -->
        </div>
      </div>
      <!-- test guid line -->
      <template v-if="isTestMode">
        <div
          class="text_border"
          :style="[
            {
              top: `${viewModel.testBorderData.y * (100 / viewModel.scale) -
                4}px`,
              left: `${viewModel.testBorderData.x * (100 / viewModel.scale) -
                4}px`,
              width: `${viewModel.testBorderData.w}px`,
              height: `${viewModel.testBorderData.h}px`,
              fontSize: `${1500 / viewModel.scale}px`,
            },
          ]"
        ></div>
        <div
          class="test_spot test_spot_x"
          :style="[
            {
              left: `${viewModel.testSpotData.x * (100 / viewModel.scale)}px`,
              fontSize: `${1500 / viewModel.scale}px`,
            },
          ]"
        >
          x
        </div>
        <div
          class="test_spot test_spot_y"
          :style="[
            {
              top: `${viewModel.testSpotData.y * (100 / viewModel.scale)}px`,
              fontSize: `${1500 / viewModel.scale}px`,
            },
          ]"
        >
          y
        </div>
        <div class="test_ruler_x"></div>
        <div class="test_ruler_y"></div>
      </template>
    </div>
  </div>
</template>
<script>
import IconSvg from '@/components/common/icon/IconSvg';

import PlaceDone from '@/components/common/place/PlaceDone';
import Glowfly from '@/components/common/glowfly/Glowfly';

import { mapGetters } from 'vuex';

// mixin
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';

import { wordToCamal } from '@/utils/stringUtils'
// test

import { placeDrawDataList } from '@/constants/placeDrawDataList';

export default {
  name: 'StageMap',
  components: {
    IconSvg,
    PlaceDone,
    Glowfly,
  },
  mixins: [InterFaceMixin, BackKeyEventMixin],
  props: {
    viewModel: Object,
    isTestMode: Boolean
  },
  // data() {
  //   return {
  //     isTestMode: false,
  //   };
  // },
  mounted() {
    this.viewModel.map = this.$refs.map;
    const query = this.$route.query;
    // this.viewModel.init(query);

    const _this = this;
    window.addEventListener('load', function(event) {
      _this.viewModel.firstLoadCheckList.WINDOW_LOAD = true;
      _this.viewModel.mapRefsData = _this.$refs;
    
      _this.viewModel.init(query);
      _this.viewModel.loadFinish();
    });
  },
  computed: {
    ...mapGetters('commonViewport', [
      'getWindowWidth',
      'getWindowHeight',
      'getSafeAreaTopHeight',
      'getSafeAreaBottomHeight',
    ]),
    ...mapGetters('map', ['isLocked']),
    mapCssProps() {
      var cssObj = {};
      // const zoomPersent = ( (100 - this.viewModel.scale)/2 ) * -1
      cssObj = {
        '--zoom': `${this.viewModel.scale}%`,
        '--windowHeight': `${this.getWindowHeight}px`,
        '--mapBackgroundUrl': `url(${this.viewModel.model.contentData.MAP. thumbnail_image_url})`,
        // '--currentMapWidth': `${this.viewModel.model.contentData.MAP.width}px`,
        // '--currentMapHeight': `${this.viewModel.model.contentData.MAP.height}px`,
      };
      return cssObj;
    },
    contentData() {
      return this.viewModel.model.contentData;
    },
    isDevMode() {
      return process.env.NODE_ENV != 'production';
    },
  },
  methods: {
    // 유저 프로필 영역 장소 가운데 설정위해 장소 그림자 너비 제거용
    // getPlaceShadow(id) {
    //   return placeDrawDataList.find(item => item.id === id).bottomGround.left;
    // },
    handleClickMap(e) {
      if (!this.isLocked) {
        return;
      }

      // 잠김 상태면 맵 모든 클릭 이벤트 취소.
      e.preventDefault();
      e.stopPropagation();
    },
  },
};
</script>
<style scoped>
.area_map >>> .img_container {
  line-height: 0;
}

/* .cont_world {
  width: 100%;
  height: 100%;
  background: #499ccb;
} */
/* map */
.area_map {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  background:var(--mapBackgroundUrl);
  background-repeat:repeat;
  background-size:cover;
  background-position:center;
  box-sizing:border-box;
  padding-bottom:48px;
}
.area_map:after{
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:rgba(0,0,0,0.8);
  content:'';
}
.area_map::-webkit-scrollbar {
  display: none;
  width: 0; /* Remove scrollbar space */
  height: 0;
  background: transparent; /* Optional: just make scrollbar invisible */
  -webkit-appearance: none;
}
.area_map.isPopup{
  padding-bottom:var(--windowHeight)
}
/* .inner_map{position:relative;width:var(--currentMapWidth);height:var(--currentMapHeight);zoom:var(--zoom);background:url('/assets/images/map/map_empty/wave.png') repeat;background-size:262px 143px;} */
.inner_map {
  position: relative;
  z-index: 2;
  /* width: var(--currentMapWidth);
  height: var(--currentMapHeight); */
  /* zoom: var(--zoom); */
}
/* ship */
/* .bg_ship{position:absolute;background-size:cover;background-repeat:no-repeat}
.bg_ship1{top:450px;left:1154px;width:243px;height:140px}
.bg_ship2{top:3399px;left:1487px;width:244px;height:140px}
.bg_ship3{top:644px;left:2366px;width:93px;height:33px}
.bg_ship4{top:2554px;left:3030px;width:94px;height:34px}
.bg_ship5{top:1174px;left:3266px;width:185px;height:94px}
.bg_ship6{top:2743px;left:503px;width:184px;height:157px}
.bg_ship7{top:3074px;left:3095px;width:275px;height:102px} */
/* bg_map */
.group_bg_map .bg_map {
  position: absolute;

  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: top;
}
.group_bg_map .bg_map img {
  width: 100%;
  height: 100%;
}
/* scrollbar */
.area_map::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  border: 0 none;
  background-color: #f9f9f9;
}
.group_level{
  width:180px;
  margin:0 auto;
}
.group_level:first-child .item_place:first-child:after{
  position:absolute;
  top:50%;
  right:-72px;
  padding:8px 14px 8px 18px;
  border-radius: 6px;
  border: 2px solid #232323;
  font-family: Poppins;
  font-size:20px;
  font-weight: 800;
  line-height:24px;
  background: #F77833;
  color:#fff;
  transform: translateY(-50%);
  content:'Start';
}
.group_level:last-child .item_place:last-child:after{
  position:absolute;
  top:50%;
  padding:8px 14px 8px 18px;
  border-radius: 6px;
  border: 2px solid #232323;
  font-family: Poppins;
  font-size:20px;
  font-weight: 800;
  line-height:24px;
  background: #F77833;
  color:#fff;
  transform: translateY(-50%);
  content:'Goal';
}
.group_level.odd:last-child .item_place:last-child:after{
  right:-72px;
}
.group_level.even:last-child .item_place:last-child:after{
  left:-72px;
}
.group_level:nth-child(even){
  text-align:right
}
.group_level+.group_level{
  margin-top:12px;
}
.group_level .item_place {
  display:inline-block;
  position: relative;
  z-index: 10;
  /* text-align: center; */
  /* overflow:hidden; */
  width:106px;
  height:112px;
  vertical-align: top;
}
.group_level .item_place.user_on{
  margin-top:38px;
}
.group_level .item_place .txt_name{
  position:absolute;
  top:8px;
  left:0;
  right:0;
  z-index:1;
  font-size:16px;
  font-weight: 900;
  text-align:center;
}
.group_level .item_place .img_place {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: top;
  transform: translate(-50%, -50%);
}
.group_level .item_place.clear .img_active {
  opacity: 0;
  background:url('/assets/images/step/place_compelet.png') no-repeat 0 0;
  background-size:100% 100%;
}
.group_level .item_place .img_active {
  opacity: 0;
  background:url('/assets/images/step/place_active.png') no-repeat 0 0;
  background-size:100% 100%;
}
.group_level .item_place .img_disabled {
  background:url('/assets/images/step/place_disabled.png') no-repeat 0 0;
  background-size:100% 100%;
}
/* .group_level .item_place .img_disabled{opacity:0.6} */

/* selected */
.area_map.selected:after {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  content: '';
}
.group_level .item_place.selected {
  z-index: 30;
}
.group_level .item_place.selected:before {
  position:absolute;
  top:-7px;
  bottom:-7px;
  left:-7px;
  right:-7px;
  border:5px solid #BEA9ED;
  border-radius:27px;
  content:''
}
/* .group_level .item_place.selected .bg_done {
  opacity: 0.8;
} */

/* 해금 액션 장소 */
.group_level.action .item_place .img_active {
  opacity: 1;
  transition: opacity 0.4s, ease-in;
  transition-delay: 1s;
  animation: disabledAni 1.2s ease-in-out;
  -webkit-animation: disabledAni 1.2s ease-in-out;
}
.group_level.action .item_place .img_disabled {
  opacity: 0;
  transition: opacity 0.4s, ease-in;
  transition-delay: 1s;
  animation: disabledAni 1.2s ease-in-out;
  -webkit-animation: disabledAni 1.2s ease-in-out;
}
/* 해금된 장소 */
.group_level.active .item_place {
  z-index: 20;
}
.group_level.active .item_place .img_active {
  opacity: 1;
}
.group_level.active .item_place .img_disabled {
  opacity: 0;
}

@keyframes disabledAni {
  0% {
    width: 90%;
    height: 110%;
  }
  18% {
    width: 110%;
    height: 90%;
  }
  34% {
    width: 90%;
    height: 110%;
  }
  48% {
    width: 110%;
    height: 90%;
  }
  60% {
    width: 90%;
    height: 110%;
  }
  70% {
    width: 110%;
    height: 90%;
    filter: brightness(180%);
  }
  78% {
    width: 90%;
    height: 110%;
  }
  84% {
    width: 110%;
    height: 90%;
  }
  90% {
    width: 90%;
    height: 110%;
  }
  90% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 100%;
    height: 100%;
    filter: brightness(100%);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* 사용자 프로필 */
.profile_comm {
  position: absolute;
  top: 0;
  z-index: 20;
  width: 105px;
  /* margin: -78px 0 0 -40px; */
  margin-top: -42px;
  padding:8px 0;
  border-radius: 6px;
  background-color: #232323;
  animation: bounce 2s infinite;
  box-sizing: border-box;
  text-align:center;
}
.profile_comm .desc_today{
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color:#fff;
}
.profile_comm .icon_profile_arrow_bottom {
  position: absolute;
  bottom: -14px;
  left: 50%;
  margin-left: -7px;
}

/* 퀘스트완료 말풍선 */
/* .box_quest_status{position:absolute;top:0;left:50%;width:130px;margin:-40px 0 0 -65px;padding:12px 0 10px;border-radius:20px;background-color:#111;box-shadow:0 4px 4px rgba(0,0,0,.25)}
.box_quest_status .desc_box{font-weight:800;font-size:14px;line-height:24px;color:#3DBF69;text-align:center;letter-spacing:-0.05em} */

/* 퀘스트완료 아이콘 */
.icon_done {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -36px;
  margin-top: -36px;
  /* top: -32px;
  right: -26px; */
  z-index: 10;
  width: 72px;
  height: 72px;
  background: url('/assets/images/map/done.png') no-repeat 0 0;
  background-size: 72px;
}

/* test */
.text_border {
  position: absolute;
  border: 4px solid #f00;
  font-weight: 800;
  line-height: 1.2;
  color: #000;
  white-space: nowrap;
}

.test_spot {
  position: absolute;
  font-weight: 800;
  color: #000;
  background-color: #f00;
}
.test_spot_x {
  top: 0;
  width: 2px;
  height: 100%;
}
.test_spot_y {
  left: 0;
  height: 2px;
  width: 100%;
}

.test_ruler_x {
  position: fixed;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  border-left: 1px dashed #f00f0080;
}
.test_ruler_y {
  position: fixed;
  top: 50%;
  height: 1px;
  width: 100%;
  border-top: 1px dashed #f00f0080;
}
</style>
