
import { POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';

import QuizPageModel from '@/views/quiz/model/QuizPageModel';

import { getKoreanDateString } from '@/helpers/date';

export default class QuizViewModel {
  constructor(isForceHideMoneyReward = false){
    this.userData = {};
    this.quizPageModel = undefined;
    this.isTypingComment = false; // 해설 타이핑 애니메이션 종료 유무
    this.onUpdatePopupData = undefined; // 퀴즈 팝업 업데이트
    // this.onUpdatePopupData = {}; // 퀴즈 팝업 업데이트 data
    this.questClear = undefined; // 퀘스트 완료
    this.questClearNoPopup = undefined; // 퀘스트 완료 후 닫기
    this.goToWorldMap = undefined; // 월드맵으로 이동

    this.isForceHideMoneyReward = isForceHideMoneyReward;
  }
  init(){
    this.quizPageModel = new QuizPageModel();
  }
  setRewardData(placeDetail){
    this.quizPageModel.setRewardData(placeDetail);
  }
  quizStart(){
    if( this.quizPageModel.questData.lesson.lesson_position === 'BEFORE' ){
      this.setLessonBeforePage();
    }else if(this.quizPageModel.quizDataList[0].intro_contents){
      this.setIntroPage();
    }else{
      this.setQuestionPage();
    }
  }
  goNextPage(){
    switch(this.quizPageModel.quizPageMode){
      case 'Intro' : {
        this.setQuestionPage();
        break;
      }
      case 'Question' : {
        this.setResultPage();
        break;
      }
      case 'Result' : {
        // if(!this.isResultAnimationEnd) return;
        this.setExplanationPage();
        break;
      }
      case 'Explanation' : {
        if(!this.isTypingComment){
          this.isTypingComment = true;
          return
        }
        if(this.quizPageModel.selectedAnswerItem.is_last_quiz){
          if(this.quizPageModel.questData.lesson.lesson_position === 'AFTER'){
            this.setLessonAfterPage();
          }else{
            this.questClear();
            return;
          }
        }else{
          this.setNextQuiz();
        }
        break;
      }
      case 'Lesson' : {
        if(this.quizPageModel.questData.lesson.lesson_position === 'BEFORE'){
          if(this.quizPageModel.quizDataList[0].intro_contents){
            this.setIntroPage();
          }else{
            this.setQuestionPage();
          }
        }else if(this.quizPageModel.questData.lesson.lesson_position === 'AFTER'){
          this.questClear();
          return;
        }
        break;
      }
      default : return;
    }
    // this.setNextSwipePage();
  }

  setLessonBeforePage(){
    this.quizPageModel.paginationData.page = 0;
    this.quizPageModel.quizPageMode = 'Lesson';
  }
  setLessonAfterPage(){
    this.quizPageModel.paginationData.page = 0;
    this.quizPageModel.quizPageMode = 'Lesson';
  }
  setNextQuiz(){
    this.quizPageModel.paginationData.page += 1;
    this.quizPageModel.selectedAnswerItem = {};
    const currentQuizData = this.quizPageModel.quizDataList[this.quizPageModel.paginationData.page-1];
    this.quizPageModel.currentQuizData = currentQuizData;
    this.isTypingComment = false;
    if(currentQuizData.intro_contents){
      this.setIntroPage();
    }else{
      this.setQuestionPage();
    }
  }
  setIntroPage(){
    if(this.quizPageModel.paginationData.page === 0){
      this.quizPageModel.paginationData.page = 1;
    }
    this.quizPageModel.quizPageMode = 'Intro';
  }
  setQuestionPage(){
    if(this.quizPageModel.paginationData.page === 0){
      this.quizPageModel.paginationData.page = 1;
    }
    this.quizPageModel.quizPageMode = 'Question';
  }
  setResultPage(){
    // 은행연합회는 늘 같은 배경색이다. 은행엽합회가 아니고, 보상이 있으면 정오답에 따라 배경색이 달라진다.
    if(!this.isForceHideMoneyReward && this.quizPageModel.rewardData.checked_reward){
      const bgColor = this.quizPageModel.selectedAnswerItem.checked_answer ? 'PRIMARY200' : 'SECONDARY100';
      this.quizPageModel.quizPageBg = bgColor;
      this.onUpdatePopupData({ bgColor : bgColor });
    }
    this.quizPageModel.quizPageMode = 'Result';
    this.quizPageModel.paginationData.progressPage += 1;
  }
  setExplanationPage(){
    // 은행연합회는 늘 같은 배경색이다. 은행엽합회가 아니고, 보상이 있으면 정오답에 따라 배경색이 달라진다.
    if(!this.isForceHideMoneyReward && this.quizPageModel.rewardData.checked_reward){
      const bgColor = 'SECONDARY';
      this.quizPageModel.quizPageBg = bgColor;
      this.onUpdatePopupData({ bgColor : bgColor });
    }
    this.quizPageModel.quizPageMode = 'Explanation';
  }

  onClickAnswer(quizId,answer){
    if(quizId === 'TUTORIAL'){
      this.quizPageModel.setTutorialQuizAnswer(answer);
      this.goNextPage();
      return
    }
    const diffAnswer = this.quizPageModel.selectedAnswerList.find(item => item.quiz_id === quizId);
    if(!diffAnswer){
      this.quizPageModel.setQuizAnswerBefore(quizId,answer)
      this.postQuizAnswer(answer);
    }
  }

  getQuizList(questData, quizData){
    this.quizPageModel.setQuizData(quizData.quiz_list);
    this.quizPageModel.setQuestData(questData);
    this.quizStart()
  }
  postQuizAnswer(answer){
    const path = apiPath.QUIZ_ANSWER;
    // const data = {
    //   quest_id: this.quizPageModel.questData.id,
    //   quiz_id: this.quizPageModel.currentQuizData.id,
    //   user_answer: answer,
    // }

    const data = {
      header: {
        request_at: getKoreanDateString(),
      },
      body: {
        quest_id: this.quizPageModel.questData.id,
        quiz_id: this.quizPageModel.currentQuizData.id,
        user_answer: answer,
      },
    };

    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data.body;
      // resultData.user_answer = data.user_answer;
      resultData.user_answer = answer;

      this.quizPageModel.setQuizAnswer(resultData);
      this.goNextPage();
    }, (failed) => {
      this.goToWorldMap();
    })
  }
}
