<template>
  <div
    :class="[
      'lemontree_layer',
      `lemontree_layer_${type}`,
      direct ? `lemontree_layer_direct_${direct}` : '',
      { dragable: isDragable },
      { active: isActive },
      { openDrag: isOpenResult },
      { moving: initPoint != null },
    ]"
    :style="contCssProps"
  >
    <div
      ref="wrapLayer"
      class="wrap_layer"
      :style="{
        '--bottomSheetHeight': bottomSheetHeight
          ? bottomSheetHeight + 'px'
          : 'auto',
      }"
    >
      <div
        v-if="isDragable"
        class="area_drag"
        @mousedown="onListStart"
        @touchstart="onListStart"
        @mousemove="onListMove"
        @touchmove="onListMove"
        @mouseup="onListEnd"
        @mouseleave="onListEnd"
        @touchend="onListEnd"
        @touchcancle="onListEnd"
      >
        <span class="icon_drag"></span>
      </div>
      <slot />
    </div>
    <button
      v-if="type === 'bottom_sheet'"
      class="btn_close"
      @click="$emit('onClickClose')"
    >
      <template v-if="isDragable && isOpenResult">
        <IconSvg
          iconName="cross"
          :size="24"
          :icoSize="14"
          iconColor="#232323"
        />
      </template>
      <template v-else>
        <IconSvg
          iconName="map"
          :size="24"
          :icoSize="18"
          iconColor="#232323"
        />{{ $poData.place_details_go_worldmap_btn }}
      </template>
    </button>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg';
import BottomSheetMixin from '@/mixins/BottomSheetMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'Popup',
  components: {
    IconSvg,
  },
  mixins: [BottomSheetMixin],
  props: {
    direct: {
      type: String,
      default: '', // bottom / right
    },
    type: {
      type: String,
      default: 'full', // bottom_sheet, full
    },
    isDragable: {
      type: Boolean,
      default: false,
    },
    bgColor: String, // PRIMARY / PRIMARY200 / SECONDARY / SECONDARY100 / SECONDARY200 / WHITE
    isDimmedClose: {
      type: Boolean,
      default: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    // isOpenResult:{
    //   type:Boolean,
    //   default: false
    // },
  },
  data() {
    return {
      bottomSheetHeight: 0,
      isOpenResult: false,
    };
  },
  watch: {
    isActive() {
      this.setOverflowHidden();
      if (!this.isActive) {
        this.bottomSheetHeight = 0;
        this.isOpenResult = false;
      }
    },
  },
  updated() {
    this.$nextTick(function() {
      this.setBottomSheetHeight();
      this.setDragBottomSheet();
    });
  },
  mounted() {
    this.setOverflowHidden();
  },
  unmounted() {
    document.body.style.overflow = '';
  },
  computed: {
    ...mapGetters('commonViewport', [
      'getSafeAreaTopHeight',
      'getSafeAreaBottomHeight',
    ]),
    bgColorHex() {
      if (!this.bgColor) return '';

      switch (this.bgColor) {
        case 'PRIMARY': {
          return '#8F69DD';
        }
        case 'PRIMARY200': {
          return '#BEA9ED';
        }
        case 'SECONDARY': {
          return '#F1EEE1';
        }
        case 'SECONDARY200': {
          return '#F98E5A';
        }
        case 'SECONDARY100': {
          return '#FBAE8A';
        }
        case 'WHITE': {
          return '#FFF';
        }
        default: {
          return '#FFF';
        }
      }
    },
    contCssProps() {
      var cssObj = {};
      cssObj = {
        '--bgColor': this.bgColorHex,
        '--safeAreaTopHeight': `${this.getSafeAreaTopHeight + 3}px`,
        '--safeAreaBottomHeight': `${this.getSafeAreaBottomHeight - 3}px`,
      };
      return cssObj;
    },
  },
  methods: {
    onClickClose(isClose) {
      if (!isClose) return;
      document.body.style.overflow = '';
      this.$emit('onClickClose');
    },
    onClickComplete() {
      document.body.style.overflow = '';
      this.$emit('onClickComplete');
    },
    setOverflowHidden(){
      if(this.isActive){
        document.body.style.overflow = 'hidden';
      }else{
        document.body.style.overflow = '';
      }
    },
    setBottomSheetHeight() {
      // console.log('===== updated =====',this.type,this.bottomSheetHeight);
      if (this.type === 'bottom_sheet' && this.bottomSheetHeight === 0) {
        let maxHeight =
          this.getWindowHeight - 54 - this.getSafeAreaBottomHeight;
        const wrapLayerHeight = this.$refs.wrapLayer.offsetHeight;
        if (this.$children[0].isRewardInfo) {
          maxHeight -= 96;
        }
        this.bottomSheetHeight =
          maxHeight >= wrapLayerHeight ? wrapLayerHeight : maxHeight;
      }
      if (!this.type) {
        this.bottomSheetHeight = 0;
      }
    },
    setDragBottomSheet() {
      if (this.isDragable && !this.mobileBottomSheet) {
        this.bottomSheetMixinInit();
      }
      if (!this.isDragable) {
        if (this.mobileBottomSheet) {
          this.mobileBottomSheet = false;
        }
        this.$refs.wrapLayer.style.top = '';
      }
    },
  },
};
</script>

<style scoped>
/* -- 레이어 팝업 -- */
.lemontree_layer {
  position: fixed;
  top: 100%;
  /* bottom:0; */
  left: 0;
  right: 0;
  z-index: 210;
  min-width: 280px;
  height: 100%;

  /* transition:background-color 0.6s; */
}
.lemontree_layer.active {
  top: 0;
  bottom: 0;
}

/* direct */
.lemontree_layer_direct_bottom {
  top: 100%;
  bottom: inherit;
  transition: 0.6s;
}
.lemontree_layer_direct_bottom.active {
  top: 0;
  bottom: 0;
  transition: 0.6s;
}

/* type */
/* common */
.lemontree_layer .wrap_layer {
  position: absolute;
  width: 100%;
  transition: top 0.6s;
}
/* full */
.lemontree_layer_full .wrap_layer {
  bottom: 0;
  height: 100%;
  background-color: var(--bgColor);
  transition: height 0.6s;
  padding-bottom: var(--safeAreaBottomHeight);
  box-sizing: border-box;
}
/* bottom_sheet */
.lemontree_layer_bottom_sheet .wrap_layer {
  /* top:40%;
  height:60%; */
  bottom: 0;
  height: var(--bottomSheetHeight);
  background-color: #fff;
  border-radius: 8px 8px 0px 0px;
  transition: height 0.6s;
}
/* drag_bottom_sheet */
.lemontree_layer_bottom_sheet.dragable .wrap_layer {
  /* top:40%;
  height:60%; */
  top: 50%;
  height: 50%;
  background-color: #fff;
  border-radius: 8px 8px 0px 0px;
  transition: top 0.6s, height 0.6s;
}
.lemontree_layer_bottom_sheet.dragable.openDrag .wrap_layer {
  height: auto;
  border-radius: 0;
  border-top: 1px solid #e7e5e5;
}
.lemontree_layer_bottom_sheet.dragable.moving .wrap_layer {
  height: 100%;
}
.lemontree_layer_bottom_sheet.dragable.openDrag {
  background-color: #fff;
}
.lemontree_layer_bottom_sheet.dragable.openDrag .btn_close {
  right: 0;
  width: 64px;
  height: 54px;
  padding: 15px 20px;
}

/* area_drag */
.area_drag {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  padding: 8px;
  text-align: center;
}
.area_drag .icon_drag {
  display: inline-block;
  width: 40px;
  height: 4px;
  border-radius: 100px;
  background-color: #d9d9d9;
  vertical-align: top;
}

/* btn_close */
.btn_close {
  position: fixed;
  top: var(--safeAreaTopHeight);
  right: 20px;
  padding: 12px 16px 12px 14px;
  height: 48px;
  border-radius: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background-color: #fff;
  color: #111;
}
.btn_close .icon_map {
  margin-right: 8px;
}
</style>
