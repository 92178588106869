<template>
  <PopupLayout
    :paddingTop="2"
    :isTemplate="true"
    class="inner_place_clear_layer"
    ref="placeClearLayer"
    :compeleteBtnText="$poData.place_quest_clear_btn"
    @onClickComplete="$emit('onClickComplete')"
    :style="cssProps">
    <template v-slot:head>
      <span class="txt_small">{{ $poData.place_quest_clear_subtitle.format(placeDetail.name) }}</span>
      <strong class="tit_layer">{{ $poData.place_quest_clear_title }}</strong>
      <!-- {{ $poData.place_quest_clear_title }} -->
      <span v-if="checked_reward" class="badge_price badge_primary">{{ $poData.place_quest_clear_total_reward_1.format( addComma(clearPlaceData.total_accumulated_point) ) }}</span>
      <span v-else class="badge_price badge_primary">{{ $poData.place_quest_clear_total_reward_2 }}</span>
    </template>
    <div
      v-if="thumbnailImageUrl"
      class="img_place"
      :style="{
        backgroundImage: `url(${thumbnailImageUrl})`,
      }"
    />
    <Lottie
      v-else
      class="img_place"
      :options="stepPlaceClearLottieOption"
      @animCreated="handleAnimation" />
    <Lottie
      class="lottie_confettie_img"
      :width="400"
      :height="400"
      :options="confettieImgLottieOption"
      @animCreated="handleAnimation" />
    <template v-slot:foot>
      <p class="desc_layer">{{ $poData.place_quest_clear_right_answer_sum.format(clearPlaceData.total_quiz_count, clearPlaceData.total_accumulated_success_cnt) }}</p>
    </template>
  </PopupLayout>
</template>
<script>
import PopupLayout from '@/components/layout/popup/PopupLayout'
import Lottie from 'vue-lottie';
import confettie from '@/assets/json/confettie.json';
import stepPlaceClear from '@/assets/json/step_place_clear.json';
import { placeDrawDataList } from '@/constants/placeDrawDataList'
import { addComma } from '@/utils/stringUtils'

export default {
  name: 'PlaceClearPopup',
  props:{
    placeDetail: Object,
    clearPlaceData: Object,
    checked_reward: Boolean,
    thumbnailImageUrl: String,
  },
  components:{
    PopupLayout,
    Lottie,
  },
  mounted(){
    this.setScale()
  },
  data(){
    return{
      placeScale:'100%',
      confettieImgLottieOption: {
        animationData: confettie,
        loop : false,
      },
      stepPlaceClearLottieOption: {
        animationData: stepPlaceClear,
        loop : false,
      }
    }
  },
  computed:{
    cssProps(){
      var obj = {};
      obj = {
        '--placeScale':this.placeScale,
      };
      return obj;
    },
    bgDoneWidth(){
      return this.placeDetail.width*0.75*2
    },
    bgDoneHeight(){
      return this.bgDoneWidth*0.88;
    },
  },
  methods:{
    addComma(val){
      return addComma(val)
    },
    setScale(){
      const placeClearLayer = this.$refs.placeClearLayer;
      const areaPlaceHeight = placeClearLayer.$refs.bodyLayer.offsetHeight;
      const currentareaPlaceHeight = this.placeDetail.height + 40 + 24 + 30;
      let placeScale = (areaPlaceHeight/currentareaPlaceHeight)*100;
      if(placeScale > 100){
        placeScale = 100;
      }
      this.placeScale = placeScale+'%';
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
  },
}
</script>

<style scoped>
.inner_place_clear_layer >>> .head_layer .badge_price{margin-top:22px}
.inner_place_clear_layer >>> .head_layer .txt_small{padding:0 20px;word-wrap:break-word;word-break:keep-all}
.inner_place_clear_layer >>> .body_layer{overflow:inherit}
.inner_place_clear_layer >>> .inner_body{position:relative;max-width:300px;height:100%;max-height:320px;padding:40px 0;box-sizing:border-box}
.inner_place_clear_layer >>> .inner_body .img_place{display:block;height:100%;background-repeat:no-repeat;background-size:auto 100%;background-position:center}
.inner_place_clear_layer >>> .lottie_confettie_img{position:absolute;bottom:62px;left:50%;transform: translateX(-50%)}
.inner_place_clear_layer >>> .foot_layer{padding-top:12px}

/* 높이 640 이하 디바이스 */
.short_device .inner_place_clear_layer >>> .head_layer{padding-top:30px}
.short_device .inner_place_clear_layer >>> .head_layer .badge_price{margin-top:12px}
.short_device .inner_place_clear_layer >>> .inner_body{max-height:236px;padding:18px 0}
/* .short_device .area_place .img_place{width:200px;height:200px} */
.short_device .inner_place_clear_layer >>> .lottie_confettie_img{bottom:-54px}
.short_device .inner_place_clear_layer >>> .foot_layer{padding-top:0}

</style>