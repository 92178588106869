var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import apiPath from '@/services/apiPath';
import { GET_REQUEST } from '@/services/http';
import MapViewModelBase from '@/views/worldMap/viewModel/MapViewModelBase';
var PlusPaidMapViewModel = /** @class */ (function (_super) {
    __extends(PlusPaidMapViewModel, _super);
    function PlusPaidMapViewModel(mapUid) {
        return _super.call(this, mapUid) || this;
    }
    // 퀘스트 완료 팝업 실행
    PlusPaidMapViewModel.prototype.questClearPopupOpen = function () {
        var _this = this;
        var popupParams = {
            name: 'QuestClearPopup',
            isActive: true,
            direct: 'null',
            type: 'full',
            bgColor: 'WHITE',
            isDimmedClose: false,
            closeCallBack: undefined,
        };
        this.popupModel.setPopupData(popupParams);
        this.historyBackEvent = function () {
            _this.onClickCloseQuizPopup();
        };
    };
    // checked_reward 값 강제로 바꿔주려고 오버라이딩.
    PlusPaidMapViewModel.prototype.getUserStatus = function () {
        var _this = this;
        if (this.firstLoadCheckList.isLoadFailed)
            return;
        if (!this.mapUid)
            return;
        // const path = apiPath.USER_STATUS;
        var path = "".concat(apiPath.USER_STATUS.replace('%s', this.mapUid));
        return GET_REQUEST(path).then(function (success) {
            var resultData = success.data.body;
            // 강제!!! 스폰서맵은 보상 여부 무조건 true.
            resultData.user_status.checked_reward = true;
            _this.model.setUserStatus(resultData);
            return resultData;
        }, function (failed) {
            _this.firstLoadCheckList.isLoadFailed = true;
            // store.dispatch('commonToast/fetchToastStart', failed.msg);
            return null;
        });
    };
    return PlusPaidMapViewModel;
}(MapViewModelBase));
export default PlusPaidMapViewModel;
