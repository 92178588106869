import axios from 'axios';
import store from '@/store/index';
import { uuid } from 'vue-uuid';
import Vue from 'vue'
import LocalStorageManager from '@/storages/LocalStorageManager';



const http = axios.create({
  withCredentials: true,  // 자격 증명 추가 
  baseURL: process.env.VUE_APP_API_URL,
  
  headers: {
    'content-type': 'application/json',
  },
});

http.interceptors.request.use(
  function(config) {

    checkConfig(config);
    return config;
  },
  function(error) {
    // console.log('error Promise',error);
    return Promise.reject(error);
  },
);


function checkConfig(config) {
  config.headers['x-transaction-id'] = uuid.v1();
  config.headers['x-device-os'] = 'WEB';
  if(!config.headers['x-content-web-local'] && LocalStorageManager.shared.getToken()){
    config.headers['x-content-web-local'] = LocalStorageManager.shared.getToken();
  }
}

function httpErrorToast(data){
  const { type, title, message, button} = data;
  switch(type){
    case 'ALERT' : {
      const alertTitle = title ? `<strong>${title}</strong>` : '';
      const alertMessage = message ? message : '';
      store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `${alertTitle}${alertMessage}`,
        closeBtnText: button.positive_button.text,
      });
      break;
    }
    case 'TOAST' : {
      store.dispatch('commonToast/fetchToastStart', 
      {
        msg : title || '이용에 문제가 생겼어요. 먼저 인터넷 연결 상태를 확인해 보고 다시 시도해 주세요.',
        type: 'error'
      });
      break;
    }
  }
}

http.interceptors.response.use(
  async function(response) {
    return response;
  },
  function(error) {
    // 901 점검중
    if(error.response.data.header.result.code === '901'){
      const compeleteWebEnd = () => {
        //안드로이드
        if ( Vue.prototype.$varUA === 'ANDROID') {
          window.FirFinInterface.webEnd();
        }
        //IOS
        else if ( Vue.prototype.$varUA === 'IOS' ) {
          window.webkit.messageHandlers.FirFinInterface.postMessage("webEnd");
        }
      }
      store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `<strong>퍼핀이 정기점검 중이에요</strong>이 문제가 계속되면 퍼핀 앱 또는 홈페이지의 고객센터로 문의해 주세요.`,
        compeleteBtnText: '알겠어요',
        isBackEventCompelete: true,
        confirmCallBack: compeleteWebEnd,
      });
      // return Promise.reject(error);
      return
    }
    else{
      return Promise.reject(error);
    }
  },
);

export function GET_REQUEST(path) {
  return new Promise((success, failed) => {
    http.get(path).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // let result = {
      //   errCode: err.response.data.code,
      //   msg: err.response.data.message.toString()
      // }
      failed(err)
      if(!err) return
      httpErrorToast(err.response.data.header.notice)
    })
  })
}

export function PUT_REQUEST(path, data) {
  return new Promise((success, failed) => {
    http.put(path, data).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        // const description = data.header.result.description
        const errCode = '200'
        const errMsg = 'errMsg'
        const description = 'description'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg + '\n' + description
          }
          failed(result)

        }else{

          let result = {
            data : data.body
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // let result = {
      //   errCode: err.response.data.code,
      //   msg: err.response.data.message.toString()
      // }
      failed(err)
      if(!err) return
      httpErrorToast(err.response.data.header.notice)
    })
  })
}

export function POST_REQUEST(path, data, config) {
  return new Promise((success, failed) => {
    http.post(path, data, config).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // let result = {
      //   errCode: err.response.data.code,
      //   msg: err.response.data.message.toString()
      // }
      failed(err)
      if(!err) return
      httpErrorToast(err.response.data.header.notice)
    })
  })
}

export function DELETE_REQUEST(path) {
  return new Promise((success, failed) => {
    http.delete(path).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data.body
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // let result = {
      //   errCode: err.response.data.code,
      //   msg: err.response.data.message.toString()
      // }
      failed(err)
      if(!err) return
      httpErrorToast(err.response.data.header.notice)
    })
  })
}

export default http;
