import ContentModel from '@/views/worldMap/model/ContentModel'
import PopupModel from '@/views/worldMap/model/PopupModel'

import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import { makeQueryStringByObject } from '@/utils/urlUtils'
import Vue from 'vue'

export default class WorldMapViewModel {
  constructor(){
    this.hasMapAuth = true; // 권한 유무

    this.isLoaded = false; // 로딩 완료 여부
    this.firstLoadCheckList = { // 첫 진입 로딩 완료 체크 리스트
      WINDOW_LOAD: false,
      COMMON:{ // 공통
        isLoadedPlaceListArea: false, // 장소 목록 API
        isLoadedUserStatus: false, // 유저 정보 API
        isLoadedMapInfo: false, // 맵 정보 API
      },
      WORLD_MAP:{ // 월드맵 진입
        isLoadedPlaceFocus: false, // 장소 포커스
      },
      TODAY_QUEST:{ // 오늘의 퀘스트 진입
        isLoadedPlaceDetailPopup: false, // 장소 팝업
      },
      TUTORIAL:{ // 튜토리얼 진입
        isLoadedTutorial: false, // 튜토리얼 팝업
      },
      isLoadFailed: false,
    };
    this.historyBackEvent = undefined; // 뒤로가기 이벤트
    // 지도 엘리먼트
    this.map = undefined;
    // this.mapLandIndex = [ 9,10,11,12,13,17,18,19,20,21,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,53,54 ]
    // 디바이스 사이즈
    this.getWindowWidth = store.getters['commonViewport/getWindowWidth'];
    this.getWindowHeight = store.getters['commonViewport/getWindowHeight'];
    // 지도 배경
    // this.currentMapSize = 4000; // 원본 지도 배경 넓이, 높이 값
    // this.bgCut = 8; // 행, 열 별로 분할 수

    // this.mapInfo = {
    //   id: null,
    //   uid: null,
    //   title: null,
    //   mapCategoryId: null,
    //   mapType: null,
    //   width: 0,
    //   height: 0,
    //   rowCount: 0,
    //   columnCount: 0,
    //   list: [],
    // };

    // this.currentMapWidth = 4000;
    // this.currentMapHeight = 4000;
    // this.rowCount = 8;
    // this.columnCount = 8;
    this.scale = 75; // 스케일
    this.minScale = null; // 디바이스 기준 최소 스케일
    this.isShowMapUI = false; // 월드맵 UI 노출 유무
    this.isOpenControal = false; // 테스트 컨트롤러 오픈 유무
    // 애니메이션 동작
    this.isAction = false; // 동작 중 유무
    this.animationData = {
      stage:null,
    }

    // 데이터
    this.model = new ContentModel();
    this.popupModel = new PopupModel();

    // 장소검색 팝업
    this.isPlaceSearchPopup = false;

    // 테스트 테이터
    this.isWebDirect = false;
    this.scaleDataList = [ // 줌 인&아웃 테스트 데이터
      { id:1, text:25 },
      { id:2, text:50 },
      { id:3, text:75 },
      { id:4, text:100 },
    ];
    this.testBorderData = {x:0,y:0,w:0,h:0};
    this.testSpotData = {x:0,y:0};
    this.isTestResultPopup = false;
    this.testResult = '';

    this.isShowMapCompletePopup = false;

    // 중복 클릭 호출 방지.
    this.isPreparingQuest = false;

    this.isFromWeb = false;
  }

  async init(query){
    throw new Error('override this!');
  }

  getMapResource() {
    throw new Error('override this!');
  }

  getThumbnailImageUrl(placeId) {
    throw new Error('override this!');
  }

  // 로드 완료 체크
  loadFinish(){
    if(this.firstLoadCheckList.isLoadFailed){
      // 맵내 지정한 위치 보여주기 (현재 공항 위치로 고정되어있음)
      const params = {
        place: {
          x: 2714,
          y: 1940,
          width:143,
          height:78
        },
        isSmooth: false,
        scale: 75,
        duration: 0,
        topHeightPercent : 100,
      }
      this.setPosition(params);

      // 로드완료 interface (loadFinish) 전달
      this.sendLoadFinishInterface();
    }

    // firstLoadCheckList 내 해당하는 진입타입에 로드 완료되지 않은게 있는지 체크
    const loadTypeFinish = Object.values(this.firstLoadCheckList[this.model.contentData.USER.init_type]).find(item => item === false) === undefined;
    // firstLoadCheckList 내 공통에 로드 완료되지 않은게 있는지 체크
    const commonLoadTypeFinish = Object.values(this.firstLoadCheckList.COMMON).find(item => item === false) === undefined;
    // 로드 된거 체크
    if(this.firstLoadCheckList.WINDOW_LOAD && commonLoadTypeFinish && loadTypeFinish){
      // 월드맵 진입시 MapUI 노출
      this.isShowMapUI = this.model.contentData.USER.init_type === 'WORLD_MAP';
      this.isLoaded = true;
      // 로드완료 interface (loadFinish) 전달

      this.sendLoadFinishInterface();
    }
  }
  // 로드완료 interface (loadFinish) 전달
  sendLoadFinishInterface(){
    try {
      //안드로이드
      if ( Vue.prototype.$varUA === 'ANDROID') {
        window.FirFinInterface.loadFinish();
      }
      //IOS
      else if ( Vue.prototype.$varUA === 'IOS' ) {
        window.webkit.messageHandlers.FirFinInterface.postMessage("loadFinish");
      }
    } catch (e) {
      // do nothing.
    }

    try {
      //안드로이드
      if ( Vue.prototype.$varUA === 'ANDROID') {
        window.FirFinInterface.questLoadEnd();
      }
      //IOS
      else if ( Vue.prototype.$varUA === 'IOS' ) {
        window.webkit.messageHandlers.FirFinInterface.postMessage("questLoadEnd");
      }
    } catch (e) {
      // do nothing.
    }
  }
  setFirstLoadCheckList(keyName,value){
    if(this.firstLoadCheckList.COMMON[keyName] != undefined){
      this.firstLoadCheckList.COMMON[keyName] = value;
    }else{
      this.firstLoadCheckList[this.model.contentData.USER.init_type][keyName] = value;
    }
    this.loadFinish();
  }


  // * -- common --- *
  // 뒤로기기

  // 진입 형태 체크
  checkInitType(resultData){
    // 오늘의 퀘스트 진입시
    if (this.model.contentData.USER.init_type === 'TODAY_QUEST') {
      this.initTodayQuest();

      // 튜토리얼 진입시
    } else if (this.model.contentData.USER.init_type === 'TUTORIAL') {
      this.postTutorialDone();

      // 일반 월드맵으로 진입시
    } else {
      this.initWorldMap(resultData);
    }
  }

  // 오늘의 퀘스트 진입시
  initTodayQuest(){
    const getPlaceDetailCallBack = (placeDetailResultData) => {
      if(this.model.contentData.USER.stage < placeDetailResultData.stage || this.isAction){
        this.firstLoadCheckList.isLoadFailed = true;
        return;
      }
      this.onClickPlace(placeDetailResultData, placeDetailResultData.stage, false);
    }
    const { place_id } = this.model.contentData.USER.today_quest
    this.getPlaceDetail(place_id,getPlaceDetailCallBack);
  }
  // 일반 월드맵으로 진입시
  initWorldMap(resultData){
    const initPlaceFocus = () => {
      this.setFirstLoadCheckList('isLoadedPlaceFocus',true)
    }
    let currentCenterData = resultData.place_info.random_place.place || resultData.place_info.last_access_place;
    let params = {
      place : currentCenterData,
      isSmooth: false,
      callBack: {
        isCallBack: true,
        delay: 0,
        event: initPlaceFocus,
      }
    }
    this.setPosition(params);
  }
  // 최소 줌아웃 사이즈 체크 
  setMinScale(){
    // const maxWindow = Math.max(this.getWindowWidth, this.getWindowHeight);
    // this.minScale = (maxWindow / this.currentMapSize) * 100;

    const widthScale = this.getWindowWidth / this.model.contentData.MAP.width;
    const heightScale = this.getWindowHeight / this.model.contentData.MAP.height;
    this.minScale = Math.max(widthScale, heightScale) * 100; // TODO.
  }
  // 클리어 장소 체크
  checkPlaceClear(id){
    if(!this.model.contentData.PLACE.cleared_place_list || this.model.contentData.PLACE.cleared_place_list.length === 0) return false;
    return this.model.contentData.PLACE.cleared_place_list.findIndex(item => item === id) >= 0
  }
  // 팝업 닫기
  onClickClosePopup(){
    if(this.popupModel.closeCallBack){
      this.popupModel.closeCallBack()
    }
    this.popupModel.resetPopupData();
    this.historyBackEvent = undefined;
  }
  // 테스트] 테스트 팝업
  onClickTestPopup(){
    this.isTestResultPopup = true;
  }
  onClickCloseTestPopup(){
    this.isTestResultPopup = false;
  }


  // * -- 튜토리얼 --- *
  // 튜토리얼 팝업 실행
  tutorialPopup(){
    // 튜토리얼 팝업 종료후 포커싱 되어있어야 할 장소 미리 포커싱해서 뒤에 깔아주고
    this.placeDetailFocusIn(this.model.contentData.PLACE_DETAIL.place);
    // 유저의 장소 상세 정보 API 호출
    this.getUserPlaceStatus(this.model.contentData.PLACE_DETAIL.place);
    // 튜토리얼 팝업 세팅
    const popupParams = {
      name:'Tutorial',
      isActive:true,
      type: 'full',
      bgColor:'SECONDARY',
    }
    this.popupModel.setPopupData(popupParams);
    // 튜토리얼 팝업에서 뒤로가기 물리버튼 이벤트시 동작 적용
    this.historyBackEvent = () => {
      const onClickWebEnd = () => {
        // this.onClickWebEnd();
        this.goBack();
      }
      store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `<strong>퍼핀월드 안내를 종료할까요?</strong>지금 아니면 다시는 못 봐요.<br>30초만 시간 내주시면 안 될까요? 🥺`,
        closeBtnText: '계속',
        compeleteBtnText: '종료하기',
        isBackEventCompelete: true,
        confirmCallBack: onClickWebEnd,
      });
    };
    // 첫 진입 퀴즈팝업 체크
    if(!this.isLoaded && this.model.contentData.USER.init_type === 'TUTORIAL'){
      this.setFirstLoadCheckList('isLoadedTutorial',true)
    }
  }
  goBack() {
    try {
      //안드로이드
      if ( Vue.prototype.$varUA === 'ANDROID') {
        window.FirFinInterface.goBack();
      }
      //IOS
      else if ( Vue.prototype.$varUA === 'IOS' ) {
        if (this.isFromWeb) {
          window.webkit.messageHandlers.FirFinInterface.postMessage("goBack");
        } else {
          window.webkit.messageHandlers.FirFinInterface.postMessage("webEnd");
        }
      }
    } catch (e) {
      this.webEnd();
    }
  }
  webEnd() {
    try {
      //안드로이드
      if ( Vue.prototype.$varUA === 'ANDROID') {
        window.FirFinInterface.webEnd();
      }
      //IOS
      else if ( Vue.prototype.$varUA === 'IOS' ) {
        window.webkit.messageHandlers.FirFinInterface.postMessage("webEnd");
      }
    } catch (e) {
      // do nothing.
    }
  }
  onClickTutorialQuiz(){
    this.model.setTutorialQuiz();
    this.quizPopup();
  }
  // 퀴즈 팝업 실행
  tutorialQuizPopup(){
    const popupParams = {
      name:'Quiz',
      isActive:true,
      type:'full',
      bgColor:'SECONDARY',
      isDimmedClose: false,
      closeCallBack: () => {
        this.quizPopup();
      }
    }
    this.popupModel.setPopupData(popupParams);
  }


  // * -- 장소 검색 --- *
  // 장소 검색 팝업 실행
  onClickPlaceSearchPopup(){
    // this.isPlaceSearchPopup = true;
    const getPlaceSearchListCallBack = (resultData) => {
      this.isPlaceSearchPopup = true;
    }
    this.getPlaceSearchList(getPlaceSearchListCallBack); // 장소 검색 목록
    this.historyBackEvent = () => {
      this.onClickClosePlaceSearchPopup();
    };
  }
  // 장소 키워드 검색
  placeSearchKeyword(keyWord){
    if(keyWord != ''){
      keyWord = keyWord.trim();
      this.model.contentData.PLACE_SEARCH.search_keyword = keyWord;
      // 이미 검색한 값일 경우
      const historyItem = this.model.getPlaceSearchKeywordHistoryList(keyWord);
      if(historyItem){
        // 검색 결과, 빈결과 문구 적용
        this.model.setPlaceSearchedList(historyItem)
      
      // 새로 검색한 값일 경우
      }else{
        this.getPlaceSearchKeyword(keyWord);
      }
    }else{
      this.model.resetPlaceSearchKeyword();
    }
  }
  // 장소 검색 팝업 닫기
  onClickClosePlaceSearchPopup(){
    this.isPlaceSearchPopup = false;
    this.model.resetPlaceSearchKeyword();
    this.historyBackEvent = undefined;
  }
  // 장소 선택
  onClickPlaceSearchItem(placeId,placeStage){
    const stageData = this.model.contentData.PLACE.total_list.find(item => item.stage === placeStage);
    const placeData = stageData.placeList.find(item => item.id === placeId);
    this.onClickClosePlaceSearchPopup();
    this.onClickPlace(placeData,placeStage);
  }


  // * -- 장소 상세 --- *
  // 장소 선택
  onClickPlace(place,placeStage, isSmooth = true){
    if(this.model.contentData.USER.stage < placeStage || this.isAction){
      return;
    }
    this.model.setPlaceDetail(place);
    const getUserPlaceStatusCallBack = (placeData,resultData) => {
      const isClearedPlace = resultData.body.check_cleared_place;
      this.placeDetailPopup(isClearedPlace);
      this.placeDetailFocusIn(placeData, isSmooth);
    }
    this.getUserPlaceStatus(place,getUserPlaceStatusCallBack);
  }
  // 장소 상세 팝업 실행
  placeDetailPopup(isDragable){
    const popupParams = {
      name:'PlaceDetailPopup',
      isActive:true,
      direct:'bottom',
      type: 'bottom_sheet',
      bgColor:'WHITE',
      isDragable: isDragable,
      isDimmedClose: true,
      closeCallBack: () => {
        this.placeDetailFocusOut()
      }
    }
    this.popupModel.setPopupData(popupParams);
    this.historyBackEvent = () => {
      this.onClickClosePopup();
      this.placeDetailFocusOut();
    };
    // 첫 진입 퀴즈팝업 체크
    if(!this.isLoaded && this.model.contentData.USER.init_type === 'TODAY_QUEST'){
      this.setFirstLoadCheckList('isLoadedPlaceDetailPopup',true)
    }
  }
  // 장소 상세 포커스 In
  placeDetailFocusIn(placeData, isSmooth = true){
    this.isShowMapUI = false;
    const params = {
      place : placeData,
      isSmooth: isSmooth,
      scale: 100,
      duration: 100,
      topHeightPercent : 40,
    }
    this.setPosition(params);
  }
  // 장소 상세 포커스 In - not smooth / 40%
  placeDetailFocusInNotSmooth(placeData){
    this.isShowMapUI = false;
    const params = {
      place : placeData,
      isSmooth: false,
      scale: 100,
      topHeightPercent : 40,
    }
    this.setPosition(params);
  }
  // 장소 상세 포커스 Out
  placeDetailFocusOut(){
    const params = {
      place : this.model.contentData.PLACE_DETAIL.place,
      isSmooth: true,
      scale: 75,
      duration: 100,
    }
    this.setPosition(params)
    this.isAction = false;
    this.isShowMapUI = true;
  }


  // * -- 퀴즈 --- *
  // 퀴즈 실행
  onClickQuiz(){
    this.postQuestStatus();
  }
  setFamilyWalletCheck(resultData){
    const code = resultData.header.result.code;
    const message = resultData.header.result.message;
    // 퀴즈 진행 가능
    if(code === "200"){
      this.getQuizList();
    } else {
      this.isPreparingQuest = false;
    }
  }
  // 퀴즈 팝업 실행
  quizPopup(){
    const popupParams = {
      name:'Quiz',
      isActive:true,
      type:'full',
      bgColor:'SECONDARY',
      isDimmedClose: false,
    }
    this.popupModel.setPopupData(popupParams);
  }
  // 퀘스트 내 퀴즈 완료시
  questClear(answerData){
    this.model.contentData.QUEST.last_answer = answerData;
    // 튜토리얼일때 퀘스트 완료 팝업만 열기
    if(answerData.quiz_id === 'TUTORIAL') {
      this.questClearPopupOpen();
      return;
    };

    // 퀘스트 완료할 때마다, 스폰서 맵 남은 상금 호출 하겠다.
    this.onClearQuest();

    this.getUserPlaceStatus(this.model.contentData.PLACE_DETAIL.place);
    this.getUserStatus();
    if(this.model.contentData.QUEST.last_answer.is_last_quest_in_place){
      this.getPlaceClear();
    }else{
      this.questClearPopupOpen();
    }
  }

  // 필요하면 오버라이드 하세요..
  onClearQuest() {}

  // 퀘스트 내 퀴즈 완료 후 닫기
  questClearNoPopup(answerData){
    this.model.contentData.QUEST.last_answer = answerData;
    this.getUserPlaceStatus(this.model.contentData.PLACE_DETAIL.place);
    this.getUserStatus();
    this.placeDetailPopup();
  }

  // 퀘스트 완료 팝업 실행
  questClearPopupOpen(){
    const popupParams = {
      name:'QuestClearPopup',
      isActive:true,
      direct:'null',
      type:'full',
      bgColor:'PRIMARY',
      isDimmedClose: false,
      closeCallBack: undefined
    }
    this.popupModel.setPopupData(popupParams);
    this.historyBackEvent = () => {
      this.onClickCloseQuizPopup();
    };
  }
  // 장소 완료 팝업 실행
  placeClearPopupOpen(){
    const popupParams = {
      name:'PlaceClearPopup',
      isActive:true,
      type:'full',
      bgColor:'SECONDARY',
      isDimmedClose: true,
      closeCallBack: undefined
    }
    this.popupModel.setPopupData(popupParams);
    this.historyBackEvent = () => {
      this.onClickClosePlaceClearPopup();
    };
  }
  // 스테이지업 팝업 실행
  stageUpPopupOpen(){
    const popupParams = {
      name:'StageUpPopup',
      isActive:true,
      type:'full',
      bgColor:'SECONDARY',
      isDimmedClose: true,
      closeCallBack: undefined
    }
    this.popupModel.setPopupData(popupParams);
    this.historyBackEvent = () => {
      this.onClickCloseStageUpPopup(0);
    };
  }
  // 맵완료 팝업 실행
  mapCompletePopupOpen(){
    const popupParams = {
      name:'MapCompletePopup',
      isActive:true,
      type:'full',
      bgColor:'SECONDARY200',
      isDimmedClose: true,
      closeCallBack: undefined
    }
    this.popupModel.setPopupData(popupParams);
    this.historyBackEvent = () => {
      this.onClickCloseMapCompletePopup();
    };
  }
  // 퀘스트 완료 팝업 닫기
  onClickCloseQuizPopup(){
    const last_answer = this.model.contentData.QUEST.last_answer;
    // 튜토리얼이고 용돈계약 안되어있을때 공유하기
    if(last_answer.quiz_id === 'TUTORIAL' && !this.model.contentData.USER.checked_pocket_money) {
      this.shareKakaotalk();
      return;
    };
    const isOpenPlaceClearPopup = last_answer.is_last_quest_in_place;
    // const isOpenPlaceClearPopup = true;
    const isOpenStageUpPopup = last_answer.is_level_up_target;
    // 장소완료 팝업 열기
    if(isOpenPlaceClearPopup){
      this.placeClearPopupOpen();
      return;

    // 스테이지 업
    }else if(isOpenStageUpPopup){
      this.stageUpAnimationMotion();
      return;

    // 장소상세 팝업
    }else{
      this.placeDetailPopup();
    }
  }
  // 장소 완료 팝업 닫기
  onClickClosePlaceClearPopup(){
    const isClearMap = this.model.contentData.QUEST.last_answer.is_clear_map;
    const isOpenStageUpPopup = this.model.contentData.QUEST.last_answer.is_level_up_target;

    // 맵완료 팝업 열기
    if (isClearMap) {
      this.mapCompletePopupOpen();
      return;
    }

    if (isOpenStageUpPopup) {
      this.stageUpAnimationMotion();
      return;
    }

    this.popupModel.closeCallBack = () => {
      this.placeDetailFocusOut();
    };
    this.onClickClosePopup();
  }
  // 맵완료 팝업 닫기
  onClickCloseMapCompletePopup(){
    this.onClickClosePopup();
    this.isShowMapUI = true;
  }
  // 튜토리얼 완료팝업 닫기
  onClickCloseTutorialPopup(){
    // 장소상세 팝업
    this.placeDetailPopup();
  }
  // 카카오톡 공유하기
  shareKakaotalk(){
    const userAgent = Vue.prototype.$varUA;
    const templateId = 91944;
    // IOS
    if ( userAgent === 'IOS' ) {
      this.kakaoSDKshare(templateId);
    // 안드로이드
    }else{
      window.FirFinInterface.kakaoTutorialMsg(templateId);
    }
  }
  kakaoSDKshare(templateId){
    try {
      Kakao.init(Vue.prototype.$config.kakaoJSKey);
    } catch (e) {}

    var kakaoLinkData = {
      templateId : templateId,
      installTalk: true
    };
    Kakao.Share.sendCustom(kakaoLinkData);
  }

  // 테스트] 퀘스트 진행상태 삭제
  onClickQuestStatusDelete(){
    if(!this.model.contentData.PLACE_DETAIL.place.id){
      store.dispatch('commonToast/fetchToastStart', 
      {
        msg : '삭제할 퀘스트가 포함된<br>장소의 상세를 조회한 이 후 진행해주세요',
        type: 'error'
      });
      return;
    }else{
      const deleteQuest = () => {
        this.deleteQuestStatus();
      }
      store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `${this.model.contentData.PLACE_DETAIL.place.name}에서 마지막으로 완료한<br>퀘스트의 진행상태를<br>초기화 하시겠습니까?`,
        closeBtnText: '취소',
        compeleteBtnText: '초기화 하기',
        confirmCallBack: deleteQuest,
      });
    }
  }
  // 테스트] 스테이지업
  onClickStageUp(){
    this.model.contentData.USER.stage += 1;
    this.stageUpAnimationMotion();
  }
  // 테스트] 스테이지 초기화
  onClickLevelReset(){
    const currentCenterData = this.model.contentData.PLACE.total_list[0].placeList[0];
    const params = {
      place: currentCenterData,
      isSmooth: false,
      scale: null,
      duration: 0,
      topHeightPercent : 100,
    }
    this.setPosition(params);
    this.model.contentData.USER.stage = 0;
  }
  // 스테이지 팝업 닫기
  onClickCloseStageUpPopup(realIndex){
    const userStage = this.model.contentData.USER.stage;
    const nextLevelPlace = this.model.contentData.PLACE.total_list.find(item => item.stage === userStage).placeList[realIndex];
    // 2. zoomout - nextLevelArea first place
    const endZoomOut = () => {
      this.isAction = false;
      this.isShowMapUI = true;
    }
    const zoomOut = () => {
      this.onClickClosePopup();
      const zoomOutNextLevelArea = {
        place: nextLevelPlace,
        isSmooth: true,
        callBack: {
          isCallBack: true,
          delay: 120,
          event: endZoomOut,
        },
        scale: 75,
        duration: 200,
      }
      this.setPosition(zoomOutNextLevelArea);
    }
    // 1. zoomIn - nextLevelArea first place
    const zoomInNextLevelArea = {
      place: nextLevelPlace,
      isSmooth: false,
      callBack: {
        isCallBack: true,
        // delay: 1200,
        event: zoomOut,
      },
      scale: 100,
      // duration: 100,
    }
    this.setPosition(zoomInNextLevelArea);
  }

  getMoveToNextLevelAreaData() {
    const nextLevel = this.model.contentData.USER.stage;

    // stage x,y,w,h 제거. 제일 앞 place로 이동.
    // const nextLevelArea = this.model.contentData.PLACE.total_list.find(item => item.stage === nextLevel);

    const stageInfo = this.model.contentData.PLACE.total_list.find(
      item => item.stage === nextLevel,
    );

    // 없을 리 없지만 방어 코드.
    if (!stageInfo?.placeList) {
      this.isOpenControal = false;
      return;
    }

    const nextLevelArea = stageInfo.placeList[0];

    // 5. OpenNewPlacePopup
    // const openNexPlacePopup = () => {
    //   this.stageUpPopupOpen();
    //   this.isAction = false;
    //   this.isShowMapUI = true;
    // }
    // 3. action nextLevelArea
    const actionNewLevelArea = () => {
      this.isAction = true;
      // this.model.contentData.USER.stage = nextLevel;
      this.animationData.stage = nextLevel;
      const _this = this;
      this.timeoutID2 = setTimeout(function(){
        this.timeoutID2 = null;
        _this.stageUpPopupOpen();
        _this.isAction = false;
        _this.isShowMapUI = true;
      }, 1500);
    };
    // 2. move to nextLevelArea
    let maxWindow = this.getWindowHeight - 240; // 상하 여백 120
    let nextLevelMax = nextLevelArea.height;
    let nextLevelAreaScale = 50;
    if(nextLevelArea.width > nextLevelArea.height){
      maxWindow = this.getWindowWidth - 40; // 좌우 여백 20
      nextLevelMax = nextLevelArea.width;
    }
    nextLevelAreaScale = (maxWindow / nextLevelMax) * 100;
    if(nextLevelAreaScale > 75){
      nextLevelAreaScale = 75 // 최대 scale 75
    }
    if(nextLevelAreaScale < this.minScale){
      nextLevelAreaScale = this.minScale
    }
    const moveToNextLevelAreaData = {
      place: nextLevelArea,
      isSmooth: true,
      callBack: {
        isCallBack: true,
        delay: 120,
        event: actionNewLevelArea,
      },
      scale: nextLevelAreaScale,
      duration: 120,
    }

    return moveToNextLevelAreaData;
  }

  stageUpAnimationMotion(){
    // 0. 기존 열려있는 팝업닫기
    this.onClickClosePopup();

    this.isOpenControal = false;

    const moveToNextLevelAreaData = this.getMoveToNextLevelAreaData();

    if (!moveToNextLevelAreaData) {
      return;
    }

    this.setPosition(moveToNextLevelAreaData);
  }
  // 테스트] 용돈계약유무
  onClickTestChangePocketMoney(){
    this.model.contentData.USER.checked_pocket_money = !this.model.contentData.USER.checked_pocket_money;
  }
  // 테스트] 보상유무
  onClickTestChangeReward(){
    this.model.contentData.USER.checked_reward = !this.model.contentData.USER.checked_reward;
    const has_rewards = this.model.contentData.QUEST.last_answer.has_rewards;
    this.model.contentData.QUEST.last_answer.has_rewards = has_rewards != undefined ? !has_rewards : false;
  }


  // * -- Animation --- *
  // 지도 위치 포커스 세팅
  setPosition(obj){
    let callBack = {
      isCallBack: false,
      delay: null,
      event: undefined
    }
    if(obj.callBack){
      callBack = {
        isCallBack: obj.callBack.isCallBack,
        delay: obj.callBack.delay,
        event: obj.callBack.event,
      }
    }
    // if(this.isAction) return;
    const {top,left} = this.getCenterPosition(obj);
    this.actionCallBack = undefined;
    if(obj.isSmooth){
      this.isAction = true;
      let { scrollTop, scrollLeft } = this.map;
      const animationParams = {
        startX : scrollLeft,
        endX : left,
        startY : scrollTop,
        endY : top,
        scale : obj.scale,
        callBack : callBack,
        duration : obj.duration || 300
      }
      this.animateScrollTo(animationParams)
    }else{
      if(obj.scale){
        this.scale = obj.scale;
      }
      const _this = this;
      this.timeoutID2 = setTimeout(function(){
        _this.map.scrollLeft = left;
        _this.map.scrollTop = top;
        this.timeoutID2 = null;
        if(callBack.isCallBack){
          if(callBack.delay){
            this.timeoutID1 = setTimeout(function(){
              _this.isAction = false;
              if(callBack.isCallBack){
                callBack.event();
              }
              this.timeoutID1 = null;
            }, callBack.delay);
          }else{
            callBack.event();
            this.isAction = false;
          }
        }
      }, 10);
    }
  }
  // 지도 애니메이션
  animateScrollTo(obj) {
    const { startX, endX, startY, endY, duration, callBack } = obj;
    let diff = null;
    if(obj.scale){
      diff = obj.scale - this.scale;
    }
    // console.log('------- animateScrollTo - X:',startX,'->',endX,' / Y:',startY,endY,' / duration:',duration);
    const cutTime = 10;
    const cutCount = ( duration / cutTime );
    const totalTopDiff = endY - startY;
    const totalLeftDiff = endX - startX;
    const oneTimeTopPoint = (totalTopDiff) / cutCount;
    const oneTimeLeftPoint = (totalLeftDiff) / cutCount;
    let startScale = null;
    let onTiemScalePoint = null;
    if(obj.scale){
      startScale = this.scale;
      onTiemScalePoint = diff / cutCount;
      // console.log(this.changedScaleDiff);
      // console.log(onTiemScalePoint);
    }
    let moveCount = 0;
    let timerId = setInterval(() => {
      if(moveCount >= cutCount){
        clearInterval(timerId);
        // console.log(obj.scale);
        // console.log(this.actionPositionData);
        if(obj.scale){
          this.scale = startScale + diff;
        }
        if(callBack.delay){
          const _this = this;
          this.timeoutID1 = setTimeout(function(){
            _this.isAction = false;
            if(callBack.isCallBack){
              callBack.event();
            }
            this.timeoutID1 = null;
          }, callBack.delay);
        }else{
          if(callBack.isCallBack){
            callBack.event();
          }
          this.isAction = false;
        }
        // let { scrollTop, scrollLeft } = this.$refs.map;
        // console.log('cutCount',cutCount)
        // console.log('totalMoveCount',moveCount);
        // console.log('leftPoint',(endX - scrollLeft),(endY - scrollTop));
        // console.log('dontWorkCount',(endX - scrollLeft)/oneTimeLeftPoint,(endY - scrollTop)/oneTimeTopPoint );
        // console.log('animateScrollTo Result - X:',scrollLeft,' / Y:',scrollTop);
        return;
      };
      // console.log('setInterval',startX + (oneTimeTopPoint * (moveCount+1)) , startY + (oneTimeTopPoint * (moveCount+1)) );
      this.map.scrollTop = startY + (oneTimeTopPoint * (moveCount+1));
      this.map.scrollLeft = startX + (oneTimeLeftPoint * (moveCount+1));
      if(obj.scale){
        this.scale = startScale + (onTiemScalePoint * (moveCount+1));
      }
      moveCount += 1;
    }, cutTime);
  }
  // 지도 가운데 좌표 찾기
  getCenterPosition(obj){
    const params = {
      place : obj.place,
      isNowPosition : obj.isNowPosition || false,
      scale : obj.scale,
      topHeightPercent : obj.topHeightPercent || 100,
    }
    let focuseData = { x:null, y:null, width:null, height:null };
    let nowScalePercent = this.scale/100;
    let changedScalePercent = ( params.scale || this.scale )/100;
    let windowScalePercent = 100/(params.scale || this.scale);
    
    if(!params.isNowPosition){
      focuseData.x = params.place.x * nowScalePercent;
      focuseData.y = params.place.y * nowScalePercent;
      focuseData.w = params.place.width;
      focuseData.h = params.place.height;
    }else{
      // 현위치 중심
      focuseData.x = this.map.scrollLeft + ( this.getWindowWidth * 0.5 ) - ( 50 * nowScalePercent );
      focuseData.y = this.map.scrollTop + ( this.getWindowHeight * 0.5 ) - ( 50 * nowScalePercent );
      focuseData.w = 100;
      focuseData.h = 100;
    }
    if(params.scale && params.scale != this.scale){
      const scaleDiffPercent = params.scale / this.scale;
      focuseData.x = focuseData.x * scaleDiffPercent;
      focuseData.y = focuseData.y * scaleDiffPercent;
    }
    const scaleX = focuseData.x;
    const scaleY = focuseData.y;
    const scaleW = focuseData.w;
    const scaleH = focuseData.h;
    const viewHeight = this.getWindowHeight * (params.topHeightPercent/100);
    const viewWidth = this.getWindowWidth;
    let overflowHeight = 0;
    if(viewHeight < ( scaleH * changedScalePercent ) + 120){
      overflowHeight = ((scaleH * changedScalePercent) - viewHeight)/2 + 60;
    }
    let top = scaleY - (viewHeight/2) + (scaleH* changedScalePercent/2) - overflowHeight
    let left = scaleX - (viewWidth/2) + (scaleW* changedScalePercent/2)
    const maxTop = this.model.contentData.MAP.height * changedScalePercent - this.getWindowHeight;
    const maxLeft = this.model.contentData.MAP.width * changedScalePercent - this.getWindowWidth;
    if(top < 0 ){
      top = 0;
    }else if(top > maxTop){
      top = maxTop;
    }
    if(left < 0){
      left = 0;
    }else if(left > maxLeft){
      left = maxLeft;
    }
    this.testBorderData = { y:scaleY, x:scaleX, w:scaleW, h:scaleH};
    this.testSpotData = {y:top, x:left};
    // console.log('expect Result - X: 1774.01 / Y:1715.22')
    // console.log('getCenterPosition Result - X:',left,' / Y:',top,' / width:',scaleW,' / height:',scaleH )
    // return { top:top, left:left, width:scaleW, height:scaleH };
    return { top: top, left: left }; // width, height 쓰는 곳 없어보여서 제거.
  }
  // 테스트] 줌 인&아웃
  onClickChangeScale(scaleData){
    const largetSelectedScaleArray = this.scaleDataList.filter(item => item.text >= this.scale)
    const selectedScaleIndex = largetSelectedScaleArray[0].id;
    const params = {
      isNowPosition: true,
      isSmooth: true,
      scale: this.scaleDataList[selectedScaleIndex + scaleData -1].text,
      duration: 120,
      topHeightPercent : 100,
    }
    this.setPosition(params)
  }


  // * -- API --- *
  // API] 장소 리스트
  // getPlaceListArea(callBack){
  //   if(this.firstLoadCheckList.isLoadFailed) return;
  //   const path = `${apiPath.PLACE_LIST_AREA}`;
  //   GET_REQUEST(path).then(
  //   (success) => {
  //     // const resultData = success.data.body;
  //     const resultData = addTempImageUrls(success.data.body);

  //     console.log('@#@@@@@', resultData);

  //     this.model.setPlaceList(resultData);
  //     if(callBack){
  //       callBack(resultData);
  //     }
  //   }, (failed) => {
  //     this.firstLoadCheckList.isLoadFailed = true;
  //     // store.dispatch('commonToast/fetchToastStart', failed.msg);
  //   })
  // }

  // API] 유저 정보
  getUserStatus(){
    if (this.firstLoadCheckList.isLoadFailed) return;

    // const path = apiPath.USER_STATUS;
    const path = `${apiPath.USER_STATUS.replace('%s', this.mapUid)}`;

    return GET_REQUEST(path).then(
      success => {
        const resultData = success.data.body;
        this.model.setUserStatus(resultData);
        return resultData;
      },
      failed => {
        this.firstLoadCheckList.isLoadFailed = true;
        // store.dispatch('commonToast/fetchToastStart', failed.msg);
        return null;
      },
    );

    // GET_REQUEST(path).then(
    // (success) => {
    //   const resultData = success.data.body;
    //   this.model.setUserStatus(resultData);
    //   if(callBack){
    //     callBack(resultData);
    //   }
    // }, (failed) => {
    //   this.firstLoadCheckList.isLoadFailed = true;
    //   // store.dispatch('commonToast/fetchToastStart', failed.msg);
    // })
  }
  // API] 장소 검색 리스트
  getPlaceSearchList(callBack){
    if (this.firstLoadCheckList.isLoadFailed) return;

    // const path = apiPath.PLACE_SEARCH_LIST;
    const path = `${apiPath.PLACE_SEARCH_LIST.replace('%s', this.mapUid)}`;

    GET_REQUEST(path).then(
      success => {
        const resultData = success.data.body;
        // this.model.setPlaceSearchList(resultData);

        // 스테이지 순서대로 정렬.
        // resultData.search_place.sort((a, b) => a.stage - b.stage);

        // 썸네일 이미지를 여기서 넣어줌.
        resultData.search_place.forEach(item => {
          item.place_status_list.forEach(place => {
            place.thumbnail = {
              imageUrl: this.getThumbnailImageUrl(place.id),
            }
          });
        });

        this.model.setPlaceSearchList(resultData.search_place);
        if (callBack) {
          callBack(resultData);
        }
      },
      failed => {
        // this.firstLoadCheckList.isLoadFailed = true;
        // store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // API] 장소 키워드 검색
  getPlaceSearchKeyword(keyWord){
    if(this.firstLoadCheckList.isLoadFailed) return;
    const data = {
      keyword: keyWord
    }
    const params = makeQueryStringByObject(data);

    // const path = `${apiPath.PLACE_SEARCH_KEYWORD}${params}`;
    const path = `${apiPath.PLACE_SEARCH_KEYWORD.replace('%s', this.mapUid)}${params}`;

    // if(data.keyword === ''){
    if (!data.keyword) {
      this.model.resetPlaceSearchKeyword();
      return;
    }

    GET_REQUEST(path).then(
      success => {
        const resultData = success.data.body;

        // 스테이지 순서대로 정렬.
        // resultData.search_place.sort((a, b) => a.stage - b.stage);

        // 썸네일 이미지를 여기서 넣어줌.
        resultData.search_place.forEach(item => {
          item.place_status_list.forEach(place => {
            place.thumbnail = {
              imageUrl: this.getThumbnailImageUrl(place.id),
            }
          });
        });

        this.model.setPlaceSearchKeywordList(keyWord, resultData.search_place);
      },
      failed => {
        // this.firstLoadCheckList.isLoadFailed = true;
        // store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // API] 장소 상세 정보
  getPlaceDetail(placeId,callBack){
    if(this.firstLoadCheckList.isLoadFailed) return;
    const path = `${apiPath.PLACE_DETAIL.format(placeId)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.model.setPlaceDetail(resultData);
      if(callBack){
        callBack(resultData);
      }
    }, (failed) => {
      // this.firstLoadCheckList.isLoadFailed = true;
      // store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // API] 유저의 장소 상세 정보
  getUserPlaceStatus(placeData,callBack){
    if(this.firstLoadCheckList.isLoadFailed) return;
    const path = `${apiPath.USER_PLACE_STATUS.format(placeData.id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setUserPlaceStatus(resultData);
      if(callBack){
        callBack(placeData,resultData);
      }
    }, (failed) => {
      // this.firstLoadCheckList.isLoadFailed = true;
      // store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // API] 튜토리얼 완료 저장
  postTutorialDone(){
    const path = `${apiPath.TUTORIAL_DONE}`;
    POST_REQUEST(path).then(
    (success) => {
      this.tutorialPopup();
    }, (failed) => {
      // 튜토리얼 완료 저장 실패시 월드맵으로 진입
      this.model.contentData.USER.init_type = 'WORLD_MAP';
      this.initWorldMap(resultData);
    })
  }
  // API] 퀴즈 상태 저장
  postQuestStatus() {
    // 중복 클릭 호출 방지.
    if (this.isPreparingQuest) return;

    this.isPreparingQuest = true;

    const path = `${apiPath.QUEST_STATUS_SAVE.format(this.model.contentData.QUEST.id)}`;

    POST_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.setFamilyWalletCheck(resultData)
    }, (failed) => {
      this.isPreparingQuest = false;
    })
  }
  // API] 퀴즈 리스트
  getQuizList(){
    const path = `${apiPath.QUIZ_LIST.format(this.model.contentData.QUEST.id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.model.setQuiz(resultData);
      this.quizPopup();
    }, (failed) => {
    }).finally(() => {
      this.isPreparingQuest = false;
    })
  }
  // API] 장소 클리어 데이터
  getPlaceClear(){
    const path = `${apiPath.PLACE_CLEAR.format(this.model.contentData.PLACE_DETAIL.place.id)}`;
    GET_REQUEST(path).then(
      (success) => {
        const resultData = success.data.body;
        this.model.setPlaceClear(resultData);
        this.questClearPopupOpen();
      }, (failed) => {
        // store.dispatch('commonToast/fetchToastStart', failed.msg);
      })    
  }
  // 테스트 API] 퀘스트 진행상태 삭제
  deleteQuestStatus(){
    const path = `${apiPath.QUEST_STATUS_DELET.format(this.model.contentData.PLACE_DETAIL.place.id)}`;
    DELETE_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.getUserStatus();
    }, (failed) => {
      // store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}
