import { mapGetters } from 'vuex';

const BottomSheetMixin = {
  data(){
    return{
      // isBottomSheet:false,//기능 적용 유무
      isMobile:true, // 모바일인지 유무
      mobileBottomSheet: false, // 드레그 기능 유무
      initPoint: null, // 최초 드레그 진입 지점
      openPoint: 0, // 열리는 top값
      closePoint: null, // 닫히는 top값
      openFromFill: 60, // 풀로 열리는 top값
      openFromUnfill: null, // 열리는 top값
      openFromUnfillPc: 136, // 열리는 top값 pc
      openFromUnfillMo: 54, // 열리는 top값 mo
      closeFromBottomPoint: -71, // 닫혔을때 보이는 값
      listHidePoint:0.1, // 드레그 구분 포인트
      closePopupPoint: null, // 팝업 닫히는 포인트
    }
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowWidth', 'getWindowHeight' ]),
    ...mapGetters('commonViewport', [ 'getSafeAreaTopHeight', 'getSafeAreaBottomHeight' ]),
    isOpenResultSync:{
      get(){
        return this.isOpenResult;
      },
      set(val){
        // this.$emit('update:isOpenResult',val)
        this.isOpenResult = val
      },
    },
  },
  // unmounted(){
  //   if(this.isBottomSheet){
  //     document.body.style.overflow = "";
  //   }
  // },
  watch:{
    getWindowWidth(){
      if(this.type != 'drag_bottom_sheet') return;
      this.setMobile();
      this.setOpenResultTop();
    },
    getWindowHeight(){
      if(this.type != 'drag_bottom_sheet') return;
      this.setClosePoint();
      this.setOpenResultTop();
      this.setOpenPoint();
    },
    isOpenResult(){
      if(this.type != 'drag_bottom_sheet') return;
      this.setOpenResultTop();
    },
    mobileBottomSheet(){
      if(this.type != 'drag_bottom_sheet') return;
      this.setOpenPoint();
    }
  },
  methods:{
    bottomSheetMixinInit(){
      this.mobileBottomSheet = true;
      // document.body.style.overflow = "hidden";
      this.closeFromBottomPoint = ( this.getWindowHeight / 2 ) * -1;
      this.setMobile();
      this.setClosePoint();
      this.setOpenResultTop();
      this.setOpenPoint();
    },
    setMobile(){
      this.isMobile = true;
      this.openFromUnfill = this.openFromUnfillMo + this.getSafeAreaTopHeight + 3;
      // if(this.getWindowWidth <= 600){
      //   this.isMobile = true;
      //   this.openFromUnfill = this.openFromUnfillMo;
      // }else{
      //   this.isMobile = false;
      //   this.openFromUnfill = this.openFromUnfillPc;
      // }
    },
    setOpenPoint(){
      this.openPoint = this.mobileBottomSheet ? this.openFromUnfill : this.openFromFill;
      this.setOpenResultTop();
    },
    setClosePoint(){
      this.closePoint = this.getWindowHeight + this.closeFromBottomPoint;
      this.closePopupPoint = this.getWindowHeight * (1- this.listHidePoint);
    },
    setOpenResultTop(){
      // if(this.isMobile){
        // this.$refs.wrapLayer.style.display = 'block';
        if(this.isOpenResult){
          this.$refs.wrapLayer.style.top = `${ this.openPoint }px`;
        }else{
          this.$refs.wrapLayer.style.top = `${ this.closePoint }px`;
        }
      // }else{
      //   this.$refs.wrapLayer.style.top = `${ this.openPoint }px`;
      //   if(this.isOpenResult){
      //     this.$refs.wrapLayer.style.display = 'block';
      //   }else{
      //     this.$refs.wrapLayer.style.display = 'none';
      //   }
      // }
    },
    onListMove(e){
      if(this.initPoint != null && this.isMobile && this.mobileBottomSheet){
        // console.log('onListMove',e)
        const yPoint =  e.clientY || e.changedTouches[0].clientY;
        const dragPoint = yPoint - this.initPoint;

        // open -> close
        // const closeingTopMaxPoint = this.openPoint + dragPoint < this.closePoint;
        // close -> open
        // const openingTopMinPoint = this.closePoint + dragPoint > this.openPoint;
        // 결과값 - 절대적 top 값
        const resultTop = this.isOpenResultSync ? this.openPoint + dragPoint : this.closePoint + dragPoint;

        // 아래로 listHidePoint 이상 내리면 close
        if( resultTop >= this.closePopupPoint ){
          this.$emit('onClickClose');
          return;
        }

        // close -> open
        // 열리는 포인트까지 드레그시 moving 종료
        if(!this.isOpenResultSync && resultTop <= this.openPoint){
          this.onListEnd(e);
          return;
        }

        this.$refs.wrapLayer.style.top = `${ resultTop }px`;
        
        // console.log('*******',dragPoint)

        // // open -> close
        // if(this.isOpenResultSync){
        //   // console.log('open -> close');
        //   // 아래로 드레그
        //   if(dragPoint > 0){
        //       this.$refs.wrapLayer.style.top = `${ resultTop }px`;
        //     // 닫히는 포인트까지 드레그시 moving 종료
        //     // if(closeingTopMaxPoint){
        //     //   // console.log('closeingTopMaxPoint',this.openPoint + dragPoint , this.closePoint);
        //     //   this.$refs.wrapLayer.style.top = `${ this.openPoint + dragPoint }px`;
        //     // }else{
        //     //   this.onListEnd(e);
        //     // }
        //   }
        // // close -> open
        // }else{
        //   // console.log('close -> open');
        //   // console.log('openingTopMinPoint',this.closePoint + dragPoint, this.openPoint);
        //   this.$refs.wrapLayer.style.top = `${ resultTop }px`;
        //   // 위로 드레그
        //   if(dragPoint < 0){
        //     // 열리는 포인트까지 드레그시 moving 종료
        //     if(!openingTopMinPoint){
        //       this.onListEnd(e);
        //     }

        //   // 아래로 드레그
        //   }else{
        //     // 아래로 listHidePoint 이상 내리면 close
        //     // if(dragPoint > this.closePoint - this.getWindowHeight * this.listHidePoint){
        //     //   this.$emit('onClickClose')
        //     // }
        //   }
        // }
      }
    },
    onListStart(e){
      if(this.isMobile && this.mobileBottomSheet){
        // console.log('onListStart',e)
        this.initPoint = e.clientY || e.changedTouches[0].clientY;
      }
    },
    onListEnd(e){
      if(this.initPoint != null && this.isMobile && this.mobileBottomSheet){
        // console.log('onListEnd',e)
        const yPoint =  e.clientY || e.changedTouches[0].clientY;
        const dragPoint = yPoint - this.initPoint;
        if(dragPoint > ( this.getWindowHeight * this.listHidePoint )){
          this.isOpenResultSync = false;
        }else{
          this.isOpenResultSync = true;
        }
        this.initPoint = null;
        this.setOpenResultTop();
      }
    },
  }
}
export default BottomSheetMixin;