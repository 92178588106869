<template>
  <div class="container">
    <!-- 장소목록 -->
    <template v-if="placeSearchData.searched_list.length > 0">
      <!-- stage 별 묶음 -->
      <div
        v-for="(levelPlace, index) in placeSearchData.searched_list"
        :key="`levelPlace${levelPlace.stage}`"
        :class="['group_level', { active: isActiveStage(levelPlace) }]"
      >
        <div class="stage_box">
          <strong>{{
            $poData.place_list_lv_divider.format(levelPlace.stage)
          }}</strong>
          <p
            v-if="
              placeSearchData.search_keyword === '' && remainQuest(index).isShow
            "
            class="description"
          >
            {{
              $poData.place_list_lv_guide.format(
                remainQuest(index).stage,
                remainQuest(index).remain_quest_count,
              )
            }}
          </p>
        </div>
        <!-- stage 내 장소목록 -->
        <ul class="list_place">
          <!-- 장소항목 -->
          <li
            v-for="place in levelPlace.place_status_list"
            :key="`Place${place.id}`"
            @click="
              isActiveStage(levelPlace)
                ? $emit('onClickPlaceSearchItem', place.id, place.stage)
                : ''
            "
          >
            <div class="img_container">
              <img
                class="thumbnail"
                :src="`${getPlaceImg(levelPlace,place)}`"
                alt="썸네일"
              />
              <!-- :src="`/assets/images/map/place/focus/${place.eng_name.replaceAll(' ','',)}.png`" -->
            </div>
            <strong class="tit_place">{{ place.name }}</strong>
            <!-- 진행상태 -->
            <div v-if="isActiveStage(levelPlace)" class="area_right">
              <span
                :class="[
                  'txt_status',
                  { txt_status_clear: isPlaceClear(place) },
                  { txt_status_started: isPlaceStarted(place) },
                ]"
                >{{ place.cleared_quest_count | emptyValue('', '0') }}/{{
                  place.all_quest_count | emptyValue('', '0')
                }}</span
              >
            </div>
            <div v-else class="img_container" >
              <img src="/assets/icons/lock.svg" alt="잠긴 열쇠" />
            </div>
          </li>
        </ul>
      </div>
    </template>
    <!-- 검색결과 없을시 -->
    <template v-else>
      <div
        class="area_empty"
        :style="{ paddingTop: getWindowHeight * 0.5 - 158 + 'px' }"
      >
        <p class="desc_empty">{{ $poData.place_list_no_search }}</p>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PlaceList',
  props: {
    placeSearchData: Object,
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('commonViewport', ['getWindowHeight']),
  },
  methods: {
    isActiveStage(levelPlace) {
      if (this.isLocked) return false;

      return levelPlace.stage <= levelPlace.user_stage;
    },
    remainQuest(index) {
      const beforeStageData = this.placeSearchData.total_list[index - 1];
      let result = {
        isShow: false,
        stage: null,
        remain_quest_count: null,
      };
      if (index === 0) {
        return result;
      }
      if (
        index > 0 &&
        beforeStageData.remain_quest_count > 0 &&
        beforeStageData.stage === beforeStageData.user_stage
      ) {
        result.isShow = true;
        result.stage = beforeStageData.stage;
        result.remain_quest_count = beforeStageData.remain_quest_count;
        return result;
      } else {
        return result;
      }
    },
    isPlaceClear(place) {
      return place.cleared_quest_count === place.all_quest_count;
    },
    isPlaceStarted(place) {
      if (place.cleared_quest_count === null) {
        return false;
      }
      return place.cleared_quest_count > 0;
    },
    getPlaceImg(levelPlace,place){
      const activeImgUrl = '/assets/images/step/place_search_active.png';
      const clearImgUrl = '/assets/images/step/place_search_clear.png';
      const disabledImgUrl = '/assets/images/step/place_search_disabled.png';
      if(this.isActiveStage(levelPlace)){
        if(this.isPlaceClear(place)){
          return clearImgUrl;
        }else{
          return activeImgUrl;
        }
      }else{
          return disabledImgUrl;
      }
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.group_level {
  /* padding: 24px 0; */

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.list_place {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.list_place li {
  padding: 16px;

  border-radius: 8px;
  border: 1px solid var(--neutrals-gray-50, #f3f1f1);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.list_place li .tit_place {
  color: var(--font-font-black, #111);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  word-break: keep-all;
  flex: 1;
}
.list_place li .txt_status {
  display: inline-block;
  margin: 12px 0;
  padding: 4px 8px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  background-color: #fbae8a;
  color: #999;
  vertical-align: top;
}

.list_place li .txt_status_clear {
  background-color: #d8cbf4;
}

/* .group_level.active .tit_level em {
  color: #bea9ed;
}
.group_level.active .list_place li .img_place {
  opacity: 1;
  filter: none;
}
.group_level.active .list_place li .tit_place {
  color: #111;
} */
.group_level.active .list_place li .txt_status {
  color: #111;
}

.area_empty .desc_empty {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #999;
  text-align: center;
}

.stage_box {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--neutrals-gray-10, #F2F1FA);

  box-sizing: border-box;
}

.stage_box strong {
  color: var(--font-font-black, #111);
  text-align: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.stage_box .description {
  color: var(--font-font-100, #999);
  text-align: center;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.img_container {
  line-height: 0;
}

.thumbnail {
  width: 46px;
  height: 46px;

  filter: saturate(0);
}

.group_level.active .thumbnail {
  filter: none;
}
</style>
