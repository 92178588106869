<template>
  <PopupLayout
    :isTemplate="false">
    <!-- 리워드 정보 -->
    <div v-if="isRewardInfo" class="box_reward_info">
      <div class="inner_box_reward_info">
        <img
          class="img_sponsor_logo"
          :src="rewardData.image"
          :alt="rewardData.from_name || '스폰서 로고'"
        />
        <div class="cont_box">
          <template v-if="!rewardData.is_reward_end">
            <p v-if="!rewardData.is_show_remain_amount" class="desc_money">
              퍼핀이 포인트 <span class="txt_point">리워드를</span> 드려요!
            </p>
            <p v-else class="desc_money">
              남은 리워드
              <span class="txt_point">{{ rewardData.reward_description_combine }}</span>
            </p>
            <div class="lottie_coin">
              <Lottie
                :options="coinLottieOption"
                @animCreated="handleAnimation" />
            </div>
          </template>
          <p v-else class="desc_money">{{ rewardData.reward_end_desc }}</p>
        </div>
      </div>
    </div>
    <!-- 장소명 -->
    <div class="head_layer">
      <strong class="tit_layer">{{ placeName }}</strong>
    </div>
    <div :class="['body_layer',{ cleared : placeDetail.check_cleared_place, character_bottom: isCharacterInfo }]">
      <!-- 코스정보 -->
      <div
        v-if="courseData.length > 0"
        class="list_course">
        <div
          v-for="course in courseData"
          :key="`course${course.id}`"
          class="item_course">
          <div class="head_course">
            <!-- 진행 프로그레스 -->
            <LineProgress
              :value="course.cleared_quest_count"
              :total="course.total_quest_count"/>
            <!-- 코스명 -->
            <div class="area_overflow">
              <span class="txt_course">{{ $poData.place_details_course_number.format(course.idx) }}</span>
              <strong class="tit_course">{{ course.name }}</strong>
            </div>
          </div>
          <!-- 코스 설명 -->
          <div class="body_course">
            <p class="desc_course" v-html="convertLineFeed(course.description )"></p>
          </div>
        </div>
      </div>
      <!-- 코스정보 없을시 -->
      <p v-else v-html="$poData.general_error_toast"></p>
      <!-- 퀘스트 버튼 -->
      <Button
        v-if="!placeDetail.check_cleared_place && quizBtnText"
        class="btn_quiz"
        btnSize="medium"
        btnStyle="primary"
        :disabled="userData.checked_today_quest"
        text=""
        @onClickBtn="$emit('onClickQuiz')">
        <span class="txt_quiz" v-html="quizBtnText"></span>
        <template v-slot:btn_right v-if="!userData.checked_today_quest">
          <IconSvg iconName="arrow_right" :size="24" :icoSize="16" iconColor="#232323"/>
        </template>
      </Button>
      <!-- 캐릭터 안내문구 -->
      <div v-if="isCharacterInfo" class="area_info">
        <div class="area_right">
          <!-- <img class="img_character" :src="`/assets/images/character/${infoText.img}.png`" alt=""> -->

          <Lottie
            :class="['lottie_info_img', infoText.imgClass]"
            :width="100"
            :height="100"
            :options="infoImgLottieOption"
            @animCreated="handleAnimation" />
        </div>
        <div class="area_overflow">
          <div class="inner_info">
            <p class="desc_info">{{infoText.frontText}} <em>{{infoText.pointText}}</em></p>
          </div>
        </div>
      </div>
    </div>
  </PopupLayout>
</template>

<script>
import PopupLayout from '@/components/layout/popup/PopupLayout'
import Button from '@/components/common/button/Button';
import IconSvg from '@/components/common/icon/IconSvg';
import LineProgress from '@/components/common/progress/LineProgress'
import Lottie from 'vue-lottie';
import coinLottie from '@/assets/json/coin_3d.json';
import send from '@/assets/json/send.json';
import alarm3 from '@/assets/json/alarm3.json';
import { convertDateFormat, calcDate, timeDiff } from '@/utils/dateUtils';
import { addComma } from '@/utils/stringUtils';

import { convertLineFeed } from '@/utils/stringUtils'
export default {
  name: 'PlaceDetailPopup',
  components:{
    PopupLayout,
    Button,
    IconSvg,
    LineProgress,
    Lottie
  },
  props:{
    rewardData: Object,
    userData: Object,
    courseData: Array,
    questData: Object,
    placeDetail: Object,
    mapType: String,
    mapUid: String,
    isCharacterInfo: {
      type: Boolean,
      default: false,
    }, // TODO. 개선 필요..
  },
  data(){
    return{
      coinLottieOption: {
        animationData: coinLottie,
        loop: true,
      },
      infoImgLottieOption: {
        animationData: send,
      },
      timerId: undefined,
      leftTime: '',
    }
  },
  beforeMount(){
    // 일일퀘스트 완료시 다음 퀘스트까지 남은시간 계산
    if (this.userData.checked_today_quest) {
      this.getNextDayTime();
    }
    this.setInfoImgLottie();
  },
  computed:{
    isRewardInfo(){
      return this.mapType != 'FREE' && !this.placeDetail.check_cleared_place;
    },
    placeName(){
      // 퍼핀월드만 name / 나머지는 eng_name
      return this.mapUid === 'm9feozFXMmEqThJO7goqc' ? this.placeDetail.place.name : this.placeDetail.place.eng_name
    },
    // 캐릭터 안내문구
    infoText(){
      let result = {
        img: '',
        frontText: '',
        pointText: '',
      }
      // 장소 클리어시
      if(this.placeDetail.check_cleared_place){
        result.imgClass = 'img_place_detail_course_done';
        result.img = 'img_place_detail_course_done';
        result.frontText = this.$poData.place_details_great;
      // 일일퀘스트 완료시
      }else if(this.userData.checked_today_quest){
        result.imgClass = 'img_place_detail_quest_done';
        result.img = 'img_place_detail_quest_done';
        // 보상있음
        if(this.placeDetail.today_quest_total_point > 0){
          result.frontText = this.$poData.place_details_reward_claimed_1;
          result.pointText = `${addComma(this.placeDetail.today_quest_total_point)}원`;
        // 보상없음
        }else{
          result.frontText = this.$poData.place_details_quest_done_1;
          result.pointText = this.$poData.place_details_quest_done_2;
        }
      // 일일퀘스트 미완료시
      }else{
        result.imgClass = 'img_place_detail_quest';
        result.img = 'img_place_detail_quest';
        // 보상있음
        if(this.placeDetail.total_today_gain_point > 0){
          result.frontText = this.$poData.place_details_reward_available_1;
          result.pointText = `${addComma(this.placeDetail.total_today_gain_point)}원`;
        // 보상없음
        }else{
          result.frontText = this.$poData.place_details_quest_ready_1;
          result.pointText = this.$poData.place_details_quest_ready_2;
        }
      }
      return result;
    },
    quizBtnText(){
      const quizName = this.questData ? this.questData.name : '진행 가능한 퀘스트가 없습니다.';
      if(!this.userData.checked_today_quest){
        return quizName;
      }else{
        return this.$poData.place_details_closed_btn.format(this.leftTime)
      }
    },
  },
  beforeDestroy(){
    clearInterval(this.timerId);
  },
  methods:{
    convertLineFeed(val){
      return convertLineFeed(val)
    },
    // onClickUnPocketMoney(){
    //   this.$store.dispatch('commonToast/fetchToastStart',
    //   {
    //     msg : this.$poData.need_contract_error_toast,
    //     type: 'error'
    //   });
    // },
    setInfoImgLottie(){
      // 장소 클리어시
      if(this.placeDetail.check_cleared_place){
        this.infoImgLottieOption.animationData = send;
      // 일일퀘스트 완료시
      }else if(this.userData.checked_today_quest){
        this.infoImgLottieOption.animationData = send;
      // 일일퀘스트 미완료시
      }else{
        this.infoImgLottieOption.animationData = alarm3;
      }
    },
    getNextDayTime(){
      if(this.userData.checked_today_quest){
        const nowDate = this.placeDetail.response_at || new Date();
        const now = nowDate;
        const next = convertDateFormat(nowDate,'YYYY-MM-DD')+' 24:00:00';
        const timeDiffResult = timeDiff(next,now);
        if(timeDiffResult === null || timeDiffResult === '00:00:00'){
          this.leftTime = '';
          return;
        }else{
          this.leftTime = timeDiffResult;
        }
        this.timerId = setInterval(() => {
          const left = convertDateFormat(nowDate,'YYYY-MM-DD')+' '+this.leftTime;
          this.leftTime = calcDate(left,-1,'seconds','HH:mm:ss');
          if(this.leftTime === '00:00:00'){
            this.getPlaceDetail();
            clearInterval(this.timerId);
          }
        }, 1000);
      }
    },
    getPlaceDetail(){
      this.leftTime = '';
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
  },
}
</script>

<style scoped>
/* .layer_bottom_sheet{position:fixed;top:0;bottom:0;left:0;right:0;z-index:200} */
/* 리워드 정보 */
.box_reward_info {
  position:absolute;
  bottom: 100%;
  left: 20px;
  right:20px;
  padding-bottom: 12px;
}
.box_reward_info .inner_box_reward_info {
  display:inline-block;
  overflow:hidden;
  border-radius: 100px;
  background: #232323;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.24);
  padding: 8px 12px 8px 8px;
  vertical-align:top;
}
.box_reward_info .img_sponsor_logo {
  float:left;
  width: 30px;
  height: 30px;
  margin-top:3px;
  vertical-align:top;
  border-radius: 100%;
}
.box_reward_info .cont_box {
  display: flex;
  padding:4px 0 4px 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  color: #fff;
}
.box_reward_info .cont_box .desc_money{flex:1 0;padding:5px 0;line-height:18px;letter-spacing:-0.02em}
.box_reward_info .cont_box .desc_money >>> .txt_point {
  display:inline-block;
  vertical-align:top;
}
.box_reward_info .cont_box .desc_money >>> .txt_point {
  margin-left:4px;
  color: #BEA9ED;
}
.box_reward_info .cont_box .lottie_coin {
  
  width: 20px;
  margin:1px 0 1px 8px;
  vertical-align:middle;
}

.tit_layer{display:block;font-weight:800;font-size:24px;line-height:30px}
.body_layer{margin-top:24px;padding-bottom:32px}
.body_layer.character_bottom{padding-bottom:24px}

/* 완료 장소 */
.openDrag .body_layer.cleared{padding-bottom:24px}

/* 코스항목 */
.item_course + .item_course{margin-top:24px}
.item_course .head_course{overflow:hidden}
.item_course .head_course .progress_line + .area_overflow{margin-top:8px}
.item_course .head_course .txt_course{float:left;margin-right:8px;padding:4px 8px;font-weight:600;border-radius:100px;font-size:12px;line-height:16px;background-color:#F0EAFA;color:#8F69DD}
.item_course .head_course .tit_course{display:block;overflow:hidden;font-weight:700;font-size:16px;line-height:24px;white-space:nowrap;text-overflow:ellipsis}
.item_course .body_course{margin-top:8px;}
.item_course .body_course .desc_course{font-weight:500;font-size:14px;line-height:22px;color:#666;word-wrap:break-word;word-break:keep-all}

/* 캐릭터 안내문구 */
.area_info{overflow:hidden;height:100px;margin-top:24px}
/* .area_info .img_character{display:block;width:100px;height:100px} */
.area_info .lottie_info_img.img_place_detail_quest{transform:scaleX(-1)}
.area_info .area_overflow{height:100px;font-size:0;white-space:nowrap;text-align:right}
.area_info .area_overflow:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.area_info .inner_info{display:inline-block;vertical-align:middle;white-space:normal}
.area_info .desc_info{display:inline-block;margin:0 12px 0 0;padding:8px 12px;border-radius:100px;font-weight:500;font-size:14px;line-height:22px;background-color:#F1EEE1;vertical-align:top;word-wrap:break-word;word-break:keep-all}
.area_info .desc_info em{display:inline-block;font-weight:500;color:#8F69DD;vertical-align:top}

.btn_quiz{position:relative;width:100%;height:auto;min-height:56px;padding:10px 48px;margin-top:24px;}
.btn_quiz .txt_quiz{display:block;overflow:hidden;font-size:18px;line-height:28px;white-space:nowrap;text-overflow:ellipsis}
.btn_quiz .icon_arrow_right{position:absolute;top:16px;right:16px}

.alert_error{position:absolute;top:20px;left:20px;right:20px;padding:18px 72px 18px 20px;border-radius:16px;background-color:#F04848;box-shadow:0px 6px 10px rgba(0, 202, 44, 0.12)}
.alert_error .desc_alert{font-weight:800;font-size:14px;line-height:24px}
</style>