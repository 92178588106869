var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"map",class:[
    'area_map',
    { 
      action: _vm.viewModel.isAction, 
      selected: _vm.contentData.PLACE_DETAIL.id, 
      isPopup: _vm.viewModel.popupModel.name,
      scroll_bottom_sheet: _vm.viewModel.popupModel.name === 'PlaceDetailPopup'
    },
  ],style:(_vm.mapCssProps),attrs:{"id":"map"},on:{"!click":function($event){return _vm.handleClickMap.apply(null, arguments)}}},[_c('div',{staticClass:"inner_map"},[_vm._l((_vm.contentData.PLACE.total_list),function(levelPlace){return _c('div',{key:`levelPlace${levelPlace.stage}`,class:[
        'group_level',
        `level${levelPlace.stage}`,
        {
          active:
            _vm.isLocked ||
            (_vm.contentData.USER.stage != null &&
              _vm.contentData.USER.stage >= levelPlace.stage),
          // active:
          //   contentData.USER.stage != null &&
          //   contentData.USER.stage >= levelPlace.stage,
          action:
            _vm.viewModel.animationData.stage != null &&
            _vm.viewModel.animationData.stage === levelPlace.stage,
          even: levelPlace.stage % 2 === 0,
          odd: levelPlace.stage % 2 != 0,
        },
      ]},_vm._l((levelPlace.placeList),function(place){return _c('div',{key:`Place${place.id}`,ref:`place_${place.id}`,refInFor:true,class:[
          'item_place',
          { selected: _vm.contentData.PLACE_DETAIL.place.id === place.id && !_vm.viewModel.isShowMapUI },
          { user_on: _vm.contentData.PLACE.random_place && _vm.contentData.PLACE.random_place.id === place.id },
          { clear : _vm.viewModel.checkPlaceClear(place.id) }
        ],attrs:{"id":place.id},on:{"click":function($event){return _vm.viewModel.onClickPlace(place, levelPlace.stage)}}},[_c('div',{staticClass:"img_place img_today"}),_c('div',{staticClass:"img_place img_active"}),_c('div',{staticClass:"img_place img_disabled"}),(_vm.contentData.PLACE.random_place && _vm.contentData.PLACE.random_place.id === place.id)?_c('div',{staticClass:"profile_comm"},[_c('p',{staticClass:"desc_today"},[_vm._v("오늘의 퀴즈")]),_c('IconSvg',{attrs:{"iconName":"profile_arrow_bottom","icoSize":14,"iconColor":"#232323"}})],1):_vm._e()])}),0)}),(_vm.isTestMode)?[_c('div',{staticClass:"text_border",style:([
          {
            top: `${_vm.viewModel.testBorderData.y * (100 / _vm.viewModel.scale) -
              4}px`,
            left: `${_vm.viewModel.testBorderData.x * (100 / _vm.viewModel.scale) -
              4}px`,
            width: `${_vm.viewModel.testBorderData.w}px`,
            height: `${_vm.viewModel.testBorderData.h}px`,
            fontSize: `${1500 / _vm.viewModel.scale}px`,
          },
        ])}),_c('div',{staticClass:"test_spot test_spot_x",style:([
          {
            left: `${_vm.viewModel.testSpotData.x * (100 / _vm.viewModel.scale)}px`,
            fontSize: `${1500 / _vm.viewModel.scale}px`,
          },
        ])},[_vm._v(" x ")]),_c('div',{staticClass:"test_spot test_spot_y",style:([
          {
            top: `${_vm.viewModel.testSpotData.y * (100 / _vm.viewModel.scale)}px`,
            fontSize: `${1500 / _vm.viewModel.scale}px`,
          },
        ])},[_vm._v(" y ")]),_c('div',{staticClass:"test_ruler_x"}),_c('div',{staticClass:"test_ruler_y"})]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }