
// import poData from '@/constants/poData.js';

export default class QuizPageModel {
  constructor(){
    this.quizPageMode = ''; // 페이지 모드  'Lesson' , 'Intro', 'Question', 'Result', 'Explanation'
    this.quizPageBg = 'SECONDARY'; // PRIMARY / SECONDARY / WHITE
    // this.quizPageTitle = ''; // 페이지 타이틀
    this.paginationData = { total: 5, page: 0, progressPage: 0 }; // 페이지 타이틀
    // this.isContMiddle = false; // 상하 가운데 정렬 페이지 여부
    // this.isShowAnswer = false; // 문제 선택지 노출 여부
    this.isCorrect = false; // 정답 결과
    this.rewardData = { // 보상정보
      checked_pocket_money : true,
      checked_reward : true,
      success_point: null,
      fail_point: null,
    };
    this.accumulated_point = '0'; // 보상 비용 합계

    this.questData = {}; // 퀘스트 정보
    this.quizDataList = []; // 퀴즈 정보
    this.currentQuizData = {}; // 활성 퀴즈 정보
    this.selectedAnswer = ''; // 선택한 정답
    this.selectedAnswerList = []; // 선택한 정답
    this.selectedAnswerItem = {};

    this.tutorial_quiz_answer = {
      accumulated_point : 0,
      accumulated_success_cnt : 0,
      checked_answer : true,
      comments : "예산은 앞으로 얼마의 돈이 들어올지, 어디에 얼마의 돈을 쓸지 미리 계산하는 것이다.",
      is_last_course : false,
      is_last_quest_in_course : false,
      is_last_quest_in_place : false,
      is_last_quiz : true,
      is_level_up_target : false,
      quiz_answer : "예산",
      quiz_content_type : "SELECTONE",
      quiz_id : 'TUTORIAL',
      total_quiz_cnt : 1,
      user_id : null,
      user_answer:'',
    }
  }
  setQuestData(obj){
    this.questData = obj;
  }
  setQuizData(arr){
    this.quizDataList = arr;
    this.currentQuizData = arr[0];
    this.paginationData.total = arr.length;
    // this.paginationData.page = 1;
  }
  setRewardData(obj){
    const {checked_pocket_money, checked_reward, success_point, fail_point} = obj;
    this.rewardData.checked_pocket_money = checked_pocket_money;
    this.rewardData.checked_reward = checked_reward;
    this.rewardData.success_point = success_point;
    this.rewardData.fail_point = fail_point;
  }
  setQuizAnswerBefore(quizId,answer){
    const rObj = {
      quiz_id: quizId,
      user_answer: answer,
    }
    this.selectedAnswerList.push(rObj);
  }
  setQuizAnswer(obj){
    const diffIndex = this.selectedAnswerList.findIndex(item => item.quiz_id === obj.quiz_id);
    if(diffIndex >= 0){
      this.selectedAnswerList[diffIndex] = obj
    }else{
      this.selectedAnswerList.push(obj);
    }
    this.selectedAnswerItem = obj;
    this.accumulated_point = obj.accumulated_point;
  }
  setTutorialQuizAnswer(answer){
    // 용돈 계약이 되어있으면 보상없는 결과
    if(this.rewardData.checked_pocket_money){
      this.rewardData.checked_reward = false;
    // 용돈 계약이 안되어있으면 보상있는 결과
    }else{
      this.rewardData.checked_reward = true;
      this.rewardData.success_point = 100;
      this.rewardData.fail_point = 50;
    }

    let tutorial_quiz_answer = this.tutorial_quiz_answer;
    tutorial_quiz_answer.user_answer = answer;
    const checked_answer = Boolean(tutorial_quiz_answer.quiz_answer === answer);
    tutorial_quiz_answer.checked_answer = checked_answer;
    if(checked_answer){
      tutorial_quiz_answer.accumulated_success_cnt += 1;
      tutorial_quiz_answer.accumulated_point += this.rewardData.success_point;
    }else{
      tutorial_quiz_answer.accumulated_point += this.rewardData.fail_point;
    }
    this.selectedAnswerList.push(tutorial_quiz_answer);
    this.selectedAnswerItem = tutorial_quiz_answer;
    this.accumulated_point = tutorial_quiz_answer.accumulated_point;
  }
  getModifyData(){
    let obj = {
      introTitle : this.introTitle,
      introContents : this.introContents,
      contents : this.contents,
      content_attachment : this.content_attachment,
      content_attachment_type : this.content_attachment_type,
      options : this.options,
      answer : this.answer,
      grade : this.grade,
    }
    return obj;
  }
}