<template>
  <div class="container">
    <div v-if="!rewardData.is_reward_end" class="content">
      <p>퀴즈 풀면 </p>
      <p class="txt_point">{{ rewardData.reward_description_combine }}!</p>
    </div>
    <div v-else class="content">
      <p>{{ rewardData.reward_end_desc }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MapUiBottomRewardInfo',
  props: {
    rewardData: Object
  },
};
</script>
<!-- contentData.SPONSOR.reward_info -->
<style scoped>
.container {
  border-radius: 20px;
  border: 1px solid var(--neutrals-gray-50, #f3f1f1);
  background: var(--neutrals-white, #fff);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.24);

  color: var(--font-font-black, #111);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.content {
  display: flex;
  padding: 20px;
  gap: 4px;
}
.txt_point{
  color: var(--primary-purple-300, #8f69dd);
  font-weight: 700;
}
</style>
