<template>
  <component :is="componentName" />
</template>

<script>
import WorldMap from '@/views/worldMap/view/WorldMap.vue';
import SponsoredMap from '@/views/sponsoredMap/view/SponsoredMap.vue';
import PlusPaidMap from '@/views/plusPaidMap/view/PlusPaidMap.vue';
import StepMap from '@/views/stepMap/view/StepMap.vue';

export default {
  components: {
    WorldMap,
    SponsoredMap,
    PlusPaidMap,
    StepMap
  },
  computed: {
    componentName() {
      switch (this.$route.query.mapStyle) {
        case 'SPONSOR':
          return 'SponsoredMap';
        case 'PLUS':
          return 'PlusPaidMap';
        case 'STEP':
          return 'StepMap';
        case 'FREE':
          return 'WorldMap';
      }

      switch (this.$route.query.mapType) {
        case 'SPONSOR':
          return 'SponsoredMap';
        case 'PLUS':
          return 'PlusPaidMap';
        case 'STEP':
          return 'StepMap';
        default:
          return 'WorldMap';
      }
    },
  },
};
</script>
