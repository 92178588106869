import Vue from 'vue'
import store from '@/store/index';

export default class TutorialViewModel {
  constructor(isFromWeb = false) {
    this.page = {};
    this.pageDataList = [
      {
        index:0,
        component: 'TutorialStep1',
        // class: 'inner_app_leave_service_reason',
        bgColor:'SECONDARY',
        isBackBtn: false,
        isCloseBtn: true,
        onClickBack : () => { this.onClickClose() },
        onGoNextPage : () => { this.onGoNextPage() },
        boxDesc:'당신의 용돈으로 돌아가는 세상,<br>퍼핀월드의 구석구석을 돌아다니다 보면<br>짤랑짤랑 소리내는 정체불명의<br>퍼핀즈들을 마주하게 될거에요~',
        pagnationBulltBg:'#E2D7C4'
      },
      {
        index:1,
        component: 'TutorialStep2',
        // class : 'inner_app_leave_service',
        bgColor:'PRIMARY200',
        isBackBtn: false,
        isCloseBtn: true,
        onClickBack : () => { this.onGoPrevPage() },
        onGoNextPage : () => { this.onGoNextPage() },
        boxDesc:'매일 5문제씩 퀘스트를 깨면<br>부모님이 설정해주신 학습보상을 보너스<br>용돈으로 받을 수 있다는 놀라운 사실!<br>퀴즈를 틀려도 보상의 절반을 받을 수 있어요.',
        pagnationBulltBg:'#fff'
      },
      {
        index:2,
        component: 'TutorialStep3',
        // class : 'inner_app_leave_service',
        bgColor:'WHITE',
        isBackBtn: true,
        isCloseBtn: true,
        onClickBack : () => { this.onGoPrevPage() },
        onGoNextPage : () => { this.onClickQuiz() },
        pagnationBulltBg:'#E7E5E5'
      }
    ];
    this.closePopup = undefined;
    this.onClickQuiz = undefined;
    this.isFromWeb = isFromWeb;
  }
  init(){
    this.page = this.pageDataList[0];
  }
  onClickClose(){
    const onClickWebEnd = () => {
      // this.onClickWebEnd();
      this.goBack(this.isFromWeb);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>퍼핀월드 안내를 종료할까요?</strong>지금 아니면 다시는 못 봐요.<br>30초만 시간 내주시면 안 될까요? 🥺`,
      closeBtnText: '계속',
      compeleteBtnText: '종료하기',
      isBackEventCompelete: true,
      confirmCallBack: onClickWebEnd,
    });
  }
  goBack(isFromWeb = false) {
    try {
      //안드로이드
      if ( Vue.prototype.$varUA === 'ANDROID') {
        window.FirFinInterface.goBack();
      }
      //IOS
      else if ( Vue.prototype.$varUA === 'IOS' ) {
        if (isFromWeb) {
          window.webkit.messageHandlers.FirFinInterface.postMessage("goBack");
        } else {
          window.webkit.messageHandlers.FirFinInterface.postMessage("webEnd");
        }
      }
    } catch (e) {
      this.webEnd();
    }
  }
  webEnd() {
    try {
      //안드로이드
      if ( Vue.prototype.$varUA === 'ANDROID') {
        window.FirFinInterface.webEnd();
      }
      //IOS
      else if ( Vue.prototype.$varUA === 'IOS' ) {
        window.webkit.messageHandlers.FirFinInterface.postMessage("webEnd");
      }
    } catch (e) {
      // do nothing.
    }
  }
  onGoPrevPage(){
    const prevPageIndex = this.page.index - 1;
    if(prevPageIndex < 0) return;
    this.page = this.pageDataList[prevPageIndex];
  }
  onGoNextPage(){
    const nextPageIndex = this.page.index + 1;
    if(nextPageIndex > this.pageDataList.length -1) return;
    this.page = this.pageDataList[nextPageIndex];
  }
  
}