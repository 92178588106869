var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"map",class:[
    'area_map',
    { action: _vm.viewModel.isAction, selected: _vm.contentData.PLACE_DETAIL.id },
  ],style:(_vm.mapCssProps),attrs:{"id":"map"},on:{"!click":function($event){return _vm.handleClickMap.apply(null, arguments)}}},[_c('div',{staticClass:"inner_map",style:({
      width: `${_vm.viewModel.model.contentData.MAP.width}px`,
      height: `${_vm.viewModel.model.contentData.MAP.height}px`,
    })},[_c('div',{staticClass:"group_bg_map"},_vm._l((_vm.viewModel.model.contentData.MAP.list),function(mapItem){return _c('div',{key:mapItem.uid,class:['bg_map', 'img_container'],style:({
          width: `${mapItem.width}px`,
          height: `${mapItem.height}px`,
          top: `${mapItem.y}px`,
          left: `${mapItem.x}px`,
        })},[_c('img',{attrs:{"src":mapItem.image_url,"alt":`배경 이미지`,"loading":"lazy"}})])}),0),_vm._l((_vm.contentData.PLACE.total_list),function(levelPlace){return _c('div',{key:`levelPlace${levelPlace.stage}`,class:[
        'group_level',
        `level${levelPlace.stage}`,
        {
          active:
            _vm.isLocked ||
            (_vm.contentData.USER.stage != null &&
              _vm.contentData.USER.stage >= levelPlace.stage),
          // active:
          //   contentData.USER.stage != null &&
          //   contentData.USER.stage >= levelPlace.stage,
          action:
            _vm.viewModel.animationData.stage != null &&
            _vm.viewModel.animationData.stage === levelPlace.stage,
        },
      ]},_vm._l((levelPlace.placeList),function(place){return _c('div',{key:`Place${place.id}`,class:[
          'item_place',
          { selected: _vm.contentData.PLACE_DETAIL.place.id === place.id },
        ],style:({
          top: `${place.y}px`,
          left: `${place.x}px`,
          width: `${place.width}px`,
          height: `${place.height}px`,
        }),attrs:{"id":place.id},on:{"click":function($event){return _vm.viewModel.onClickPlace(place, levelPlace.stage)}}},[_c('div',{staticClass:"img_place img_active",style:({
            backgroundImage: `url(${place.active.imageUrl})`,
          })}),_c('div',{staticClass:"img_place img_disabled",style:({
            backgroundImage: `url(${place.disabled.imageUrl})`,
          })}),(
            _vm.viewModel.isShowMapUI &&
              _vm.contentData.PLACE.last_access_place.id === place.id
          )?_c('div',{staticClass:"profile_comm",style:({
            // marginLeft: (40 + getPlaceShadow(place.id)) * -1 + 'px',
            'background-image': `url(${_vm.contentData.USER.profile_image_url})`,
          })},[_c('IconSvg',{attrs:{"iconName":"profile_arrow_bottom","icoSize":14,"iconColor":"#232323"}})],1):_vm._e(),(_vm.viewModel.checkPlaceClear(place.id))?[_c('PlaceDone',{attrs:{"placeWidth":place.width}}),(!_vm.viewModel.isShowMapUI)?_c('Glowfly'):_vm._e(),_c('div',{staticClass:"icon_done"})]:_vm._e()],2)}),0)}),(_vm.isTestMode)?[_c('div',{staticClass:"text_border",style:([
          {
            top: `${_vm.viewModel.testBorderData.y * (100 / _vm.viewModel.scale) -
              4}px`,
            left: `${_vm.viewModel.testBorderData.x * (100 / _vm.viewModel.scale) -
              4}px`,
            width: `${_vm.viewModel.testBorderData.w}px`,
            height: `${_vm.viewModel.testBorderData.h}px`,
            fontSize: `${1500 / _vm.viewModel.scale}px`,
          },
        ])}),_c('div',{staticClass:"test_spot test_spot_x",style:([
          {
            left: `${_vm.viewModel.testSpotData.x * (100 / _vm.viewModel.scale)}px`,
            fontSize: `${1500 / _vm.viewModel.scale}px`,
          },
        ])},[_vm._v(" x ")]),_c('div',{staticClass:"test_spot test_spot_y",style:([
          {
            top: `${_vm.viewModel.testSpotData.y * (100 / _vm.viewModel.scale)}px`,
            fontSize: `${1500 / _vm.viewModel.scale}px`,
          },
        ])},[_vm._v(" y ")]),_c('div',{staticClass:"test_ruler_x"}),_c('div',{staticClass:"test_ruler_y"})]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }