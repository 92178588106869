<template>
  <PopupLayout>
    <div class="head_tutorial">
      <button
        v-if="viewModel.page.isBackBtn"
        class="btn_back"
        @click.stop="viewModel.page.onClickBack()">
        <IconSvg iconName="back" :size="24" :icoSize="16" iconColor="#232323"/>
      </button>
      <button
        v-if="viewModel.page.isCloseBtn"
        class="btn_close"
        @click.stop="viewModel.onClickClose()">
        <IconSvg iconName="cross" :icoSize="14" iconColor="#232323"/>
      </button>
    </div>
    <div class="cont_tutorial">
      <component
        :is="viewModel.page.component"
        @onGoNextPage="viewModel.page.onGoNextPage()" />
    </div>
    <div class="foot_tutorial">
      <FootTutorial
        :boxDesc="viewModel.page.boxDesc"
        :paginationData="{
          page : viewModel.page.index,
          totalPage : viewModel.pageDataList.length,
          bgColor : viewModel.page.pagnationBulltBg
        }"
        @onClickBack="viewModel.page.onClickBack()"
        @onGoNextPage="viewModel.page.onGoNextPage()"/>
    </div>
  </PopupLayout>
</template>
<script>
import PopupLayout from '@/components/layout/popup/PopupLayout'
import IconSvg from '@/components/common/icon/IconSvg';

import TutorialStep1 from '@/views/tutorial/view/components/TutorialStep1'
import TutorialStep2 from '@/views/tutorial/view/components/TutorialStep2'
import TutorialStep3 from '@/views/tutorial/view/components/TutorialStep3'
import FootTutorial from '@/views/tutorial/view/components/FootTutorial'
// viewModel
import TutorialViewModel from '@/views/tutorial/viewModel/TutorialViewModel'

export default {
  name: 'Tutorial',
  components:{
    PopupLayout,
    IconSvg,

    TutorialStep1,
    TutorialStep2,
    TutorialStep3,

    FootTutorial
  },
  beforeMount(){
    this.viewModel.init();
    // 퀴즈 팝업 업데이트
    const closePopup = () => { this.$emit('onClickClosePopup'); }
    this.viewModel.closePopup = closePopup;
    // 퀴즈 종료
    const onClickQuiz = () => { this.$emit('onClickQuiz'); }
    this.viewModel.onClickQuiz = onClickQuiz;
  },
  watch:{
    'viewModel.page.bgColor':{
      deep:true,
      handler(newVal, oldVal) {
        if(newVal != oldVal){
          this.$emit('onUpdatePopupData',{ bgColor : this.viewModel.page.bgColor });
        }
      }
    }
  },
  data(){
    return{
      viewModel : new TutorialViewModel(this.isFromWeb)
    }
  },
  props: {
    isFromWeb: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
<style scoped>
.head_tutorial{position:relative;z-index:100;flex:0 1 54px;min-height:54px;box-sizing:border-box}
.head_tutorial .inner_head{padding:12px 17px;}
.head_tutorial .tit_head{display:block;padding-left:8px;font-weight:600;font-size:16px;line-height:30px;text-align:center}
.head_tutorial .btn_back,
.head_tutorial .btn_close{position:absolute;top:12px;right:17px;width:30px;height:30px;padding:8px}
.head_tutorial .btn_back{left:17px;width:30px;height:30px}

.cont_tutorial{flex:1 1;overflow:hidden;font-size:0;white-space:nowrap;text-align:center}
.cont_tutorial:after{display:inline-block;height:100%;max-height:inherit;vertical-align:middle;content:''}
.cont_tutorial .inner_cont{display:inline-block;overflow-x:hidden;overflow-y:auto;vertical-align:middle;white-space:normal}

.cont_tutorial,
.foot_tutorial{padding:0 20px}

.foot_tutorial{flex:0 1}
.foot_tutorial >>> .group_btn_tutorial{margin-top:24px}
.foot_tutorial >>> .group_btn_tutorial:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.foot_tutorial >>> .group_btn_tutorial button{display:inline-block;width:auto;vertical-align:top}
.foot_tutorial >>> .group_btn_tutorial .btn_prev{float:left}
.foot_tutorial >>> .group_btn_tutorial .btn_prev .icon_back{margin:14px 16px 18px}
.foot_tutorial >>> .group_btn_tutorial .btn_next{float:right}
.foot_tutorial >>> .group_btn_tutorial .btn_next{padding:0 18px;line-height:44px}
.foot_tutorial >>> .group_btn_tutorial .btn_next .icon_arrow_right{margin:14px 0 18px 8px}

.foot_tutorial >>> .box_tutorial{margin-top:24px;padding:16px 14px;border:1px solid #232323;border-radius:2px}
.foot_tutorial >>> .box_tutorial .desc_tutorial{font-weight:600;font-size:16px;line-height:24px;color:#232323;word-wrap:break-word;word-break:keep-all}

.foot_tutorial >>> .page_tutorial{height:8px;padding:32px 0 30px;text-align:center}
.foot_tutorial >>> .page_tutorial .bullt_page{display:inline-block;width:8px;height:8px;border-radius:8px;vertical-align:top}
.foot_tutorial >>> .page_tutorial .bullt_page.active{background-color:#232323}
.foot_tutorial >>> .page_tutorial .bullt_page + .bullt_page{margin-left:7px}
</style>