var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import apiPath from '@/services/apiPath';
import { GET_REQUEST } from '@/services/http';
import store from '@/store';
import WorldMapViewModel from '@/views/worldMap/viewModel/WorldMapViewModel';
var MapViewModelBase = /** @class */ (function (_super) {
    __extends(MapViewModelBase, _super);
    function MapViewModelBase(mapUid) {
        var _this = _super.call(this) || this;
        // 퍼핀월드 : m9feozFXMmEqThJO7goqc
        // 은행연합 : V7WrEjUIwP3TK3vUu1Y2I
        _this.mapUid = null;
        // mapUid가 필수지만, 마안약에 안들어온다면, 퍼핀월드 맵으로.
        _this.mapUid = mapUid || 'm9feozFXMmEqThJO7goqc';
        return _this;
    }
    MapViewModelBase.prototype.init = function (query) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var params;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (query) {
                            this.model.setQuery(query);
                        }
                        this.isFromWeb = query.from === 'web';
                        return [4 /*yield*/, this.getMapResource()];
                    case 1:
                        _b.sent();
                        this.getRemainAmount();
                        if (!this.hasMapAuth) {
                            // 맵에 권한이 없으면 강제로 기본 지도 진입 모드로 변환.
                            this.model.contentData.USER.init_type = 'WORLD_MAP';
                            store.dispatch('map/fetchLockStart');
                            params = {
                                place: {
                                    x: 875,
                                    y: 780,
                                    width: 255,
                                    height: 337,
                                },
                                isSmooth: false,
                                scale: 75,
                                duration: 0,
                                topHeightPercent: 100,
                            };
                            this.setPosition(params);
                            // 의미에 맞지 않지만, 이렇게 처리해두겠다.
                            this.setFirstLoadCheckList('isLoadedPlaceFocus', true);
                            this.setFirstLoadCheckList('isLoadedUserStatus', true);
                            // 로드완료 interface (loadFinish) 전달
                            // this.loadFinish();
                            return [2 /*return*/];
                        }
                        (_a = this.getUserStatus()) === null || _a === void 0 ? void 0 : _a.then(function (result) {
                            _this.setFirstLoadCheckList('isLoadedUserStatus', true);
                            _this.checkInitType(result); // 진입 형태 체크
                        });
                        this.loadFinish();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(MapViewModelBase.prototype, "mapInfoWrapper", {
        get: function () {
            return this.model.contentData.MAP;
        },
        enumerable: false,
        configurable: true
    });
    MapViewModelBase.prototype.getRemainAmount = function () {
        var _this = this;
        if (!this.mapUid)
            return null;
        var path = "".concat(apiPath.SPONSOR_REMAIN_AMOUNT.replace('%s', this.mapUid));
        return GET_REQUEST(path).then(function (successResponse) {
            var response = successResponse.data.body;
            _this.model.setRemainAmount(response.remain_amount);
        }, function (failed) {
            store.dispatch('commonToast/fetchToastStart', failed.msg);
        });
    };
    MapViewModelBase.prototype.getMapResource = function () {
        var _this = this;
        if (!this.mapUid)
            return null;
        if (this.firstLoadCheckList.isLoadFailed)
            return null;
        var path = "".concat(apiPath.MAP_RESOURCE_LIST.replace('%s', this.mapUid));
        return GET_REQUEST(path).then(function (successResponse) {
            var response = successResponse.data.body;
            var responseMap = response.map;
            // const data: MapData = {
            //   id: responseMap.id,
            //   uid: responseMap.uid,
            //   thumbnailImageUrl: responseMap.thumbnail_image_url,
            //   // title: responseMap.title,
            //   name: responseMap.name,
            //   mapCategoryId: responseMap.map_category_id,
            //   mapType: responseMap.map_type,
            //   width: responseMap.width,
            //   height: responseMap.height,
            //   rowCount: responseMap.row_count,
            //   columnCount: responseMap.column_count,
            //   list: response.map_resource_list.map((item: any) => ({
            //     x: item.x,
            //     y: item.y,
            //     width: item.width,
            //     height: item.height,
            //     uid: item.uid,
            //     mapUid: item.map_uid,
            //     imageUrl: item.image_url,
            //   })),
            // };
            // this.mapInfo = data as any;
            _this.model.setSponsor(response.sponsor);
            _this.model.setMap(responseMap);
            _this.model.setMapAuth(response.map_authentication);
            _this.model.setMapBg(response.map_resource_list);
            _this.model.setPlaceList(_this.getStageInfos(response.place_area_list));
            _this.hasMapAuth = response.map_authentication.is_open;
            _this.setFirstLoadCheckList('isLoadedMapInfo', true);
            _this.setMinScale(); // 최소 줌아웃 사이즈 체크
            _this.setFirstLoadCheckList('isLoadedPlaceListArea', true);
        }, function (failed) {
            _this.firstLoadCheckList.isLoadFailed = true;
            // store.dispatch('commonToast/fetchToastStart', failed.msg);
        });
    };
    MapViewModelBase.prototype.getStageInfos = function (place_area_list) {
        var stageInfos = place_area_list.map(function (areaItem) { return ({
            stage: areaItem.stage,
            placeList: areaItem.place_list.map(function (placeItem) { return (__assign({ 
                // x: placeItem.x,
                // y: placeItem.y,
                // width: placeItem.width,
                // height: placeItem.height,
                // stage: placeItem.stage,
                // id: placeItem.id,
                active: {
                    imageUrl: placeItem.place_resource.active.image_url,
                }, disabled: {
                    imageUrl: placeItem.place_resource.disabled.image_url,
                }, thumbnail: {
                    imageUrl: placeItem.place_resource.thumbnail.image_url,
                } }, placeItem)); }),
        }); });
        stageInfos.sort(function (a, b) { return a.stage - b.stage; });
        return stageInfos;
    };
    MapViewModelBase.prototype.getThumbnailImageUrl = function (placeId) {
        var total_list = this.model.contentData.PLACE.total_list;
        var placeInfo;
        for (var _i = 0, total_list_1 = total_list; _i < total_list_1.length; _i++) {
            var stageInfo = total_list_1[_i];
            placeInfo = stageInfo.placeList.find(function (placeInfo) { return placeInfo.id === placeId; });
            if (placeInfo)
                break;
        }
        return placeInfo === null || placeInfo === void 0 ? void 0 : placeInfo.thumbnail.imageUrl;
    };
    return MapViewModelBase;
}(WorldMapViewModel));
export default MapViewModelBase;
