<template>
  <div class="inner_foot">
    <div v-if="boxDesc" class="group_btn_tutorial">
      <Button
        class="btn_prev"
        btnSize="small"
        btnStyle="outlined"
        @onClickBtn="$emit('onClickBack')">
        <IconSvg iconName="back" :icoSize="16" iconColor="#232323"/>
      </Button>
      <Button
        class="btn_next"
        btnSize="small"
        btnStyle="outlined_shadow"
        text="NEXT"
        @onClickBtn="$emit('onGoNextPage')">
        <template v-slot:btn_right>
          <IconSvg iconName="arrow_right" :icoSize="16" iconColor="#232323"/>
        </template>
      </Button>
    </div>
    <div v-if="boxDesc" class="box_tutorial">
      <p class="desc_tutorial" v-html="boxDesc"></p>
    </div>
    <div class="page_tutorial">
      <span
        v-for="page in paginationData.totalPage"
        :key="`page${page}`"
        :class="[
          'bullt_page',
          { active : page ===  paginationData.page +1}
        ]"
        :style="{
          '--bulletBg' : paginationData.bgColor
        }"
        ></span>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/button/Button';
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name: 'FootTutorial',
  props:{
    boxDesc: String,
    paginationData: Object,
  },
  components:{
    Button,
    IconSvg,
  },
}
</script>

<style scoped>
.page_tutorial .bullt_page{background-color:var(--bulletBg)}
</style>