<template>
  <div :class="['controal_test', { active: viewModel.isOpenControal }]">
    <div class="inner_controal_test">
      <div class="item_controal item_level">
        <p>user stage {{ contentData.USER.stage }}</p>
        <button
          :disabled="contentData.USER.stage === 16"
          @click="viewModel.onClickStageUp()"
        >
          stageUp
        </button>
        <button
          :disabled="contentData.USER.stage === 0"
          @click="viewModel.onClickLevelReset()"
        >
          stage reset
        </button>
      </div>
      <div class="item_controal">
        <p>튜토리얼</p>
        <button @click="viewModel.tutorialPopup()">튜토리얼 시작</button>
      </div>
      <div class="item_controal">
        <p>퀘스트 진행상태</p>
        <button
          @click="viewModel.onClickQuestStatusDelete()"
        >
          초기화
        </button>
      </div>
      <div class="item_controal">
        <p>용돈계약유무</p>
        <button @click="viewModel.onClickTestChangePocketMoney()">
          {{ contentData.USER.checked_pocket_money ? 'O' : 'X' }}
        </button>
      </div>
      <div class="item_controal">
        <p>보상유무</p>
        <button @click="viewModel.onClickTestChangeReward()">
          {{ contentData.USER.checked_reward ? 'O' : 'X' }}
        </button>
      </div>
      <div class="item_controal item_quest">
        <p>퀴즈 완료후 팝업</p>
        <button @click="testControalQuestClearPopup()">퀘스트 완료</button>
        <button @click="testControalPlaceClearPopup()">장소 완료</button>
        <button @click="testControalStageUpPopup()">스테이지업</button>
        <button @click="testControalMapCompletePopup()">맵 완료</button>
      </div>
      <div class="item_controal item_quest">
        <button
          :disabled="getIsToastPopup"
          @click="
            $store.dispatch('commonToast/fetchToastStart', {
              msg: '토스트 팝업이 성공적으로 열렸습니다!',
              type: 'success',
            })
          "
        >
          토스트 팝업1
        </button>
        <button
          :disabled="getIsToastPopup"
          @click="
            $store.dispatch('commonToast/fetchToastStart', {
              msg:
                '용돈계약이 있어야 퀘스트를 열 수 있어요!<br>부모님께 요청해 주세요',
              type: 'error',
            })
          "
        >
          토스트 팝업2
        </button>
      </div>
      <div class="item_controal item_scale">
        <p>scale</p>
        <div class="group_scale">
          <button
            :disabled="
              viewModel.scale === viewModel.scaleDataList[0].text ||
                viewModel.scale - 25 < viewModel.minScale
            "
            @click="viewModel.onClickChangeScale(-1)"
          >
            -
          </button>
          <span>{{ Math.floor(viewModel.scale) }}</span>
          <button
            :disabled="
              viewModel.scale ===
                viewModel.scaleDataList[viewModel.scaleDataList.length - 1].text
            "
            @click="viewModel.onClickChangeScale(+1)"
          >
            +
          </button>
        </div>
      </div>
      <div class="item_controal">
        <p>SafeArea</p>
        <p>Top : {{ getSafeAreaTopHeight }}</p>
        <p>Bottom : {{ getSafeAreaBottomHeight }}</p>
      </div>
      <div class="item_controal item_test">
        <span>test<br />mode</span>
        <button @click="onClickTest()">
          {{ isTestMode ? 'ON' : 'OFF' }}
        </button>
      </div>
    </div>
    <button class="btn_controal" @click="onClickControal()">
      <!-- eslint-disable-next-line vue/no-parsing-error -->
      {{ viewModel.isOpenControal ? 'X' : '<' }}
    </button>
  </div>
</template>

<script>
// viewModel
import MapViewModelBase from '@/views/worldMap/viewModel/MapViewModelBase';

import { mapGetters } from 'vuex';

export default {
  name: 'DevTools',
  props: {
    viewModel: MapViewModelBase,
    isTestMode: Boolean,
  },
  computed: {
    ...mapGetters('commonToast', ['getIsToastPopup']),
    ...mapGetters('commonViewport', [
      'getSafeAreaTopHeight',
      'getSafeAreaBottomHeight',
    ]),
    contentData() {
      return this.viewModel.model.contentData;
    },
  },
  methods: {
    // ---- test controal ----
    // test controal - 접었다 펼치기
    onClickControal() {
      this.viewModel.isOpenControal = !this.viewModel.isOpenControal;
    },
    // test mode
    onClickTest() {
      this.$emit('update:isTestMode', !this.isTestMode);
      // this.isTestMode = !this.isTestMode;
    },
    // - 퀴즈 완료후 팝업 -
    // 퀘스트 완료
    testControalQuestClearPopup() {
      const place = this.viewModel.model.contentData.PLACE_DETAIL.place;
      this.viewModel.placeDetailFocusInNotSmooth(place);
      const current = this.viewModel.model.contentData.QUEST.last_answer;
      const rObj = {
        is_last_quest_in_place: false,
        accumulated_point: '1200',
        accumulated_success_cnt: '2',
        total_quiz_cnt: '5',
      };
      if (current.has_rewards === undefined) {
        rObj.has_rewards = true;
      }
      this.viewModel.model.contentData.QUEST.last_answer = Object.assign(
        current,
        rObj,
      );
      this.viewModel.questClearPopupOpen();
      this.viewModel.getUserPlaceStatus(place);
    },
    // 장소 완료
    testControalPlaceClearPopup() {
      let place = this.viewModel.model.contentData.PLACE_DETAIL.place;
      if (!place.id) {
        this.viewModel.model.contentData.PLACE_DETAIL.place = {
          name: '한국은행',
          eng_name: 'Central Bank',
          x: 2481,
          y: 2754,
          width: 184,
          height: 146,
        };
        this.viewModel.model.contentData.PLACE_DETAIL.clear_data = {
          total_accumulated_point: 2500,
          total_quiz_count: 25,
          total_accumulated_success_cnt: 16,
        };
      }
      this.viewModel.placeClearPopupOpen();
      this.isShowMapUI = false;
      const params = {
        place: place,
        isSmooth: false,
        scale: 100,
      };
      this.viewModel.setPosition(params);
      if (place.id) {
        this.viewModel.getUserPlaceStatus(place);
      }
    },
    // 스테이지업
    testControalStageUpPopup() {
      this.viewModel.stageUpPopupOpen();
    },
    // 맵완료
    testControalMapCompletePopup() {
      this.viewModel.mapCompletePopupOpen();
    },
  },
};
</script>

<style scoped>
/* controal_test */
.controal_test {
  position: fixed;
  top: var(--safeAreaTopHeightControal);
  right: -103px;
  z-index: 9999;
  width: 103px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}
.controal_test.active {
  right: 0px;
}
.controal_test .inner_controal_test {
  padding: 8px;
  max-height: var(--controalTestMaxHeight);
  overflow-y: auto;
}
.controal_test .btn_controal {
  position: absolute;
  top: 4px;
  right: 100%;
  width: 30px;
  height: 30px;
  border: 0 none;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}

.inner_controal_test .item_controal {
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  text-align: center;
}
.inner_controal_test button {
  display: block;
  width: 100%;
  padding: 2px 8px;
  font-weight: bold;
  font-size: 12px;
  border: 2px solid #fff;
  color: #fff;
}
.inner_controal_test p {
  margin-bottom: 8px;
}
.inner_controal_test .item_level button,
.inner_controal_test .item_quest button {
  margin-top: 8px;
}
.inner_controal_test .item_controal + .item_controal {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dashed #aaa;
}
.inner_controal_test button:disabled {
  color: #555;
  border-color: #555;
}
.inner_controal_test .item_scale span {
  display: inline-block;
  width: 33px;
  line-height: 26px;
  vertical-align: top;
  text-align: center;
}
.inner_controal_test .item_scale button {
  display: inline-block;
  width: 24px;
  padding: 2px 0;
  border-radius: 100%;
  vertical-align: top;
}
.inner_controal_test .item_test span {
  display: inline-block;
  margin-right: 4px;
  font-size: 12px;
  line-height: 13px;
  vertical-align: top;
}
.inner_controal_test .item_test button {
  display: inline-block;
  width: 50px;
  vertical-align: top;
}
.inner_controal_test .select_controal {
  line-height: 22px;
}
.inner_controal_test .select_controal p {
  position: relative;
  margin: 0;
}
.inner_controal_test .select_controal p .icon_arrow_bottom {
  position: absolute;
  top: 4px;
  right: 0;
}
.inner_controal_test .select_controal.active p .icon_arrow_bottom {
  transform: scaleY(-1);
}
.inner_controal_test .select_controal .list_controal {
  border: 2px solid #fff;
  border-top: 0 none;
}
.inner_controal_test .select_controal .list_controal li {
  border-top: 2px solid #fff;
}
</style>
