var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'lemontree_layer',
    `lemontree_layer_${_vm.type}`,
    _vm.direct ? `lemontree_layer_direct_${_vm.direct}` : '',
    { dragable: _vm.isDragable },
    { active: _vm.isActive },
    { openDrag: _vm.isOpenResult },
    { moving: _vm.initPoint != null },
  ],style:(_vm.contCssProps)},[_c('div',{ref:"wrapLayer",staticClass:"wrap_layer",style:({
      '--bottomSheetHeight': _vm.bottomSheetHeight
        ? _vm.bottomSheetHeight + 'px'
        : 'auto',
    })},[(_vm.isDragable)?_c('div',{staticClass:"area_drag",on:{"mousedown":_vm.onListStart,"touchstart":_vm.onListStart,"mousemove":_vm.onListMove,"touchmove":_vm.onListMove,"mouseup":_vm.onListEnd,"mouseleave":_vm.onListEnd,"touchend":_vm.onListEnd,"touchcancle":_vm.onListEnd}},[_c('span',{staticClass:"icon_drag"})]):_vm._e(),_vm._t("default")],2),(_vm.type === 'bottom_sheet')?_c('button',{staticClass:"btn_close",on:{"click":function($event){return _vm.$emit('onClickClose')}}},[(_vm.isDragable && _vm.isOpenResult)?[_c('IconSvg',{attrs:{"iconName":"cross","size":24,"icoSize":14,"iconColor":"#232323"}})]:[_c('IconSvg',{attrs:{"iconName":"map","size":24,"icoSize":18,"iconColor":"#232323"}}),_vm._v(_vm._s(_vm.$poData.place_details_go_worldmap_btn)+" ")]],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }