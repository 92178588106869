<template>
  <div class="inner_cont">
    <div class="result_body result_reward_y result_correct">
      <!-- 금액 뱃지 -->
      <span class="badge_price">+ 300원</span>
      <!-- 결과 -->
      <div class="area_get" ref="areaGet">
        <!-- 이미지 -->
        <div
          id="imgResult"
          class="img_result">
          <Lottie
            class="lottie_result_img"
            :options="resultImgLottieOption"
            @animCreated="handleAnimation" />
          <Lottie
            class="lottie_result_fireworks"
            :options="resultFireworksOption"
            @animCreated="handleAnimation" />
        </div>
      </div>
      <!-- 문구 -->
      <p class="desc_result">{{ $poData.quiz_explanation_title_correct }}</p>
    </div>
  </div>
</template>

<script>
import Lottie from 'vue-lottie';
import card from '@/assets/json/card.json';
import fireworks from '@/assets/json/fireworks.json';

export default {
  name: 'TutorialStep2',
  components:{
    Lottie
  },
  data(){
    return{
      resultImgLottieOption: {
        animationData: card
      },
      resultFireworksOption: {
        animationData: fireworks,
        // loop: false,
      },
    }
  },
  methods:{
    handleAnimation(anim) {
      this.anim = anim;
    },
  }
}
</script>

<style scoped>
.inner_cont{flex:1 1 100%;max-height:100%}
.result_body{display:flex !important;flex-direction:column;position:relative;height:100%;text-align:center;box-sizing:border-box}
/* .result_body .tit_result{flex:0 1;display:block;margin-bottom:12px;font-family:'Poppins';font-weight:700;font-size:34px;line-height:40px;color:#232323} */
.result_body .area_get{flex:0 1 100%;padding-top:24px;font-size:0;white-space:nowrap;text-align:center}
.result_body .area_get:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.result_body .badge_price{display:inline-block;color:#232323;vertical-align:top}
.result_body .desc_result{margin-top:24px;font-family:'Poppins';font-weight:700;font-size:28px;line-height:34px}
.result_body .lottie_result_fireworks{position:absolute;top:35%;left:50%;width:150% !important;height:auto !important;transform: translate(-50%, -50%);}
/* -- 보상 있음 -- */
.result_reward_y .badge_price{font-weight:700;font-size:28px;line-height:36px;
  animation-name: priceEntranceToCard;
  animation-duration:0.8s;
  animation-delay:0.8s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.result_reward_y .desc_result{color:#fff}
.result_reward_y.result_wrong .txt_delete{display:block;margin-bottom:2px}
.result_reward_y.result_wrong .txt_delete .inner_txt_delete{display:inline-block;position:relative;font-weight:700;font-size:16px;line-height:24px;vertical-align:top;color:#E23C3C;
  animation-name: priceDeleteFadeOut;
  animation-duration:0.8s;
  animation-delay:0.8s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.result_reward_y.result_wrong .txt_delete .inner_txt_delete:after{position:absolute;top:12px;left:0;width:0;height:1px;background-color:#E23C3C;content:'';
  animation-name: priceDeleteLineShow;
  animation-duration:0.8s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
/* -- 보상 없음 -- */
.result_reward_n .badge_price{align-self:center;display:block;padding:6px 8px;border-radius:4px;font-weight:700;font-size:20px;line-height:28px;background-color:#BEA9ED}
/* 문구 */
.result_reward_n .desc_result{color:#8F69DD}
/* -- 공통 -- */
.result_body #imgResult.img_result{display:inline-block;position:relative;z-index:2;width:100%;height:100%;max-width:260px;max-height:260px;background-size:contain;vertical-align:middle;white-space:normal}

/* ---- 높이 640 이하 디바이스 ---- */
/* .short_device .inner_cont{height:286px} */
.short_device .result_body .area_get{padding-top:8px}
.short_device .result_body .desc_result{margin-top:8px}
.short_device .result_body #imgResult.img_result{width:100%;height:100%;max-width:200px;max-height:200px;background-size:contain;}

@keyframes priceDeleteLineShow {
  0%{
    width:0;
  }
  100%{
    width:100%
  }
}

@keyframes priceDeleteFadeOut {
  0%{
    opacity:1;
  }
  100%{
    opacity:0
  }
}

@keyframes priceEntranceToCard {
  0%{
    transform:translateY(0);opacity:1;
  }
  100%{
    transform:translateY(80px);opacity:0
  }
}
</style>