<template>
  <div
    class="inner_comm_layer"
    :style="cssProps">
    <template v-if="isTemplate">
      <div class="head_layer" ref="headLayer">
        <slot name="head"/>
      </div>
      <div class="body_layer" ref="bodyLayer">
        <div class="inner_body">
          <slot />
        </div>
      </div>
      <div class="foot_layer">
        <slot name="foot"/>
        <div class="inner_foot">
          <Button
          v-if="compeleteBtnText"
          btnSize="medium"
          btnStyle="secondary"
          :text="compeleteBtnText"
          :disabled="isDisabeldCompeleteBtn"
          @onClickBtn="$emit('onClickComplete')" />
          <slot name="foot_bottom"/>
        </div>
      </div>
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
import Button from '@/components/common/button/Button';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'PopupLayout',
  components:{
    Button
  },
  props:{
    paddingTop:{
      type: Number,
      default: 0
    },
    isTemplate: false,
    compeleteBtnText: String,
    isDisabeldCompeleteBtn:{
      type:Boolean,
      default: false
    },
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowHeight', 'getSafeAreaTopHeight', 'getSafeAreaBottomHeight' ]),
    cssProps(){
      let cssObj = {
        '--paddingTop' : this.getWindowHeight * ( this.paddingTop / 100 ) + this.getSafeAreaTopHeight  + 'px',
        '--paddingBottom' : this.getSafeAreaBottomHeight  + 'px',
      };
      return cssObj;
    },
  }
}
</script>

<style scoped>

/* full */
.lemontree_layer_full .inner_comm_layer{
  position:relative;
  display:flex;
  flex-direction:column;
  height:100%;
  padding-top:var(--paddingTop);
  box-sizing:border-box;
  text-align:center;
}

.lemontree_layer_full .head_layer{flex:0 1;padding-top:32px}
.lemontree_layer_full .head_layer >>> .tit_layer{display:block;font-family:'Poppins';font-weight:700;font-size:34px;line-height:40px;color:#232323}
.lemontree_layer_full .head_layer >>> .txt_small{display:block;font-weight:700;font-size:24px;line-height:30px;color:#232323}
.lemontree_layer_full .head_layer .badge_price{display:inline-block;margin-top:32px;padding:8px 12px 8px 40px;border-radius:4px;font-weight:700;font-size:16px;line-height:24px;color:#232323;vertical-align:top}
.lemontree_layer_full .head_layer .badge_price.badge_primary{background:#BEA9ED url('/assets/images/img_price_230330.png')no-repeat 6px 6px;background-size:28px;}
.lemontree_layer_full .head_layer .badge_price.badge_secondary{background:#FBAE8A url('/assets/images/img_price_230330.png')no-repeat 6px 6px;background-size:28px;}
.lemontree_layer_full .head_layer .badge_price.badge_tutorial{background:#FBAE8A url('/assets/images/img_tutorial.png')no-repeat 6px 6px;background-size:28px;}

.lemontree_layer_full .body_layer{flex:1 1 100%;overflow-y:auto;font-size:0;white-space:nowrap}
.lemontree_layer_full .body_layer:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.lemontree_layer_full .body_layer >>> .inner_body{display:inline-block;width:100%;vertical-align:middle;white-space:normal}

.lemontree_layer_full .foot_layer{flex:0 1 104px}
.lemontree_layer_full .foot_layer >>> .desc_layer{margin-bottom:44px;font-weight:700;font-size:16px;line-height:24px;color:#232323;text-align:center}
.lemontree_layer_full .foot_layer .inner_foot{padding:0 20px 32px}
.lemontree_layer_full .foot_layer .inner_foot button{position:relative;z-index:110}

/* bottom sheet */
.lemontree_layer_bottom_sheet .inner_comm_layer{
  display:flex;
  flex-direction:column;
  height:100%;
  overflow:hidden;
  padding-top:24px;
  padding:24px 20px 0;
  box-sizing:border-box;
  text-align:left;
  padding-bottom:var(--paddingBottom);
}
.lemontree_layer_bottom_sheet .head_layer{flex:0 1}
.lemontree_layer_bottom_sheet .body_layer{flex:1 1 100%;overflow-y:auto}
.lemontree_layer_bottom_sheet .foot_layer{flex:0 1;padding:24px 0 24px}

/* 높이 640 이하 디바이스 */
.short_device .lemontree_layer_full .head_layer .badge_price{margin-top:16px}
</style>