<template>
  <PopupLayout
    :paddingTop="2"
    :isTemplate="true"
    :class="[
      'inner_quest_clear_layer',
      { 'inner_quest_clear_layer_tutorial_share' : isTutorial && !checked_pocket_money }
    ]"
    :compeleteBtnText="compeleteBtnText"
    @onClickComplete="$emit('onClickComplete')">
    <template v-slot:head>
      <strong class="tit_layer" v-html="$poData.quest_clear_title"></strong>
      <span :class="badgeData.class">{{ badgeData.text }}</span>

      <!-- 카카오톡 공유하기 하는 튜토리얼 완료시 닫기버튼 -->
      <button
        v-if="isTutorial && !checked_pocket_money"
        class="btn_close"
        @click.stop="$emit('onClickClose')">
        <IconSvg iconName="cross" :icoSize="14" iconColor="#232323"/>
      </button>
    </template>
    <!-- <div class="img_character"></div> -->
    <Lottie
      class="lottie_clear_img"
      :options="clearImgLottieOption"
      @animCreated="handleAnimation" />
    <template v-slot:foot>
      <p class="desc_layer">{{ $poData.place_quest_clear_right_answer_sum.format(quizAnswerData.total_quiz_cnt, quizAnswerData.accumulated_success_cnt || '0') }}</p>
    </template>
  </PopupLayout>
</template>
<script>
import PopupLayout from '@/components/layout/popup/PopupLayout'
import Lottie from 'vue-lottie';
import goal3 from '@/assets/json/goal3.json';
import IconSvg from '@/components/common/icon/IconSvg';
import { addComma } from '@/utils/stringUtils'

export default {
  name: 'QuestClearPopup',
  props:{
    questData: Object,
    checked_pocket_money: Boolean,
    checked_reward: Boolean
  },
  components:{
    PopupLayout,
    Lottie,
    IconSvg,
  },
  data(){
    return{
      clearImgLottieOption: {
        animationData: goal3
      },
    }
  },
  computed:{
    quizAnswerData(){
      return this.questData.last_answer
    },
    isTutorial(){
      return this.quizAnswerData.quiz_id === 'TUTORIAL'
    },
    compeleteBtnText(){
      if(this.isTutorial){
        if(this.checked_pocket_money){
          return '진짜 퀘스트 깨러 가기';
        }else{
          return '카톡으로 부모님께 보상요청';
        }
      }else{
        return this.$poData.quest_clear_btn
      }
    },
    badgeData(){
      let result = {
        text:'',
        clasee:''
      }
      if(this.isTutorial){
        if(this.checked_pocket_money){
          result.text = '튜토리얼 완료';
          result.class = ['badge_price', 'badge_tutorial'];
        }else{
          result.text = this.$poData.place_quest_clear_total_reward_1.format(addComma(this.quizAnswerData.accumulated_point));
          result.class = ['badge_price', 'badge_secondary'];
        }
      }else{
        if(this.checked_reward){
          result.text = this.$poData.place_quest_clear_total_reward_1.format(addComma(this.quizAnswerData.accumulated_point));
          result.class = ['badge_price', 'badge_secondary'];
        }else{
          result.text = this.$poData.place_quest_clear_total_reward_2;
          result.class = ['badge_price', 'badge_secondary'];
        }
      }
      return result;
    }
  },
  methods:{
    addComma(val){
      return addComma(val)
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
  },
}
</script>

<style scoped>
.inner_quest_clear_layer_tutorial_share >>> .head_layer{position:relative;padding-top:54px !important}
.inner_quest_clear_layer >>> .inner_body{height:100%;max-height:380px;padding:40px 0;box-sizing:border-box}

.btn_close{position:absolute;top:12px;right:17px;padding:8px}

/* 높이 640 이하 디바이스 */
.short_device .inner_quest_clear_layer >>> .head_layer{padding-top:32px}
.short_device .inner_quest_clear_layer >>> .inner_body{max-height:236px;padding:8px 0}
</style>