<template>
  <div :class="[ 'progress_line', { 'progress_line_full' : persent >= 100 } ]">
    <div class="area_right">
      <span class="txt_progress">{{ value }}/{{ total }}</span>
    </div>
    <div class="area_overflow">
      <div class="area_progresss">
        <div class="progresss_bar" :style="{ width : persent+'%' }"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LineProgress',
  props:{
    value: Number,
    total :Number,
  },
  computed:{
    persent(){
      return this.value / this.total * 100
    },
  }
}
</script>

<style scoped>
.progress_line{overflow:hidden;height:16px}
.progress_line .txt_progress{display:block;min-width:32px;font-size:12px;line-height:16px;color:#999;text-align:right}
.area_progresss{display:block;overflow:hidden;width:100%;height:3px;margin-top:6px;border-radius:100px;background-color:#F3F1F1}
.area_progresss .progresss_bar{display:inline-block;height:3px;border-radius:100px;background-color:#BEA9ED;vertical-align:top}

.progress_line_full .txt_progress{color:#8F69DD}
</style>