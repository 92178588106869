<template>
  <div class="group_glowfly">
    <span
      v-for="index in amount"
      :key="`growfly${index}`"
      class="item_glowfly"></span>
  </div>
</template>
<script>
export default {
  name: 'Glowfly',
  props: {
    amount:{
      type: Number,
      default:8
    }
  }
}
</script>

<style>
@keyframes glowfly-x{
  0%{
    transform:translateX(0);
  }
  25%{
    transform:translateX(20px);
  }
  50%{
    transform:translateX(0);
  }
  75%{
    transform:translateX(-20px);
  }
  100%{
    transform:translateX(0);
  }
}
@keyframes glowfly-y{
  0%{
    transform:translateY(0);
  }
  12.5%{
    transform:translateY(6px);
  }
  25%{
    transform:translateY(0);
  }
  37.5%{
    transform:translateY(-6px);
  }
  50%{
    transform:translateY(0);
  }
  62.5%{
    transform:translateY(6px);
  }
  75%{
    transform:translateY(0);
  }
  87.5%{
    transform:translateY(-6px);
  }
  100%{
    transform:translateY(0);
  }
}

.group_glowfly{position:absolute;top:10px;bottom:30px;left:-40px;right:-40px;z-index:2}
.group_glowfly .item_glowfly:nth-child(1){top:7%;left:8%;animation-delay:0s}
.group_glowfly .item_glowfly:nth-child(2){top:4%;left:70%;animation-delay:10s}
.group_glowfly .item_glowfly:nth-child(3){top:18%;left:30%;animation-delay:2s}
.group_glowfly .item_glowfly:nth-child(4){top:20%;left:98%;animation-delay:7s}
.group_glowfly .item_glowfly:nth-child(5){top:26%;left:96%;animation-delay:11s}
.group_glowfly .item_glowfly:nth-child(6){top:50%;left:10%;animation-delay:16s}
.group_glowfly .item_glowfly:nth-child(7){top:52%;left:84%;animation-delay:8s}
.group_glowfly .item_glowfly:nth-child(8){top:80%;left:70%;animation-delay:1s}
/* .group_glowfly .item_glowfly{position:absolute;width:5px;height:5px;border-radius:5px;background-color:#F0C75B}
.group_glowfly .item_glowfly:nth-child(odd){animation: glowfly-left 18s linear 1s infinite}
.group_glowfly .item_glowfly:nth-child(even){animation: glowfly-right 18s linear 1s infinite} */
.group_glowfly .item_glowfly{
  position:absolute;width:5px;height:5px;
  animation: glowfly-x 10s linear 1s infinite}
.group_glowfly .item_glowfly:after{
  display:block;width:5px;height:5px;border-radius:5px;background-color:#F0C75B;content:'';
  animation: glowfly-y 10s linear 1s infinite}

.group_glowfly .item_glowfly:nth-child(even),
.group_glowfly .item_glowfly:nth-child(even):after{animation-direction: reverse}
</style>