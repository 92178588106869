<template>
  <div id="lemontreeHead" class="head_lemontree">
    <div class="inner_head">
      <button
        v-if="isBackBtn"
        class="btn_back"
        @click.stop="$emit('onClickBack')">
        <IconSvg iconName="back" :size="24" :icoSize="16" iconColor="#232323"/>
      </button>
      <h1
        v-if="title"
        class="tit_head"
        :style="{color:titleColor}">{{ title }}</h1>
      <Pagination v-if="paginationData" :paginationData="paginationData"/>
      <div
        v-if="isCloseBtn"
        class="area_right">
        <button
          class="btn_close"
          @click.stop="$emit('onClickClose')">
          <IconSvg iconName="cross" :size="24" :icoSize="14" iconColor="#232323"/>
        </button>
      </div>
    </div>
    <slot name="headBottom"/>
  </div>
</template>

<script>
import Pagination from '@/components/layout/Pagination';
import IconSvg from '@/components/common/icon/IconSvg';
export default {
  name: 'Header',
  components:{
    Pagination,
    IconSvg
  },
  props:{
    isBackBtn: Boolean,
    title: String,
    titleColor: String,
    paginationData: Object,
    isCloseBtn: Boolean,
  }
}
</script>

<style scoped>
#lemontreeHead{position:relative;z-index:100;flex:0 1 54px;box-sizing:border-box;background-color:var(--bgColor)}
#lemontreeHead .inner_head {
  padding-left: 20px;
  padding-right: 20px;
  /* border-bottom: 1px solid #e7e5e5; */
  /* 현재 구조에서 좀 애매해서, 이건 외부에서 주겠다. */
  box-sizing: border-box;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#lemontreeHead .tit_head {
  display: block;
  /* padding-left: 8px; */
  text-align: center;

  /* width: 100%; */
  color: var(--font-font-black, #111);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

#lemontreeHead .btn_back,
#lemontreeHead .btn_close{position:absolute;top:12px;}
#lemontreeHead .btn_back{left:17px;width:30px;height:30px}
#lemontreeHead .btn_close{right:17px;width:30px;height:30px}
#lemontreeHead .pagination_comm{position:absolute;bottom:2px;left:20px;right:20px}
</style>