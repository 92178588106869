var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiPath from '@/services/apiPath';
import { GET_REQUEST } from '@/services/http';
import MapViewModelBase from '@/views/worldMap/viewModel/MapViewModelBase';
var StepMapViewModel = /** @class */ (function (_super) {
    __extends(StepMapViewModel, _super);
    function StepMapViewModel(mapUid, mapType) {
        var _this = _super.call(this, mapUid) || this;
        _this.mapRefsData = {};
        // this.mapUIRefsData = {};
        _this.horizonData = {
            topSafeAreaMarginTop: 3,
            topMapShowAreaHeight: 54,
            mapInfoMarginTop: 12,
            mapInfoHeight: 0,
            mapInfoMarginBottom: 44, // 맵 정보 박스 하단 여백
        };
        return _this;
    }
    Object.defineProperty(StepMapViewModel.prototype, "isMapTypeSponsor", {
        get: function () {
            return this.model.contentData.MAP.map_type === 'SPONSOR';
        },
        enumerable: false,
        configurable: true
    });
    // 퀘스트 완료할 때마다, 스폰서 맵 남은 상금 호출 하겠다.
    StepMapViewModel.prototype.onClearQuest = function () {
        if (this.isMapTypeSponsor) {
            this.getRemainAmount();
        }
    };
    // 퀘스트 완료 팝업 실행
    StepMapViewModel.prototype.questClearPopupOpen = function () {
        var _this = this;
        var popupParams = {
            name: 'QuestClearPopup',
            isActive: true,
            direct: 'null',
            type: 'full',
            bgColor: 'WHITE',
            isDimmedClose: false,
            closeCallBack: undefined,
        };
        this.popupModel.setPopupData(popupParams);
        this.historyBackEvent = function () {
            _this.onClickCloseQuizPopup();
        };
    };
    StepMapViewModel.prototype.initWorldMap = function () {
        this.setFirstLoadCheckList('isLoadedPlaceFocus', true);
    };
    StepMapViewModel.prototype.setPositionForce = function () {
        var params = {
            place: this.model.contentData.PLACE_DETAIL.place,
            isSmooth: false,
            scale: 75,
            duration: 100,
        };
        this.setPosition(params);
        // this.loadFinish();
    };
    // checked_reward 값 강제로 바꿔주려고 오버라이딩.
    StepMapViewModel.prototype.getUserStatus = function () {
        var _this = this;
        if (this.firstLoadCheckList.isLoadFailed)
            return;
        if (!this.mapUid)
            return;
        // const path = apiPath.USER_STATUS;
        var path = "".concat(apiPath.USER_STATUS.replace('%s', this.mapUid));
        return GET_REQUEST(path).then(function (success) {
            var resultData = success.data.body;
            // 강제!!! 스폰서맵은 보상 여부 무조건 true.
            resultData.user_status.checked_reward = true;
            _this.model.setUserStatus(resultData);
            return resultData;
        }, function (failed) {
            _this.firstLoadCheckList.isLoadFailed = true;
            // store.dispatch('commonToast/fetchToastStart', failed.msg);
            return null;
        });
    };
    StepMapViewModel.prototype.stageUpAnimationMotion = function () {
        // 0. 기존 열려있는 팝업닫기
        this.onClickClosePopup();
        this.isOpenControal = false;
        var moveToNextLevelAreaData = this.getMoveToNextLevelAreaData();
        if (!moveToNextLevelAreaData) {
            return;
        }
        var scale = moveToNextLevelAreaData.scale, rest = __rest(moveToNextLevelAreaData, ["scale"]);
        // scale을 제외한 나머지 모든 값을 넘겨준다.
        this.setPosition(rest);
    };
    // 지도 가운데 좌표 찾기
    StepMapViewModel.prototype.getCenterPosition = function (obj) {
        if (!obj.place.id) {
            return { top: 0, left: 0 };
        }
        var placeRefName = "place_".concat(obj.place.id);
        var params = {
            topHeightPercent: obj.topHeightPercent || 100,
        };
        var scaleY = this.mapRefsData[placeRefName][0].parentElement.offsetTop;
        var isUserOn = this.model.contentData.PLACE.random_place && (this.model.contentData.PLACE.random_place.id === obj.place.id);
        var scaleH = 112 + (isUserOn ? 38 : 0); // 장소항목 높이
        var viewHeight = this.getWindowHeight * (params.topHeightPercent / 100);
        var overflowHeight = 0;
        if (viewHeight < scaleH + 120) { /* 장소항목 위아래 여백의 합 120 */
            overflowHeight = (scaleH - viewHeight) / 2 + 60;
        }
        var _a = this.horizonData, mapInfoMarginTop = _a.mapInfoMarginTop, mapInfoHeight = _a.mapInfoHeight, mapInfoMarginBottom = _a.mapInfoMarginBottom;
        var top = scaleY - viewHeight / 2 + scaleH / 2 - overflowHeight + mapInfoMarginTop + mapInfoHeight + mapInfoMarginBottom;
        this.testBorderData = { y: scaleY, x: 0, w: 106, h: scaleH };
        this.testSpotData = { y: top, x: 0 };
        return { top: top, left: 0 };
    };
    return StepMapViewModel;
}(MapViewModelBase));
export default StepMapViewModel;
