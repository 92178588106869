<template>
  <div id="quizWrap">
    <div class="inner_quiz">
      <div id="quizHeader">
        <div class="inner_head">
          <h1
          v-if="title"
          class="tit_head">{{ title }}</h1>
          <button
            class="btn_close"
            @click.stop="$emit('onClickClose')">
            <IconSvg iconName="cross" :icoSize="14" iconColor="#232323"/>
          </button>
        </div>
        <Pagination
          v-if="paginationData.page"
          :quizPageBg="quizPageBg"
          :paginationData="paginationData"
          :selectedAnswerList="selectedAnswerList"/>
      </div>
      <div
        id="quizContent"
        :class="{ cont_middle : isContMiddle }"
        :style="cssProps"
        @click="isGoNextPageSelf ? $emit('onClickSelf') : ''">
        <!-- @mousedown="onSwipeStart"
        @touchstart="onSwipeStart"
        @mousemove="onSwipeMove"
        @touchmove="onSwipeMove"
        @mouseup="onSwipeEnd"
        @mouseleave="onSwipeEnd"
        @touchend="onSwipeEnd"
        @touchcancle="onSwipeEnd" -->
        <!-- <div class="group_inner_cont"
        ref="groupQuizContent">
          <div class="curren_page" :style="{paddingBottom : `${isShowAnswer ? quizFooterHeight : 0}px`}"> -->
        <slot/>
        <div
          v-if="quizContent && isShowAnswer"
          id="quizFooter"
          class="footer_quiz"
          ref="quizFooter">
          <div v-if="isAnswerClickSelf" class="cover_footer_quiz" @click.stop="$emit('onClickSelf')"></div>
          <!-- @click="isAnswerClickSelf ? $emit('onClickSelf') : ''" -->
          <Answer
            key="Answer"
            :isActive="isShowAnswer"
            :quizPageBg="quizPageBg"
            :quizContentType="quizContent.quiz_content_type"
            :options="quizContent.options"
            :quizId="quizContent.id"
            :selectedAnswerItem="selectedAnswerItem"
            :answerHeight.sync="quizFooterHeight"
            @onClickAnswer="(quizId,answer) => $emit('onClickAnswer',quizId,answer)"/>
        </div>
          <!-- </div> -->
          <!-- <div class="next_page" :style="{paddingBottom : `${nextSwipeQuizPageMode === 'Question' || nextSwipeQuizPageMode === 'Result' ? quizFooterHeight : 0}px`}">
            <slot name="nextPage"/>
            <div
              v-if="nextSwipeQuizContent && ( nextSwipeQuizPageMode === 'Question' || nextSwipeQuizPageMode === 'Result' )"
              id="quizFooter"
              :class="['footer_quiz',{ disabled : Boolean(selectedAnswerItem.user_answer) }]"
              ref="quizFooter"
              @click.capture="isGoNextPageSelf && nextSwipeQuizPageMode === 'Result' ? $emit('onClickSelf') : ''">
              <Answer
                key="Answer"
                :isActive="nextSwipeQuizPageMode === 'Question' || nextSwipeQuizPageMode === 'Result'"
                :quizPageBg="nextSwipeQuizPageBg"
                :quizContentType="nextSwipeQuizContent.quiz_content_type"
                :options="nextSwipeQuizContent.options"
                :selectedAnswerItem="selectedAnswerItem"
                :answerHeight.sync="quizFooterHeight"
                @onClickAnswer="answer => $emit('onClickAnswer',answer)"/>
            </div>
          </div> -->
        <!-- </div> -->
      </div>
      <slot name="popup" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/layout/Pagination';
import IconSvg from '@/components/common/icon/IconSvg';
import Answer from '@/views/quiz/view/components/Answer';
import { mapGetters } from 'vuex';

export default {
  name: 'QuizLayout',
  components:{
    Pagination,
    IconSvg,
    Answer
  },
  props:{
    quizPageBg : String, // bg
    paginationData: Object, // 페이징

    quizPageMode: String, // 페이지 모드
    quizContent: Object, // 현재 퀴즈 데이터
    isTypingComment: Boolean, // 해설 타이핑 완료 유무

    selectedAnswerItem: Object, // 선택한정답
    selectedAnswerList: Array, // 정답 목록
  },
  data(){
    return{
      quizFooterHeight: 0,


      initPoint: null, // 최초 드레그 진입 지점
      swipePoint: -200, // 열리는 swipe값
    }
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowWidth', 'getWindowHeight' ]),
    title(){
      if(this.quizPageMode === 'Lesson'){
        if(this.paginationData.page === 0){
          return this.$poData.quiz_lesson_intro_title;
        }else{
          return this.$poData.quiz_lesson_intro_title;
        }
      }else{
        return ''
      }
    },
    isContMiddle(){
      return this.quizPageMode != 'Lesson'
    },
    isShowAnswer(){
      return this.quizPageMode === 'Question' || this.quizPageMode === 'Result'
    },
    isGoNextPageSelf(){
      return this.quizPageMode === 'Result' || ( this.quizPageMode === 'Explanation' && !this.isTypingComment )
    },
    isAnswerClickSelf(){
      return this.quizPageMode === 'Result'
    },
    cssProps(){
      return{
        paddingBottom : `${this.isShowAnswer ? this.quizFooterHeight : 0}px`,
        // '--windowWidth' : `${this.getWindowWidth}px`,
        // '--windowWidthx2' : `${this.getWindowWidth * 2}px`
      }
    },
  },
  methods:{
    onSwipeMove(e){
      if(this.initPoint != null && this.isGoNextPageSelf){
        // console.log('onSwipeMove',e)
        const xPoint =  e.clientX || e.changedTouches[0].clientX;
        const dragPoint = xPoint - this.initPoint;

        // 열리는 포인트까지 드레그시 moving 종료
        if(!this.isSwipe && dragPoint <= this.swipePoint){
          this.onSwipeEnd(e);
          return;
        }
        if(dragPoint <= 0){
          const translateX = `${dragPoint}px`;
          this.$refs.groupQuizContent.style.transform = `translate(${ translateX },0)`;
        }
      }
    },
    onSwipeStart(e){
      if(this.isGoNextPageSelf){
        // console.log('onSwipeStart',e)
        this.initPoint = e.clientX || e.changedTouches[0].clientX;
      }
    },
    onSwipeEnd(e){
      if(this.initPoint != null && this.isGoNextPageSelf){
        const translateX = `${this.getWindowWidth}px`;
        // console.log('onSwipeEnd',e)
        const xPoint =  e.clientX || e.changedTouches[0].clientX;
        const dragPoint = xPoint - this.initPoint;

        // 열리는 포인트까지 드레그시 moving 종료
        if(!this.isSwipe && dragPoint <= this.swipePoint){
          this.$refs.groupQuizContent.style.transform = `translate(${ translateX },0)`;
          this.$emit('onClickSelf')
        }

        this.initPoint = null;
        this.$refs.groupQuizContent.style.transform = `translate(0,0)`;
      }
    },
  }
}
</script>

<style scoped>
#quizWrap{
  height:100%;
  /* background:#499CCB url('/assets/images/map/bg_map_active.png') no-repeat 60% 50%;
  background-size:auto 120%; */
  box-sizing:border-box;
  text-align:left;
}
.inner_quiz{
  display:flex;
  flex-direction:column;
  height:100%;
  /* padding-top:constant(safe-area-inset-top);
  padding-top:env(safe-area-inset-top); */
  /* background-color:rgba(17,17,17,.9); */
  box-sizing:border-box;
}
#quizHeader{position:relative;z-index:100;flex:0 1 54px;padding:0 20px 0;box-sizing:border-box}
#quizHeader .tit_head{display:inline-block;font-weight:600;font-size:16px;line-height:54px;vertical-align:top}
#quizHeader .inner_head{text-align:center}
#quizHeader .btn_close{position:absolute;top:12px;right:17px;width:30px;height:30px}
#quizHeader .btn_close .icon_cross{margin:8px}
#quizHeader .pagination_comm{margin-top:54px}
#quizContent{
  flex:1 1;
  width:100%;
  overflow-y:auto;
  z-index:1;
}
#quizContent.cont_middle{font-size:0;white-space:nowrap}
#quizContent.cont_middle:after{display:inline-block;height:100%;vertical-align:middle;min-height:inherit;content:''}
#quizContent.cont_middle >>> .inner_cont{display:inline-block;overflow-y:auto;width:100%;font-size:20px;vertical-align:middle;white-space:normal;box-sizing:border-box}
#quizContent >>> .inner_cont.disabled{opacity:0;position:absolute;z-index:-1}
#quizContent >>> .inner_cont.active{opacity:1;position:static}

#quizFooter{
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  z-index:2;
  /* padding-bottom:constant(safe-area-inset-bottom);
  padding-bottom:env(safe-area-inset-bottom); */
}
.cover_footer_quiz{position:absolute;left:0;right:0;top:0;bottom:0;z-index:1}
/* #quizFooter.disabled{z-index:-1} */


/* .group_inner_cont,
.curren_page,
.next_page{height:100%}

.group_inner_cont{overflow:hidden;width:var(--windowWidthx2)}
.curren_page,
.next_page{float:left;position:relative;width:var(--windowWidth);box-sizing: border-box;} */

/* #quizContent.cont_middle .curren_page{font-size:0;white-space:nowrap}
#quizContent.cont_middle .curren_page:after{display:inline-block;height:100%;vertical-align:middle;min-height:inherit;content:''}
#quizContent.cont_middle .curren_page >>> .inner_cont{display:inline-block;width:100%;font-size:20px;vertical-align:middle;white-space:normal;box-sizing:border-box}

#quizContent .next_page{font-size:0;white-space:nowrap}
#quizContent .next_page:after{display:inline-block;height:100%;vertical-align:middle;min-height:inherit;content:''}
#quizContent .next_page >>> .inner_cont{display:inline-block;width:100%;font-size:20px;vertical-align:middle;white-space:normal;box-sizing:border-box} */
</style>