<template>
  <div
    :class="[
      'group_answer',
      groupAnswerType,
      isActive ? 'active':'disabled'
    ]"
    :style="cssProps"
    ref="groupAnswer">
    <div
      :class="[
        'item_answer',
        selectedAnswerItem.user_answer && ((option === selectedAnswerItem.quiz_answer) || isAll) ? 'item_answer_correct' : 'item_answer_wrong',
        selectedAnswerItem.user_answer ? ( option === selectedAnswerItem.user_answer  || isAll ? 'active' : 'disabled' ) : ''
      ]"
      v-for="(option,index) in options"
      :key="`option${index}`">
      <button
        :class="['btn_answer']"
        :disabled="Boolean(selectedAnswerItem.user_answer)"
        @click.stop="$emit('onClickAnswer',quizId,option)">
        <IconSvg v-if="isOX" :iconName="option" :icoSize="option === 'O' ? 33 : 30" :iconColor="option === 'O' ? '#579BF5' : '#F98E5A'"/>
        <span v-else class="txt_answer">{{ option }}</span>
      </button>
      <div v-if="selectedAnswerItem.user_answer && (option === selectedAnswerItem.quiz_answer || isAll )" class="badge_answer">
        <div class="txt_badge">{{ $poData.quiz_explanation_answer_badge }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name: 'Answer',
  props:{
    quizPageBg: String,
    isActive: Boolean,
    quizContentType: String,
    quizId: [Number, String],
    options: Array,
    selectedAnswerItem: Object,
    answerHeight: Number,
  },
  components:{
    IconSvg,
  },
  mounted(){
    this.getAnswerHeight();
  },
  computed:{
    isOX(){
      return this.quizContentType === 'OX';
    },
    isAll(){
      return this.quizContentType === 'AUTOCHECK' || this.quizContentType === 'ALL';
    },
    groupAnswerType(){
      if(this.isOX){
        return 'group_answer_half';
      }else{
        return 'group_answer_full';
      }
    },
    bgColorHex(){
      if(!this.quizPageBg) return'';
      switch(this.quizPageBg){
        case 'PRIMARY' : {
          return '#8F69DD';
        }
        case 'PRIMARY200' : {
          return 'linear-gradient(180deg, rgba(190, 169, 237, 0) 0%, #BEA9ED 23.44%, #BEA9ED 100%)';
        }
        case 'SECONDARY' : {
          return 'linear-gradient(180deg, rgba(241, 238, 225, 0) 0%, #F1EEE1 8.33%';
        }
        case 'SECONDARY100' : {
          return 'linear-gradient(180deg, rgba(251, 174, 138, 0) 0%, #FBAE8A 23.44%, #FBAE8A 100%)';
        }
        case 'WHITE' : {
          return '#FFF';
        }
      }
    },
    cssProps(){
      let rObj = {
        '--bgColor' : this.bgColorHex
      }
      return rObj;
    }
  },
  methods:{
    getAnswerHeight(){
      const answerHeight = this.$refs.groupAnswer.offsetHeight;
      this.$emit('update:answerHeight',answerHeight);
    }
  }
}
</script>

<style scoped>
@keyframes badgeAnswerShow {
  0%{
    transform:scale(0)
  }
  100%{
    transform:scale(1)
  }
}

.group_answer.disabled{position:absolute;top:100%;opacity:0}
.group_answer.active{opacity:1}

.group_answer{display:flex;flex-wrap:wrap;width:100%;padding:34px 20px 32px;background:var(--bgColor);box-sizing:border-box}
.item_answer{position:relative;flex-shrink:1;flex-grow:1;}
.group_answer_half .item_answer + .item_answer{margin-left:16px}
.group_answer_full .item_answer + .item_answer{margin-top:16px}
.group_answer_full .item_answer{width:100%}

.btn_answer{display:block;width:100%;border-radius:4px;border:1px solid #D8CBF4;text-align:center;background-color:#fff;}
.btn_answer .txt_answer{display:inline-block;font-weight:700;font-size:18px;line-height:28px;word-wrap:break-word;word-break:keep-all}

.group_answer_half .btn_answer{height:64px}
.group_answer_full .btn_answer{padding:18px 17px}

.btn_answer >>> .icon_lemontree g,
.item_answer.disabled .btn_answer{transition:0.5s}

/* 맞았을때 정답 버튼 */
.item_answer_correct.active .btn_answer{border-color:#8F69DD;background-color:#8F69DD;color:#fff}
.item_answer_correct.active .btn_answer >>> .icon_lemontree g{fill:#fff}
/* 맞았을때 오답 버튼 */
/* .item_answer_wrong.disabled .btn_answer{border-color:#A6A4A4;background-color:#A6A4A4;color:#fff} */

/* 틀렸을때 정답 버튼 */
/* .item_answer_correct.disabled .btn_answer{border-color:#8F69DD} */
/* 틀렸을때 오답 버튼 */
.item_answer_wrong.active .btn_answer{border-color:#A6A4A4;background-color:#A6A4A4;color:#fff}
.item_answer_wrong.active .btn_answer >>> .icon_lemontree g{fill:#fff}

.item_answer .badge_answer{position:absolute;padding:4px 8px;background-color:#F98E5A;border-radius:2px;-webkit-tap-highlight-color: rgba(0,0,0,0);transform:scale(0);
  animation-name: badgeAnswerShow;
  animation-duration:0.1s;
  animation-delay:0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
/* .group_answer_half .badge_answer{top:-19px;left:50%;margin-left:-44px} */
.item_answer .badge_answer{top:-12px;right:12px}
.item_answer .badge_answer .icon_check{margin:5px 5px 6px 0}
.item_answer .badge_answer .txt_badge{font-weight:600;font-size:12px;line-height:16px;color:#111}

/* 높이 640 이하 디바이스 */
/* .short_device .group_answer_half .btn_answer{padding:12px 17px}
.short_device .group_answer_full .btn_answer{padding:13px 22px}
.short_device .group_answer_half .badge_answer{top:-20px;margin-left:-36px}
.short_device .item_answer .badge_answer{padding:4px 16px 0px 12px;font-size:14px;line-height:28px}
.short_device .item_answer .badge_answer .txt_badge{top:2px} */
</style>