<template>
  <div class="lock_container">
    <div class="back"></div>
    <button
      class="btnUnlock"
      type="button"
      @click="$emit('openUnlockBottomSheet')"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2842_1486)">
          <path d="M20 10.3252H4V22H20V10.3252Z" fill="white" />
          <path
            d="M11.9992 16.9253C8.89277 16.9253 6.36566 14.2912 6.36566 11.0533V7.87042C6.36717 4.63406 8.89428 2 11.9992 2C15.1042 2 17.6328 4.63406 17.6328 7.87199V11.0549C17.6328 14.2928 15.1057 16.9269 11.9992 16.9269V16.9253ZM11.9992 3.73612C9.81158 3.73612 8.0328 5.59019 8.0328 7.87042V11.0533C8.0328 13.3335 9.81158 15.1876 11.9992 15.1876C14.1869 15.1876 15.9657 13.3335 15.9657 11.0533V7.87042C15.9657 5.59019 14.1869 3.73612 11.9992 3.73612Z"
            fill="white"
          />
          <path
            d="M12.5997 16.6627H11.4018V17.9113H12.5997V16.6627Z"
            fill="#232323"
          />
          <path
            d="M11.9992 16.9756C12.665 16.9756 13.2047 16.4131 13.2047 15.7191C13.2047 15.0252 12.665 14.4626 11.9992 14.4626C11.3335 14.4626 10.7938 15.0252 10.7938 15.7191C10.7938 16.4131 11.3335 16.9756 11.9992 16.9756Z"
            fill="#232323"
          />
        </g>
        <defs>
          <clipPath id="clip0_2842_1486">
            <rect
              width="16"
              height="20"
              fill="#232323"
              transform="translate(4 2)"
            />
          </clipPath>
        </defs>
      </svg>
      <span>{{ $poData.worldmap_locked }}</span>
    </button>
  </div>
</template>
<script>
export default {
  name: 'Lock',
};
</script>

<style scoped>
.lock_container {
  z-index: var(--z-index-map-lock);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  pointer-events: none;

  background-color: rgba(0, 0, 0, 0);
}

.btnUnlock {
  display: inline-flex;
  padding: 14px 20px 14px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 100px;
  border: 6px solid var(--neutrals-white, #fff);
  background: var(--secondary-black-200, #232323);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.24);

  color: var(--neutrals-white, #fff);

  font-size: 18px;
  font-weight: 700;
  line-height: 28px; /* 155.556% */

  z-index: 1;

  pointer-events: auto;
}

button svg {
  width: 34px;
  height: 34px;
  /* fill: #ffffff; */
}
</style>
