<template>
  <PopupLayout>
    <QuizLayout
      :class="`quiz_${quizPageMode.toLowerCase()}`"
      :quizPageBg="model.quizPageBg"
      :paginationData="model.paginationData"

      :quizPageMode="quizPageMode"
      :quizContent="model.currentQuizData"
      :isTypingComment="viewModel.isTypingComment"

      :selectedAnswerItem="model.selectedAnswerItem"
      :selectedAnswerList="model.selectedAnswerList"
      @onClickSelf="viewModel.goNextPage()"
      @onClickClose="onClickBackKey()"
      @onClickAnswer="(quizId,answer) => viewModel.onClickAnswer(quizId,answer)">
      <Lesson
        v-if="quizPageMode === 'Lesson'"
        :lessonData="model.questData.lesson"
        @goNextPage="viewModel.goNextPage()"/>
      <Intro
        v-if="quizPageMode === 'Intro'"
        :paginationData="model.paginationData"
        :quizContent="model.currentQuizData"
        @goNextPage="viewModel.goNextPage()"/>
      <Question
        v-if="quizPageMode === 'Question'"
        :quizContent="model.currentQuizData"/>
      <Result
        v-if="quizPageMode === 'Result'"
        :quizContentType="model.currentQuizData.quiz_content_type"
        :selectedAnswerItem="model.selectedAnswerItem"
        :rewardData="model.rewardData"
        :isForceHideMoneyReward="isForceHideMoneyReward"
        @goNextPage="viewModel.goNextPage()"/>
      <Explanation
        v-if="quizPageMode === 'Explanation'"
        :isTypingComment.sync="viewModel.isTypingComment"
        :selectedAnswerItem="model.selectedAnswerItem"
        @goNextPage="viewModel.goNextPage()"/>
    </QuizLayout>
  </PopupLayout>
</template>
<script>
import PopupLayout from '@/components/layout/popup/PopupLayout'
import QuizLayout from '@/views/quiz/view/components/QuizLayout';
import Lesson from '@/views/quiz/view/components/Lesson';
import Intro from '@/views/quiz/view/components/Intro';
import Question from '@/views/quiz/view/components/Question';
import Result from '@/views/quiz/view/components/Result';
import Explanation from '@/views/quiz/view/components/Explanation';

// mixin
import InterFaceMixin from '@/mixins/InterFaceMixin';
// viewModel
import QuizViewModel from '@/views/quiz/viewModel/QuizViewModel'
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Quiz',
  mixins:[ InterFaceMixin ],
  components:{
    PopupLayout,
    QuizLayout,
    Lesson,
    Intro,
    Question,
    Result,
    Explanation,

  },
  props:{
    userData: Object,
    quizData: Object,
    questData: Object,
    isForceHideMoneyReward: {
      type: Boolean,
      default: false,
    }, // TODO. 개선 필요..
    isFromWeb: {
      type: Boolean,
      default: false,
    }
  },
  beforeMount(){
    this.viewModel.init();
    this.viewModel.getQuizList(this.questData, this.quizData);
    this.viewModel.setRewardData(this.userData)
    this.viewModel.userData = this.userData;
    // 퀴즈 팝업 업데이트
    const onUpdatePopupData = (popupUpdateData) => { this.$emit('onUpdatePopupData',popupUpdateData); }
    this.viewModel.onUpdatePopupData = onUpdatePopupData;
    // 퀴즈 종료
    const questClear = () => { this.$emit('questClear',this.viewModel.quizPageModel.selectedAnswerItem); }
    this.viewModel.questClear = questClear;
    // 퀴즈 종료 후 닫기
    const questClearNoPopup = () => { this.$emit('questClearNoPopup',this.viewModel.quizPageModel.selectedAnswerItem); }
    this.viewModel.questClearNoPopup = questClearNoPopup;    
    // 월드맵으로 이동
    const goToWorldMap = () => { this.$emit('goToWorldMap'); }
    this.viewModel.goToWorldMap = goToWorldMap;
    
  },
  data(){
    return{
      viewModel: new QuizViewModel(this.isForceHideMoneyReward),
    }
  },
  computed:{
    ...mapGetters('commonInterface', [ 'getIsBackKeyEvent' ]),
    model(){
      return this.viewModel.quizPageModel;
    },
    quizPageMode(){
      return this.viewModel.quizPageModel.quizPageMode
    }
    
  },
  methods:{
    ...mapActions('commonAlert', [ 'fetchAlertStart' ]),
    onClickBackKey(){
      const { currentQuizData, selectedAnswerList, accumulated_point } = this.viewModel.quizPageModel;
      if(currentQuizData.id === 'TUTORIAL'){
        const webEnd = () => {
          // this.webEnd();
          this.goBack(this.isFromWeb);
        }
        this.fetchAlertStart({
          alertMsg: `<strong>퍼핀월드 안내를 종료할까요?</strong>지금 아니면 다시는 못 봐요.<br>30초만 시간 내주시면 안 될까요? 🥺`,
          closeBtnText: '계속',
          compeleteBtnText: '종료하기',
          isBackEventCompelete: true,
          confirmCallBack: webEnd,
        });
        return
      }
      const selectAnswerListLength = selectedAnswerList.length;
      if(selectAnswerListLength === 0){
        this.viewModel.goToWorldMap();
        return;
      }
      if(selectAnswerListLength === 5){
        this.viewModel.questClearNoPopup();
        return;
      }
      const quitQuest = () => {
        this.viewModel.goToWorldMap();
      };

      this.fetchAlertStart({
        // 기존 스타일 들어가 있는 부분이 있어서, 일단 이렇게 처리.
        alertMsg: `<strong class='title_only'>${this.$poData.quest_drop_reconfirm_title}</strong>`,
        closeBtnText: this.$poData.quest_drop_reconfirm_cancel,
        compeleteBtnText: this.$poData.quest_drop_reconfirm_yes,
        isBackEventCompelete: true,
        confirmCallBack: quitQuest,
      });
    },
  },
}
</script>