<template>
  <PopupLayout
    :paddingTop="2"
    :isTemplate="true"
    :class="['inner_quest_clear_layer', { active: isMounted }]"
    :compeleteBtnText="$poData.quest_clear_btn"
    @onClickComplete="$emit('onClickComplete')"
  >
    <template v-slot:head>
      <p class="desc_answer_sum">
        {{
          $poData.place_quest_clear_right_answer_sum.format(
            quizAnswerData.total_quiz_cnt,
            quizAnswerData.accumulated_success_cnt || '0',
          )
        }}
      </p>
    </template>
    <div v-if="hasReward" class="group_box">
      <div class="row_box" :style="{ height: `${rowBoxSize}px` }">
        <div class="box_character img_container">
          <div class="inner_box_character">
            <img
              src="/assets/images/character/img_sponsored_quest_complete_reward.png"
              alt="퍼핀 캐릭터"
            />
          </div>
        </div>
        <div class="box_money img_container">
          <img src="/assets/icons/income-etc.svg" alt="돈 주머니" />
        </div>
      </div>
      <div
        class="box_reward_text"
        :style="{
          height: `${rowBoxSize * 0.4}px`,
          fontSize: `${rowBoxSize * 0.4 * 0.3}px`,
        }"
      >
        {{ rewardText }}
      </div>
    </div>
    <div v-else class="group_box noreward">
      <div class="row_box" :style="{ height: `${rowBoxSize}px` }">
        <div class="box_money img_container">
          <img src="/assets/icons/interest.svg" alt="돈 천사" />
        </div>
        <div class="box_character img_container">
          <div class="inner_box_character">
            <img
              src="/assets/images/sponsored/quest_clear_character.png"
              alt="퍼핀 캐릭터"
            />
          </div>
        </div>
      </div>
      <div
        class="box_reward_text"
        :style="{
          height: `${rowBoxSize * 0.4}px`,
          fontSize: `${rowBoxSize * 0.4 * 0.3}px`,
        }"
      >
        {{ rewardText }}
      </div>
    </div>
    <p class="desc_reward_sponsor" v-html="descRewardSponsor" />
    <template v-slot:foot_bottom>
      <p v-if="!hasReward" class="desc_reward_end">
        {{ $poData.sponsor_quest_clear_note_1 }}
      </p>
    </template>
    <div class="confetti area360">
      <Lottie :options="confettiLottieOption" />
    </div>
  </PopupLayout>
</template>
<script>
import PopupLayout from '@/components/layout/popup/PopupLayout';
import Lottie from 'vue-lottie';
import { addComma } from '@/utils/stringUtils';
import { mapGetters } from 'vuex';

export default {
  name: 'QuestClearSponsorInfoPopup',
  components: {
    PopupLayout,
    Lottie,
  },
  props: {
    questData: Object,
    sponsorName: String,
  },
  data() {
    return {
      isMounted: false,
      rowBoxSize: 200,
    };
  },
  computed: {
    ...mapGetters('commonViewport', ['getWindowWidth']),
    quizAnswerData() {
      return this.questData.last_answer;
    },
    descRewardSponsor() {
      if (this.hasReward) {
        return this.$poData.sponsor_quest_clear_body_1.format(this.sponsorName);
      }
      return this.$poData.sponsor_quest_clear_body_2;
    },
    hasReward() {

      if (!this.quizAnswerData.has_rewards) return false;

      return this.quizAnswerData.accumulated_point > 0;
    },
    rewardText() {
      if (this.hasReward) {
        return this.$poData.sponsor_quest_clear_total_reward_1.format(
          addComma(this.quizAnswerData.accumulated_point),
        );
      }

      return this.$poData.sponsor_quest_clear_total_reward_2;
    },
  },
  watch: {
    getWindowWidth(newVal, oldVal) {
      if (newVal != oldVal) {
        this.setRowBoxSize();
      }
    },
  },
  async mounted() {
    await this.$nextTick();
    this.setRowBoxSize();
    this.isMounted = true;
  },
  methods: {
    addComma(val) {
      return addComma(val);
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
    setRowBoxSize() {
      const maxWidth = 360;
      const windowWidth = this.getWindowWidth <= maxWidth ? this.getWindowWidth : maxWidth;
      this.rowBoxSize = (windowWidth - 40) * 0.625;
    },
  },
};
</script>

<style scoped>
.inner_quest_clear_layer >>> .inner_body {
  padding: 48px 20px;
  box-sizing: border-box;
  vertical-align: top !important;
}

.desc_answer_sum {
  color: var(--font-font-black, #111);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */

  transform: scale(0.1);
  transition: transform 0.3s ease-out;
}

.desc_reward_sponsor {
  padding-top: 48px;
  color: var(--font-font-300, #333);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */

  transform: scale(0.1);
  transition: transform 0.3s ease-out;
}

.inner_quest_clear_layer.active .desc_answer_sum,
.inner_quest_clear_layer.active .desc_reward_sponsor {
  transform: scale(1);
}

.group_box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 320px;
  margin: 0 auto;
}

.group_box .row_box {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.box_character {
  display: flex;
  padding: 11px 24px 0px 14px;
  align-items: center;
  border-radius: 20px;
  background: var(--primary-purple-200, #bea9ed);
  width: 62.5%;
  box-sizing: border-box;
}
.inner_box_character {
  position: relative;
  width: 100%;
  height: 100%;
}

.box_character img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
}

.noreward .box_character {
  background: none;
  padding: 0;
}

.noreward .box_character img {
  left: 0;
  right: 0;
}

.box_money {
  border-radius: 20px;
  background: var(--primary-purple-100, #d8cbf4);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.noreward .box_money {
  background: var(--secondary-orange-100, #fbae8a);
}

.box_money img {
  width: 64px;
  height: 64px;

  z-index: 1;
}

.box_reward_text {
  border-radius: 20px;
  background: #6cfffd;

  color: var(--font-font-black, #111);
  text-align: center;

  font-size: 24px;
  font-weight: 700;
  line-height: 30px; /* 125% */

  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noreward .box_reward_text {
  background: #3afb34;
}

.desc_reward_end {
  margin-top: 16px;
  color: var(--font-font-200, #666);
  text-align: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.confetti {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;
}

.short_device .inner_quest_clear_layer >>> .inner_body{
  padding: 24px 20px;
}
.short_device .box_reward_text {
  height: 70px;
}
.short_device .desc_reward_sponsor {
  padding-top: 24px;
}
</style>
