<template>
  <div class="cont_world" :style="contCssProps">
    <Map :viewModel="viewModel" />
    <!-- map UI -->
    <template
      v-if="viewModel.isShowMapUI || viewModel.firstLoadCheckList.isLoadFailed"
    >
      <!-- 상단 -->
      <div class="area_left">
        <button class="btn_back" @click.stop="onClickBackKey()">
          <IconSvg
            iconName="back"
            :size="24"
            :icoSize="16"
            iconColor="#232323"
          />
        </button>
      </div>
      <div v-if="!viewModel.firstLoadCheckList.isLoadFailed" class="area_right">
        <button
          class="btn_place_search"
          @click="viewModel.onClickPlaceSearchPopup()"
        >
          {{ $poData.worldmap_place_list }}
        </button>
      </div>
      <div
        v-if="!viewModel.firstLoadCheckList.isLoadFailed"
        class="area_bottom"
      >
        <MapUiBottomRewardInfo
          :rewardData="contentData.REWARD" />
      </div>
    </template>
    <PlaceSearchPoup
      :isActive="viewModel.isPlaceSearchPopup"
      :placeSearchData="contentData.PLACE_SEARCH"
      :userLevel="contentData.USER.stage"
      :isShowSearchBar="false"
      :isLocked="isLocked"
      @onClickPlaceSearchItem="
        (placeId, placeStage) =>
          viewModel.onClickPlaceSearchItem(placeId, placeStage)
      "
      @onSearch="keyWord => viewModel.placeSearchKeyword(keyWord)"
      @onClickClosePlaceSearchPopup="viewModel.onClickClosePlaceSearchPopup()"
    />
    <Popup
      :direct="viewModel.popupModel.direct"
      :type="viewModel.popupModel.type"
      :bgColor="viewModel.popupModel.bgColor"
      :isDragable="viewModel.popupModel.isDragable"
      :isDimmedClose="viewModel.popupModel.isDimmedClose"
      :isActive="viewModel.popupModel.isActive"
      @onClickClose="viewModel.onClickClosePopup()"
    >
      <!-- 튜토리얼 팝업 -->
      <Tutorial
        v-if="viewModel.popupModel.name === 'Tutorial'"
        :isFromWeb="viewModel.isFromWeb"
        @onUpdatePopupData="
          popupParams => viewModel.popupModel.updatePopupData(popupParams)
        "
        @onClickClosePopup="viewModel.onClickClosePopup()"
        @onClickQuiz="viewModel.onClickTutorialQuiz()"
      />
      <!-- 장소상세 팝업 -->
      <PlaceDetailPopup
        v-if="viewModel.popupModel.name === 'PlaceDetailPopup'"
        :rewardData="contentData.REWARD"
        :mapType="contentData.MAP.map_type"
        :mapUid="contentData.MAP.uid"
        :userData="contentData.USER"
        :courseData="contentData.COURSE"
        :questData="contentData.QUEST"
        :placeDetail="contentData.PLACE_DETAIL"
        @onClickQuiz="viewModel.onClickQuiz()"
      />
      <!-- 퀴즈 팝업 -->
      <Quiz
        v-if="viewModel.popupModel.name === 'Quiz'"
        ref="Quiz"
        :userData="contentData.USER"
        :quizData="contentData.QUIZ"
        :questData="contentData.QUEST"
        :isForceHideMoneyReward="true"
        :isFromWeb="viewModel.isFromWeb"
        @onUpdatePopupData="
          popupParams => viewModel.popupModel.updatePopupData(popupParams)
        "
        @questClear="answerData => viewModel.questClear(answerData)"
        @questClearNoPopup="
          answerData => viewModel.questClearNoPopup(answerData)
        "
        @goToWorldMap="viewModel.placeDetailPopup()"
      />
      <!-- 퀘스트 완료 팝업 -->
      <QuestClearPopup
        v-if="viewModel.popupModel.name === 'QuestClearPopup'"
        :questData="contentData.QUEST"
        :checked_reward="contentData.USER.checked_reward"
        :sponsorName="contentData.SPONSOR.name"
        @onClickClose="viewModel.onClickCloseTutorialPopup()"
        @onClickComplete="viewModel.onClickCloseQuizPopup()"
      />
      <!-- 장소 완료 팝업 -->
      <PlaceClearPopup
        v-if="viewModel.popupModel.name === 'PlaceClearPopup'"
        :placeDetail="contentData.PLACE_DETAIL.place"
        :clearPlaceData="contentData.PLACE_DETAIL.clear_data"
        :checked_reward="contentData.USER.checked_reward"
        :thumbnailImageUrl="placeClearPopupThumbnailImageUrl"
        @onClickComplete="viewModel.onClickClosePlaceClearPopup()"
      />
      <!-- 스테이지 업 팝업 -->
      <StageUpPopup
        v-if="viewModel.popupModel.name === 'StageUpPopup'"
        :userLevel="contentData.USER.stage"
        :newPlaceList="
          contentData.PLACE.total_list.find(
            item => item.stage === contentData.USER.stage,
          ).placeList
        "
        @onClickComplete="
          realIndex => viewModel.onClickCloseStageUpPopup(realIndex)
        "
      />
      <!-- 맵완료 팝업 -->
      <MapCompletePopup
        v-if="viewModel.popupModel.name === 'MapCompletePopup'"
        :image="contentData.MAP.completed_image_url || contentData.MAP.thumbnail_image_url"
        @onClickComplete="viewModel.onClickCloseMapCompletePopup()"
      />
    </Popup>
    <Lock v-if="isLocked" @openUnlockBottomSheet="openUnlockBottomSheet" />
    <div v-if="viewModel.mapUid && isShowUnlockBottomSheet" class="bottomSheet">
      <RequestUnlockBottomSheet
        v-if="contentData.MAP.authentication.has_leader_parents"
        :text="{
          title: $poData.send_unlock_request_reconfirm_title,
          description: $poData.send_unlock_request_reconfirm_desc,
          buttonYes: $poData.send_unlock_request_reconfirm_yes,
          buttonCancel: $poData.send_unlock_request_reconfirm_cancel,
        }"
        :mapUid="viewModel.mapUid"
        @onClickBack="closeUnlockBottomSheet()"
        @onClickClose="closeUnlockBottomSheet()"
        @onCompleteRequest="handleCompleteRequest()"
      />
      <RequestInvParentBottomSheet
        v-else
        :text="{
          title: '부모님이 해주셔야 하는 기능이에요.<br/>퍼핀앱 가입을 요청해보세요!',
          description: '퍼핀의 모든 기능을 이용하려면 부모님께 가족 등록을 요청해주세요.',
          buttonYes: '부모님께 문자로 가입요청',
          buttonCancel: '다음에 할게요',
        }"
        @onClickBack="closeUnlockBottomSheet()"
        @onClickClose="closeUnlockBottomSheet()"
      />
    </div>
    <div v-if="isShowRequestCompleteDialog" class="dialogComplete">
      <DialogBase
        :title="$poData.sent_request_success_popup_title"
        :isShowDimmed="true"
      >
        <!-- @onClickBack="handleClickCompleteDialog()" -->
        <Button
          btnSize="medium"
          btnStyle="secondary_tonal"
          :text="$poData.sent_request_success_popupp_btn"
          @onClickBtn="handleClickCompleteDialog()"
        />
      </DialogBase>
    </div>
    <template v-if="isDevMode">
      <!-- controal test -->
      <template v-if="viewModel.isShowMapUI">
        <DevTools :viewModel="viewModel" :isTestMode.sync="isTestMode" />
        <div v-if="viewModel.isTestResultPopup" class="test_result_popup">
          <button @click="viewModel.onClickCloseTestPopup()">X</button>
          <div
            class="inner_test_result_popup"
            v-html="viewModel.testResult"
          ></div>
        </div>
      </template>
      <!-- test action dimmed -->
      <div v-if="viewModel.isAction" class="dimmed_action">
        <p v-if="isTestMode">ACTION</p>
      </div>
    </template>
  </div>
</template>
<script>
// viewModel
import SponsoredMapViewModel from '@/views/sponsoredMap/viewModel/SponsoredMapViewModel';
import { mapGetters } from 'vuex';

// mixin
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';

import PlaceSearchPoup from '@/views/worldMap/view/popup/PlaceSearchPoup';

import Map from '@/components/shared/map/Map.vue';

import IconSvg from '@/components/common/icon/IconSvg';

import DevTools from '@/views/worldMap/view/DevTools.vue';

import Quiz from '@/views/quiz/view/Quiz';
import Popup from '@/components/layout/popup/Popup';

import PlaceDetailPopup from '@/views/worldMap/view/popup/PlaceDetailPopup';
import StageUpPopup from '@/views/worldMap/view/popup/StageUpPopup';
import QuestClearPopup from '@/components/shared/popup/QuestClearSponsorInfoPopup';
import PlaceClearPopup from '@/views/worldMap/view/popup/PlaceClearPopup';
import Lock from '@/components/layout/Lock.vue';
import MapUiBottomRewardInfo from '@/components/shared/mapUi/MapUiBottomRewardInfo.vue';
import MapCompletePopup from '@/components/shared/popup/MapCompletePopup.vue';

import RequestUnlockBottomSheet from '@/components/shared/bottomSheet/RequestUnlockBottomSheet.vue';
import RequestInvParentBottomSheet from '@/components/shared/bottomSheet/RequestInvParentBottomSheet.vue';
import DialogBase from '@/components/layout/dialog/DialogBase.vue';
import Button from '@/components/common/button/Button';

export default {
  name: 'SponsoredMap',
  components: {
    Map,
    PlaceSearchPoup,
    IconSvg,
    DevTools,
    Quiz,
    Popup,
    PlaceDetailPopup,
    StageUpPopup,
    QuestClearPopup,
    PlaceClearPopup,
    Lock,
    MapUiBottomRewardInfo,
    MapCompletePopup,
    RequestUnlockBottomSheet,
    RequestInvParentBottomSheet,
    DialogBase,
    Button,
  },
  mixins: [InterFaceMixin, BackKeyEventMixin],
  data() {
    return {
      viewModel: new SponsoredMapViewModel(this.$route.query.mapUid),
      isTestMode: false,
      isTestResultPopup: false,
      isShowUnlockBottomSheet: false,
      isShowRequestCompleteDialog: false,
    };
  },
  computed: {
    ...mapGetters('commonViewport', [
      'getWindowWidth',
      'getWindowHeight',
      'getSafeAreaTopHeight',
      'getSafeAreaBottomHeight',
    ]),
    ...mapGetters('map', ['isLocked']),
    contCssProps() {
      var cssObj = {};
      cssObj = {
        '--safeAreaTopHeight': `${this.getSafeAreaTopHeight + 3}px`,
        '--safeAreaTopHeightControal': `${this.getSafeAreaTopHeight + 80}px`,
        '--safeAreaBottomHeight': `${this.getSafeAreaBottomHeight + 24}px`,
      };
      if (this.isDevMode) {
        cssObj['--controalTestMaxHeight'] = `${this.getWindowHeight -
          220 -
          this.getSafeAreaTopHeight}px`;
      }
      return cssObj;
    },
    contentData() {
      return this.viewModel.model.contentData;
    },
    isDevMode() {
      return process.env.NODE_ENV != 'production';
    },
    placeClearPopupThumbnailImageUrl() {
      return this.viewModel.getThumbnailImageUrl(
        this.contentData.PLACE_DETAIL.place.id,
      );
    },
  },
  methods: {
    onClickBackKey() {
      if (this.viewModel.popupModel.name != 'Quiz') {
        if (this.viewModel.historyBackEvent) {
          this.viewModel.historyBackEvent();
        } else {
          // this.webEnd();
          this.goBack(this.viewModel.isFromWeb);
        }
      } else {
        this.$refs.Quiz.onClickBackKey();
      }
    },
    // 테스트 팝업
    onClickCloseTestPopup() {
      this.isTestResultPopup = false;
    },
    openUnlockBottomSheet() {
      this.isShowUnlockBottomSheet = true;
    },
    closeUnlockBottomSheet() {
      this.isShowUnlockBottomSheet = false;
    },
    handleCompleteRequest() {
      this.isShowRequestCompleteDialog = true;
      this.isShowUnlockBottomSheet = false;
    },
    handleClickCompleteDialog() {
      this.isShowRequestCompleteDialog = false;
    },
  },
};
</script>

<style scoped>
.cont_world {
  width: 100%;
  height: 100%;
  background: #64B471;
}

/* area_left */
.area_left {
  position: fixed;
  top: var(--safeAreaTopHeight);
  left: 20px;
  z-index: 100;
}
.btn_back {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: #fff;
}
/* area_right */
.area_right {
  position: fixed;
  top: var(--safeAreaTopHeight);
  right: 20px;
  z-index: 100;
}
.btn_place_search {
  height: 48px;
  padding: 12px 16px;
  border-radius: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background-color: #fff;
  color: #000;
}

.test_result_popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding-top: 30px;
  overflow-y: auto;
  background-color: #000;
  color: #fff;
}
.test_result_popup .inner_test_result_popup {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  word-wrap: break-word;
  word-break: keep-all;
}
.test_result_popup button {
  position: fixed;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: #fff;
  color: #000;
}

/* dimmed_action */
.dimmed_action {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
}
/* ;background-color:rgba(0,0,0,.6) */
.dimmed_action p {
  display: inline-block;
  padding: 8px;
  background-color: #f00;
  color: #fff;
  vertical-align: top;
}

.area_bottom {
  position: fixed;
  bottom: var(--safeAreaBottomHeight);
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  box-sizing: border-box;
}

.bottomSheet,
.dialogComplete {
  pointer-events: auto;
}
</style>
