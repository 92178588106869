<template>
  <div class="inner_cont">
    <div class="wrap_flex">
      <strong class="tit_tutorial">지금 바로 떠나볼까요?</strong>
      <div class="box_ticket">
        <div class="inner_box">
          <div class="img_box">
            <div class="inner_img_box"></div>
            <span class="punch_comm punch_left"></span>
            <span class="punch_comm punch_right"></span>
          </div>
          <div class="info_box">
            <strong class="tit_ticket">Boarding Pass</strong>
            <div class="area_info">
              <dl class="info_ticket">
                <dt>Arrival.</dt>
                <dd>FIRFIN ₩ORLD</dd>
              </dl>
              <dl class="info_ticket">
                <dt>Date.</dt>
                <dd>TODAY</dd>
              </dl>
            </div>
            <Button
              btnSize="medium"
              btnStyle="secondary"
              text="퍼핀월드 구경하기"
              @onClickBtn="$emit('onGoNextPage')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/button/Button';
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name: 'TutorialStep2',
  components:{
    Button,
    IconSvg,
  },
}
</script>

<style scoped>
.inner_cont{width:100%;max-width:320px;height:100%;max-height:604px !important}
.wrap_flex{display:flex !important;flex-direction:column;height:100%;}
.tit_tutorial{display:block;flex:0 1;padding:24px 0;font-weight:700;font-size:18px;line-height:28px;color:#000;text-align:center}

/* .area_img{width:100%;max-height:320px;height:50%;margin:0 auto;background:url(/assets/images/tutorial/img_tutorial_step3_character.png)no-repeat center 0;background-size:100%} */

.box_ticket{flex:0 1 100%;border-radius:8px;background-color:#8F69DD;color:#fff;box-sizing:border-box;text-align:left}

.inner_box{display:flex;flex-direction:column;height:100%}
.inner_box .img_box{position:relative;flex:1 1 100%;padding:19px 30px}
.inner_box .img_box .inner_img_box{height:100%;max-width:260px;max-height:282px;margin:0 auto;background:url(/assets/images/tutorial/img_tutorial_step3_character.png)no-repeat center 0;background-size:contain}
.inner_box .img_box .punch_comm{position:absolute;bottom:0;width:16px;height:16px;border-radius:100px;background-color:#fff}
.inner_box .img_box .punch_left{left:-8px}
.inner_box .img_box .punch_right{right:-8px}

.inner_box .info_box{flex:0 1;padding:20px;}

.box_ticket .tit_ticket,
.box_ticket .area_info dt,
.box_ticket .area_info dd{display:block;font-family:'Poppins'}

.box_ticket .tit_ticket{font-weight:700;font-size:20px;line-height:24px}

.box_ticket .area_info{overflow:hidden}
.box_ticket .area_info .info_ticket{float:left;width:50%}
.box_ticket .area_info dt{font-weight:700;font-size:14px;line-height:24px;color:#BEA9ED}
.box_ticket .area_info dd{margin-top:8px;font-weight:700;font-size:16px;line-height:24px;color:#fff}
.box_ticket .area_info,
.box_ticket .area_info + button{margin-top:16px}

.page_tutorial .bullt_page{background-color:#E7E5E5}
</style>