const InterFaceMixin = {
  methods: {
    sendInterface(name, data, dataKeyName) {
      const isData = Boolean(data && dataKeyName);
      //안드로이드
      if (this.$varUA === 'ANDROID') {
        window.FirFinInterface[name](isData ? data : '');
      }
      //IOS
      else if (this.$varUA === 'IOS') {
        let msg;
        if (isData) {
          msg = {};
          msg.name = name;
          msg[dataKeyName] = data;
        } else {
          msg = name;
        }
        window.webkit.messageHandlers.FirFinInterface.postMessage(msg);
      }
      return;
    },
    customSendInterface(andInterfaceFunction, iosInterfaceFunction) {
      try {
        //안드로이드
        if (this.$varUA === 'ANDROID') {
          andInterfaceFunction();
        }
        //IOS
        else if (this.$varUA === 'IOS') {
          iosInterfaceFunction();
        }
      } catch (e) {
        // do nothing.
      }
    },
    webEnd() {
      try {
        //안드로이드
        if (this.$varUA === 'ANDROID') {
          window.FirFinInterface.webEnd();
        }
        //IOS
        else if (this.$varUA === 'IOS') {
          window.webkit.messageHandlers.FirFinInterface.postMessage('webEnd');
        }
      } catch (e) {
        // do nothing.
      }
    },
    goBack(isFromWeb = false) {
      try {
        //안드로이드
        if (this.$varUA === 'ANDROID') {
          window.FirFinInterface.goBack();
        }
        //IOS
        else if (this.$varUA === 'IOS') {
          if (isFromWeb) {
            window.webkit.messageHandlers.FirFinInterface.postMessage('goBack');
          } else {
            window.webkit.messageHandlers.FirFinInterface.postMessage('webEnd');
          }
        }
      } catch (e) {
        this.webEnd();
      }
    },
  },
};
export default InterFaceMixin;
