<template>
  <div class="inner_app">
    <div
      id="lemontreeWrap"
      ref="wrapLemontree"
      :class="['wrap_lemontree']"
      :style="{ '--bgColor' : bgColor }">
      <Header
        v-if="isShowHeader"
        :isBackBtn="isBackBtn"
        :title="title"
        :titleColor="titleColor"
        :paginationData="paginationData"
        :isCloseBtn="isCloseBtn"
        @onClickBack="$emit('onClickBack')"
        @onClickClose="$emit('onClickClose')">
        <template v-slot:headBottom>
          <slot name="headBottom"/>
        </template>
      </Header>
      <div
        id="lemontreeContent"
        ref="contentLemontree"
        :class="{ 'cont_middle' : isContMiddle }"
        @click="$emit('onClickSelf')">
        <div v-if="isContMiddle" class="inner_align_middle">
          <slot/>
        </div>
        <template v-else>
          <slot/>
        </template>
      </div>
    </div>
    <slot name="popup" />
  </div>
</template>

<script>
import Header from '@/components/layout/header/Header';

export default {
  name: 'PageWithLayout',
  components:{
    Header,
  },
  props:{
    title: String,
    titleColor:{
      type: String,
      default: '#111'
    },
    paginationData: Object,
    isBackBtn:{
      type: Boolean,
      default: false
    },
    isCloseBtn:{
      type: Boolean,
      default: false
    },
    bgColor:{
      type: String,
      default: '#111'
    },
    scrollTop:{
      type: Number,
      default: null
    },
    isContMiddle:{
      type: Boolean,
      default: false
    },
    bodyColor:{
      type: String,
      default: '#499CCB'
    },
    isShowHeader: {
      type: Boolean,
      default: true,
    },
  },
  mounted(){
    document.body.style.background = this.bodyColor;
    this.$refs.contentLemontree.scrollTop = this.scrollTop;
  },
  watch:{
    scrollTop(){
      if(this.scrollTop != null){
        this.$refs.contentLemontree.scrollTop = this.scrollTop;
        this.$emit('update:scrollTop',null);
      }
    }
  },
}
</script>

<style scoped>
#lemontreeWrap{
  display:flex;
  flex-direction:column;
  height:100%;
  /* padding-top:constant(safe-area-inset-top);
  padding-top:env(safe-area-inset-top);
  padding-top:100px; */
  background-color:var(--bgColor);
  box-sizing:border-box;
}
/* .pagination_comm + .area_right{position:absolute;top:17px;right:20px} */

#lemontreeContent{
  flex:1 1 100px;
  overflow-y:auto;
  /* padding은 밖에서 관리. */
  /* padding:0 20px; */

  /* padding-bottom:constant(safe-area-inset-bottom);
  padding-bottom:env(safe-area-inset-bottom);
  padding-bottom:100px; */
}
.desc_test{font-size:16px;color:#fff}
#lemontreeContent.cont_middle{font-size:0;white-space:nowrap}
#lemontreeContent.cont_middle:after{display:inline-block;height:100%;vertical-align:middle;min-height:inherit;content:''}
#lemontreeContent.cont_middle .inner_align_middle{display:inline-block;width:100%;margin-top:-60px;font-size:20px;vertical-align:middle;white-space:normal;box-sizing:border-box}
</style>