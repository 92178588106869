<template>
  <PageWithLayout
    :class="['layer_place_search', { active: isActive }]"
    :isBackBtn="true"
    :title="$poData.place_list_title"
    bgColor="#fff"
    :scrollTop.sync="wrapScrollTop"
    :style="cssProps"
    @onClickBack="$emit('onClickClosePlaceSearchPopup')"
  >
    <!-- 검색 -->
    <template v-slot:headBottom>
      <div v-if="hasHeader" class="head_bottom">
        <div v-if="isLocked" class="area_locked">
          <div class="warn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle cx="12" cy="12" r="10" fill="#E23C3C" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
                fill="white"
              />
            </svg>
          </div>
          <p>부모님이 지도를 열어주셔야<br />퀴즈를 풀 수 있어요</p>
        </div>
        <div v-if="isShowSearchBar" class="area_search">
          <Input
            :placeholder="$poData.place_list_search"
            :value.sync="search_value"
            @update:value="value => onUpdateSearchValue(value)"
            @onInput="value => onInputSearchValue(value)"
          >
            <template v-slot:tfFrontIcon>
              <div class="area_icon">
                <IconSvg
                  iconName="search"
                  :size="24"
                  :icoSize="16.8"
                  iconColor="#232323"
                />
              </div>
            </template>
          </Input>
        </div>
      </div>
    </template>
    <div :class="['list', { hasHeader: hasHeader }]">
      <PlaceList
        :placeSearchData="placeSearchData"
        @onClickPlaceSearchItem="
          (placeId, placeStage) =>
            $emit('onClickPlaceSearchItem', placeId, placeStage)
        "
      />
    </div>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import Input from '@/components/common/input/Input';
import IconSvg from '@/components/common/icon/IconSvg';
import { mapGetters } from 'vuex';
import PlaceList from './PlaceList.vue';

export default {
  name: 'PlaceSearchPoup',
  components: {
    PageWithLayout,
    Input,
    IconSvg,
    PlaceList,
  },
  props: {
    isActive: Boolean,
    placeSearchData: Object,
    userLevel: Number,
    isShowSearchBar: {
      type: Boolean,
      default: true,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wrapScrollTop: null,
      search_value: '',
    };
  },
  watch: {
    isActive() {
      if (this.isActive) {
        this.wrapScrollTop = this.userLevelScrollTop;
      } else {
        this.search_value = '';
      }
    },
  },
  mounted() {
    this.wrapScrollTop = this.userLevelScrollTop;
  },
  computed: {
    ...mapGetters('commonViewport', [
      'getSafeAreaTopHeight',
      'getSafeAreaBottomHeight',
    ]),
    userLevelScrollTop() {
      const userLevelStageData = this.placeSearchData.total_list.find(
        item => item.stage === this.userLevel,
      );
      if (!userLevelStageData) {
        return 0;
      }
      return userLevelStageData.scroll_top;
    },
    cssProps() {
      var cssObj = {};
      cssObj = {
        '--safeAreaTopHeight': `${this.getSafeAreaTopHeight}px`,
        '--safeAreaBottomHeight': `${this.getSafeAreaBottomHeight}px`,
      };
      return cssObj;
    },
    hasHeader() {
      return this.isShowSearchBar || this.isLocked;
    },
  },
  methods: {
    onUpdateSearchValue(value) {
      if (value === '') {
        this.$emit('onSearch', '');
      }
    },
    onInputSearchValue(e) {
      let keyWord = this.search_value;
      if (e && e.inputType === 'insertCompositionText') {
        keyWord = this.search_value + (e?.data || '');
      }
      this.$emit('onSearch', keyWord);
    },
  },
};
</script>

<style scoped>
.layer_place_search >>> .inner_head {
  /* 현재 구조에서 좀 애매해서, 이건 외부에서 주겠다. */
  border-bottom: 1px solid #e7e5e5;
}

.head_bottom {
  box-sizing: border-box;
  width: 100%;
  padding: 24px 20px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.layer_place_search {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: 200;
  width: 100%;
  transition: 0.6s;

  padding-bottom: 24px;
}
.layer_place_search.active {
  left: 0;
  transition: 0.6s;
}
.layer_place_search >>> #lemontreeWrap.wrap_lemontree {
  padding-top: var(--safeAreaTopHeight);
  padding-bottom: var(--safeAreaBottomHeight);
}

/* .area_search {
  padding: 16px 20px;
} */
.area_search .tf_comm >>> .area_icon {
  display: block;
  padding: 16px 0 0 16px;
}

.list {
  padding: 24px 20px;
}

.list.hasHeader {
  padding-top: 0;
}

.area_locked {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;

  border-radius: 8px;
  background: var(--tertiary-error-50, #fdefef);

  color: var(--font-font-black, #111);

  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.area_locked .warn {
  display: flex;
  padding: 8px;
  border-radius: 100px;
  background: var(--tertiary-error-200, #e23c3c);
}
</style>
