<template>
  <BottomSheetBase
    :title="text.title"
    :isShowDimmed="true"
    @onClickBack="handleClickBack"
  >
    <div class="content">
      <p class="text" v-html="text.description">
      </p>
    </div>
    <div class="buttonGroup">
      <Button
        btnSize="medium"
        btnStyle="secondary"
        :text="text.buttonYes"
        :disabled="isLoading"
        @onClickBtn="handleClickRequest"
      >
      </Button>
      <button
        type="button"
        class="btnClose"
        :disabled="isLoading"
        @click="$emit('onClickClose')"
      >
        {{ text.buttonCancel }}
      </button>
    </div>
  </BottomSheetBase>
</template>

<script>
import BottomSheetBase from '@/components/layout/bottomSheet/BottomSheetBase.vue';
import Button from '@/components/common/button/Button';
import { POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@/store/index';

export default {
  name: 'RequestUnlockBottomSheet',
  components: { BottomSheetBase, Button },
  props: {
    title: String,
    mapUid: String,
    text: {
      title: String,
      description: String,
      buttonYes: String,
      buttonCancel: String,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    handleClickRequest() {
      const path = `${apiPath.MAP_OPEN_REQUEST.replace('%s', this.mapUid)}`;

      this.isLoading = true;

      POST_REQUEST(path)
        .then(
          successResponse => {
            this.$emit('onCompleteRequest');
          },
          failed => {
            store.dispatch('commonToast/fetchToastStart', {
              msg:
                failed?.msg ||
                '이용에 문제가 생겼어요. 먼저 인터넷 연결 상태를 확인해 보고 다시 시도해 주세요.',
              type: 'error',
            });
          },
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleClickBack() {
      if (this.isLoading) return;

      this.$emit('onClickBack');
    },
    requestMapOpen() {
      if (!this.mapUid) return null;
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 16px;
  margin-bottom: 24px;
}

.text {
  color: var(--font-font-200, #666);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.btnClose {
  color: var(--font-font-200, #666);
  text-align: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
</style>
