
import { addComma } from '@/utils/stringUtils'
import LocalStorageManager from '@/storages/LocalStorageManager';

export default class ContentModel {
  constructor(){
    this.contentData = {
      USER:{
        user_id: null,
        uid: '',
        stage: 0,
        today_quest:{
          place_id: null,
          // quest_id: null
        },
        init_type:'', // 진입 타입
        checked_pocket_money: true, // 용돈계약 유무
        checked_today_quest: false, // 일일퀘스트 완료 유무
        checked_reward: true, // 보상 유무
        fail_point: null, // 실패시 상금
        success_point: null, // 성공시 상금
        accumulated_point: 0, // 누적상금
        last_access_at: "", // 마지막 접속 일시
        profile_image_url:"https://content.lemontree.ai/assets/images/profile_default.png", // 유저 프로필 이미지
      },
      SPONSOR:{
        image: '',
        name: '',
        reward_type: '',
        sponsor_type: '',
        uid: '',
      },
      REWARD:{
        image: '',
        from_name: '',
        remain_amount: 0, // 남은 리워드
        remain_amount_comma: '0원', // 남은 리워드
        reward_description: '', // 쿠폰명
        reward_description_combine: '', // 금액 + 쿠폰
        is_show_remain_amount: true, // 리워드 금액 오픈 유무
        is_coupon_reward: false, // 쿠폰이 있는 리워드인지
        is_coupon_reward_end: false, // 쿠폰 없음
        is_reward_end: false, // 금액, 쿠폰 모두 없음
        reward_end_desc: '리워드 이벤트가 종료되었어요.',
      },
      MAP:{
        name:"",
        title:"",
        desceiption:null,
        start_at:"2023-01-01T00:00:00",
        end_at:"2099-01-01T00:00:00",
        width:1,
        height:1,
        row_count:1,
        column_count:1,
        id:8,
        uid:"",
        map_category_id:2,
        sponsor_id:11,
        map_style:"",
        map_type:"",
        status:"",
        pre_condition:null,
        price:0,
        image_url:null,
        thumbnail_image_url:"",
        completed_image_url:"",
        getThumbnailImageUrl:"",
        list:[],
        reward_title:'',
        reward_description:'',
        extrac_reward_type: null, // [COUPON || null]
        use_extra_reward: false, // 쿠폰 잔여 유무
      },
      PLACE:{
        total_list:[], // 전체 장소
        cleared_place_list:[], // 완료 장소 목록
        last_access_place:{ // 마지막 활성 장소
          id: null,
          stage: 0,
          name: "",
          eng_name: "",
          width: null,
          height: null,
          x: null,
          y: null,
        },
        random_place:{
          id: null
        },
        // static_list:[ // 비활성화 노출 장소 데이터 - TBD
        //   {
        //     areaId:null,
        //     id:10,
        //     stage:0,
        //     name:"충전소",
        //     eng_name:"Charging Station",
        //     display_status:"DISPLAY",
        //     x:1648,
        //     y:1806,
        //     width:247,
        //     height:133,
        //     createdAt:"",
        //     createdBy:8,
        //     updatedAt:"",
        //     updatedBy:1,
        //   }
        // ],
      },
      PLACE_SEARCH:{
        total_list:[],
        search_keyword:'',
        searched_list:[],
        searched_history_list:[],
      },
      PLACE_DETAIL:{
        check_cleared_place: false,
        place:{},
        response_at:'',
        clear_data:{
          total_accumulated_point:2550, // 누적 보상금액
          total_accumulated_success_cnt:32, // 정답 문제수
          total_quiz_count:50, // 총 문제수
        },
        total_point: null, // 퀘스트 진행 후 포인트 합산 결과(퀘스트 미진행 시 null)
        total_today_gain_point: null, // 오늘 획득 가능한 상금(용돈 계약 없을 시 null)
        today_quest_total_point: null, // 오늘의 퀘스트에서 받은 상금
      },
      COURSE:[
        // {
        //   id: null,
        //   idx: 1,
        //   dependent_course_id: null,
        //   name: "",
        //   description: "",
        //   difficulty: 1,
        //   audience: "",
        //   image: null,
        //   mission_count: 0,
        //   total_quest_count: 0,
        //   cleared_quest_count: 0,
        //   position: 0,
        //   quiz_count: 0,
        // }
      ],
      QUEST:{
        id: null,
        course_id: null,
        quiz_type: "",
        lesson: {},
        name: "",
        memo: "",
        quiz_count: 0,
        position: 0,
        selected_answer:[],
        last_answer:{},
      },
      QUIZ:{
        quiz_list:[],
      }
    }
    this.tutorial_quiz_list = [
      {
        content_attachment : "/assets/images/tutorial/img_tutorial_quiz.png",
        content_attachment_type : "img",
        contents : "선물 살 돈이 얼마나 필요하지? 어디에 얼마의 돈을 쓸지 미리 'OO'을 짜야겠다.",
        grade : "NORMAL",
        id : 'TUTORIAL',
        intro_contents : "이번 달에는 생일인 친구가 많다.",
        intro_title : "",
        options : ["예산", "동산"],
        position : 1,
        quest_id : null,
        quiz_area : "",
        quiz_content_type : "SELECTONE",
        quiz_type : "QUIZ",
        status : "PUBLIC",
      }
    ]
  }
  // setUserUid(uid){
  //   this.contentData.USER.uid = uid;
  // }
  // getUserData(){
  //   const userData = {
  //     id: this.contentData.USER.user_id,
  //     uid: this.contentData.USER.uid
  //   }
  //   return userData
  // }
  setQuery(obj){
    const { today_place_id, is_tutorial, devToken } = obj;
    this.contentData.USER.today_quest.place_id = today_place_id || null;
    let init_type;
    if(is_tutorial){
      init_type = 'TUTORIAL'
    }else if(Boolean(today_place_id)){
      init_type = 'TODAY_QUEST'
    }else{
      init_type = 'WORLD_MAP'
    }
    this.contentData.USER.init_type = init_type;
    if(devToken && process.env.NODE_ENV != 'production'){
      LocalStorageManager.shared.setToken(devToken);
    }
  }
  setSponsor(obj){
    this.contentData.SPONSOR = obj;
    this.contentData.REWARD.image = obj.image;
    this.contentData.REWARD.from_name = obj.name;
  }
  setMap(obj){
    this.contentData.MAP = obj;
    this.contentData.MAP.coupon_sold_out = false;
    this.setRewardInfo();
  }
  setMapAuth(obj){
    this.contentData.MAP.authentication = obj;
  }
  setMapBg(array){
    // {
    //     x: item.x,
    //     y: item.y,
    //     width: item.width,
    //     height: item.height,
    //     uid: item.uid,
    //     mapUid: item.map_uid,
    //     imageUrl: item.image_url,
    //   }
    this.contentData.MAP.list = array;
  }
  setRemainAmount(num){
    this.contentData.REWARD.remain_amount = num;
    this.contentData.REWARD.remain_amount_comma = addComma(num)+'원';
    this.setRewardInfo();
  }
  setRewardInfo(){
    const { reward_description, extra_reward_type, use_extra_reward, map_type } = this.contentData.MAP;
    this.contentData.REWARD.is_coupon_reward = extra_reward_type === 'COUPON';
    this.contentData.REWARD.is_coupon_reward_end = !use_extra_reward;
    const { remain_amount, is_coupon_reward, is_coupon_reward_end } = this.contentData.REWARD;

    // 리워드 금액 오픈 유무
    this.contentData.REWARD.is_show_remain_amount = map_type != 'PLUS';

    // 금액 + 쿠폰
    var reward_description_combine = '';
    if (remain_amount > 0) {
      reward_description_combine += addComma(remain_amount)+'원';
    }
    if (is_coupon_reward && !is_coupon_reward_end) {
      this.contentData.REWARD.reward_description = reward_description;
      if (remain_amount > 0) reward_description_combine += ' + ';
      reward_description_combine += reward_description;
    }
    this.contentData.REWARD.reward_description_combine = reward_description_combine;

    // 리워드 종료 유무
    if (remain_amount === 0 && (!is_coupon_reward || is_coupon_reward_end)) {
      this.contentData.REWARD.is_reward_end = true;
    }else{
      this.contentData.REWARD.is_reward_end = false;
    }
  }
  // 영역 포함 장소 리스트
  setPlaceList(obj){
    this.contentData.PLACE.total_list = obj;
  }
  // 장소 검색 리스트
  setPlaceSearchList(obj){
    let stack_scroll_top = 0;
    obj = obj.map((item,index) => {
      let rObj = item;
      const itemHeight = 73 + (item.place_status_list.length * 80);
      rObj.height = itemHeight;
      rObj.scroll_top = stack_scroll_top;
      stack_scroll_top += itemHeight;
      return rObj
    })
    this.contentData.PLACE_SEARCH.total_list = obj;
    this.contentData.PLACE_SEARCH.searched_list = obj;
  }
  getPlaceSearchKeywordHistoryList(keyWord){
    return this.contentData.PLACE_SEARCH.searched_history_list.find(item => item.keyWord === keyWord);
  }
  setPlaceSearchedList(historyItem){
    this.contentData.PLACE_SEARCH.search_keyword = historyItem.keyWord;
    this.contentData.PLACE_SEARCH.searched_list = historyItem.dataList;
  }
  setPlaceSearchKeywordList(keyWord,obj){
    // if(keyWord && obj){
      const historyItem = {
        keyWord: keyWord,
        dataList: obj
      }
      this.contentData.PLACE_SEARCH.searched_history_list.push(historyItem);
      this.setPlaceSearchedList(historyItem)
    // }else{
    //   this.resetPlaceSearchKeyword();
    // }
  }
  resetPlaceSearchKeyword(){
    this.contentData.PLACE_SEARCH.search_keyword = '';
    this.contentData.PLACE_SEARCH.searched_list = this.contentData.PLACE_SEARCH.total_list;
  }
  // 유저의 정보 조회
  setUserStatus(obj){
    const { place_info, user_status } = obj;
    const { cleared_place_list, last_access_place, random_place } = place_info;
    const { stage, checked_pocket_money, checked_today_quest, checked_reward, accumulated_point, last_access_at, profile_image_url } = user_status;
    
    this.contentData.PLACE.cleared_place_list = cleared_place_list;
    this.contentData.PLACE.last_access_place = last_access_place;
    this.contentData.PLACE.random_place = random_place.place;
    this.contentData.USER.stage = stage;
    this.contentData.USER.checked_pocket_money = checked_pocket_money;
    this.contentData.USER.checked_today_quest = checked_today_quest;
    this.contentData.USER.checked_reward = checked_reward;
    this.contentData.USER.accumulated_point = addComma(accumulated_point);
    this.contentData.USER.last_access_at = last_access_at;
    if(profile_image_url) this.contentData.USER.profile_image_url = profile_image_url;
    if(!this.contentData.PLACE_DETAIL.place.id){
      this.setPlaceDetail(random_place.place || last_access_place)
    }
  }
  // 장소 상세
  setPlaceDetail(obj){
    this.contentData.PLACE_DETAIL.place = obj;
  }
  // 유저의 장소 상세
  setUserPlaceStatus(obj){
    const { response_at } = obj.header;
    const { check_cleared_place, reward, course_status, quest_info_status } = obj.body;
    this.contentData.PLACE_DETAIL.check_cleared_place = check_cleared_place;
    if(reward){
      this.contentData.PLACE_DETAIL.today_quest_total_point = reward.today_quest_total_point;
    }
    this.contentData.PLACE_DETAIL.response_at = response_at;
    this.contentData.COURSE = course_status;
    if(quest_info_status){
      const { fail_point, success_point, total_point, total_today_gain_point, quest_info } = quest_info_status;
      this.contentData.USER.fail_point = fail_point;
      this.contentData.USER.success_point = success_point;
      this.contentData.PLACE_DETAIL.total_point = total_point || 0;
      this.contentData.PLACE_DETAIL.total_today_gain_point = total_today_gain_point || 0;
      this.setQuest(quest_info);
    }
  }
  // 장소완료 팝업
  setPlaceClear(obj){
    this.contentData.PLACE_DETAIL.clear_data = obj;
  }
  setQuest(obj){
    const { id, course_id, quiz_type, lesson_id, lesson, lesson_position, name, memo, quiz_count, position } = obj;
    this.contentData.QUEST.id = id;
    this.contentData.QUEST.course_id = course_id;
    this.contentData.QUEST.quiz_type = quiz_type;
    this.contentData.QUEST.lesson = lesson || {};
    this.contentData.QUEST.lesson.lesson_id = lesson_id;
    this.contentData.QUEST.lesson.lesson_position = lesson_position;
    this.contentData.QUEST.name = name;
    this.contentData.QUEST.memo = memo;
    this.contentData.QUEST.quiz_count = quiz_count;
    this.contentData.QUEST.position = position;
  }
  // 퀴즈 목록
  setQuiz(obj){
    const { quiz_list } = obj;
    this.setQuest(obj);
    this.contentData.QUIZ.quiz_list = quiz_list;
  }
  // 튜토리얼 퀴즈 데이터
  setTutorialQuiz(){
    this.contentData.QUIZ.quiz_list = this.tutorial_quiz_list;
  }
}