<template>
  <div class="bottomSheetBaseContainer">
    <div
      :class="['back', { dimmed: isShowDimmed }]"
      @click="$emit('onClickBack')"
    ></div>
    <div class="box">
      <div v-if="title || onClickClose" class="header">
        <h2 v-if="title" v-html="title" />
        <!-- <button>TODO 나중에 구현...</button> -->
      </div>
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomSheetBase',
  props: {
    title: String,
    onClickClose: String,
    isShowDimmed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.bottomSheetBaseContainer >>> * {
  box-sizing: border-box;
}
.bottomSheetBaseContainer {
  z-index: var(--z-index-bottom-sheet);

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.dimmed {
  background: var(--dim, rgba(0, 0, 0, 0.6));
}

.box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  border-radius: 8px 8px 0px 0px;
  background: var(--neutrals-white, #fff);

  display: inline-flex;
  padding: 24px 20px 32px 20px;
  flex-direction: column;
  /* align-items: center; */
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
}

h2 {
  flex: 1;

  color: var(--font-font-black, #111);

  font-size: 20px;
  font-weight: 700;
  line-height: 28px; /* 140% */
}

.content {
  width: 100%;
}
</style>
